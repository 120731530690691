import React, { useLayoutEffect } from "react";
import { client } from "../../services/axiosClient";
import { useEffect, useContext, useState } from "react";
import ChartistGraph from "react-chartist";
import { VectorMap } from "@react-jvectormap/core";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";
import AuthContext from "../../contexts/AuthProvider";
import { ThreeDots } from "react-loader-spinner";
import { getOom } from "../../utils/utils";

import { arMill } from "@react-jvectormap/argentina";
import { brMill } from "@react-jvectormap/brazil";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import Update from "@material-ui/icons/Update";
import AccessTime from "@material-ui/icons/AccessTime";
import Search from "@material-ui/icons/Search";
import Done from "@material-ui/icons/Done";

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Table from "../../components/Table/Table.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";

import { useTranslation } from "react-i18next";

import InfoChanger from "../../components/InfoChanger/InfoChanger";

import { linePercentageChart, barChart } from "../../variables/charts";
let graphMonths = [];

import styles from "../../assets/jss/material-dashboard-pro-react/views/overviewStyle.js";
import tableStyles from "../../assets/jss/material-dashboard-pro-react/components/tableStyle";

const useStyles = makeStyles(styles);
const useTableStyles = makeStyles(tableStyles);

export default function Overview() {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  // const formatter = new Intl.NumberFormat("us-EN");

  let { userDetails } = useContext(AuthContext);

  const { t, i18n } = useTranslation();
  const translate_months = (months_array) => {
    let months = [];
    for (let month of months_array) {
      months.push(t("months." + month));
    }
    return months;
  };

  const [dataLoaded, setDataLoaded] = useState(false);
  const [period, setPeriod] = useState({});
  const [volumeCard, setVolumeCard] = useState({
    name: t("overview.volume"),
    value: 0,
    unit: "",
    vs_ly: {
      value: "",
      icon: "",
      color: null,
    },
    vs_lm: {
      value: "",
      icon: "",
      color: null,
    },
    vs_ytd: {
      value: "",
      icon: "",
      color: null,
    },
  });
  const [netRevenueCard, setNetRevenueCard] = useState({
    name: t("overview.net_revenue"),
    value: 0,
    unit: "",
    vs_ly: {
      value: "",
      icon: "",
      color: null,
    },
    vs_lm: {
      value: "",
      icon: "",
      color: null,
    },
    vs_ytd: {
      value: "",
      icon: "",
      color: null,
    },
  });
  const [serveMarginCard, setServeMarginCard] = useState({
    name: t("overview.serve_margin"),
    value: "",
    vs_ly: {
      value: "",
      icon: "",
      color: null,
    },
    vs_lm: {
      value: "",
      icon: "",
      color: null,
    },
    vs_ytd: {
      value: "",
      icon: "",
      color: null,
    },
  });
  const [topChannelsTable, setTopChannelsTable] = useState([]);
  const [topCategoriesTable, setTopCategoriesTable] = useState([]);
  const [topRegionsTable, setTopRegionsTable] = useState([]);
  const [mapToDisplay, setMapToDisplay] = useState({
    map: arMill,
    strokeWidth: 1,
  });
  const [isShowMap, setIsShowMap] = useState(false);
  const [mapData, setMapData] = useState({});
  const [monthlyVolumeChart, setMonthlyVolumeChart] = useState({
    ...barChart,
    data: {
      labels: [],
      series: [[]],
    },
  });
  const [monthlyNetRevenueChart, setMonthlyNetRevenueChart] = useState({
    ...barChart,
    data: {
      labels: [],
      series: [[]],
    },
  });
  const [monthlyServeMarginChart, setMonthlyServeMarginChart] = useState({
    ...linePercentageChart,
    data: {
      labels: [],
      series: [[]],
    },
  });

  const [volumeOom, setVolumeOom] = useState({
    div: 1e6,
    x: " " + t("oom.m"),
  });
  const [revenueOom, setRevenueOom] = useState({
    div: 1e6,
    x: " " + t("oom.m"),
  });

  const format_table_results = (table_data) => {
    for (let i = 0; i < table_data.length; i++) {
      table_data[i][1] = (table_data[i][1] * 100).toFixed(1) + "%";
    }
    return table_data;
  };

  useEffect(() => {
    client
      .post("api/overview/")
      .then((response) => {
        setPeriod({
          month: response.data.period.month,
          year: response.data.period.year,
          last_update: moment.utc(
            response.data.period.last_update,
            "YYYY-MM-DD HH:mm",
          ),
          next_update: response.data.period.next_update,
          latest_modeling_month: response.data.period.latest_modeling_month,
          months_pending_count: response.data.period.months_pending_count,
        });
        setVolumeCard({
          // value: formatter.format(parseInt(response.data.volume.value)),
          value: response.data.volume.value,
          unit: response.data.volume.unit,
          vs_ly: {
            valid: response.data.volume.vs_ly !== null,
            value: (100 * response.data.volume.vs_ly).toFixed(1),
            icon: response.data.volume.vs_ly > 0 ? "north" : "south",
            color:
              response.data.volume.vs_ly > 0
                ? classes.successText
                : classes.dangerText,
          },
          vs_lm: {
            valid: response.data.volume.vs_lm !== null,
            value: (100 * response.data.volume.vs_lm).toFixed(1),
            icon: response.data.volume.vs_lm > 0 ? "north" : "south",
            color:
              response.data.volume.vs_lm > 0
                ? classes.successText
                : classes.dangerText,
          },
          vs_ytd: {
            valid: response.data.volume.vs_ytd !== null,
            value: (100 * response.data.volume.vs_ytd).toFixed(1),
            icon: response.data.volume.vs_ytd > 0 ? "north" : "south",
            color:
              response.data.volume.vs_ytd > 0
                ? classes.successText
                : classes.dangerText,
          },
        });
        setNetRevenueCard({
          name: response.data.net_revenue.name,
          // value: formatter.format(response.data.net_revenue.value.toFixed(1)),
          value: response.data.net_revenue.value,
          unit: response.data.net_revenue.unit,
          vs_ly: {
            valid: response.data.net_revenue.vs_ly !== null,
            value: (100 * response.data.net_revenue.vs_ly).toFixed(1),
            icon: response.data.net_revenue.vs_ly > 0 ? "north" : "south",
            color:
              response.data.net_revenue.vs_ly > 0
                ? classes.successText
                : classes.dangerText,
          },
          vs_lm: {
            valid: response.data.net_revenue.vs_lm !== null,
            value: (100 * response.data.net_revenue.vs_lm).toFixed(1),
            icon: response.data.net_revenue.vs_lm > 0 ? "north" : "south",
            color:
              response.data.net_revenue.vs_lm > 0
                ? classes.successText
                : classes.dangerText,
          },
          vs_ytd: {
            valid: response.data.net_revenue.vs_ytd !== null,
            value: (100 * response.data.net_revenue.vs_ytd).toFixed(1),
            icon: response.data.net_revenue.vs_ytd > 0 ? "north" : "south",
            color:
              response.data.net_revenue.vs_ytd > 0
                ? classes.successText
                : classes.dangerText,
          },
        });
        setServeMarginCard({
          name: response.data.serve_margin.name,
          value: (100 * response.data.serve_margin.value).toFixed(
            response.data.serve_margin.value < 0.1 ? 2 : 1,
          ),
          vs_ly: {
            valid: response.data.serve_margin.vs_ly !== null,
            value: (100 * response.data.serve_margin.vs_ly).toFixed(1),
            icon: response.data.serve_margin.vs_ly > 0 ? "north" : "south",
            color:
              response.data.serve_margin.vs_ly > 0
                ? classes.successText
                : classes.dangerText,
          },
          vs_lm: {
            valid: response.data.serve_margin.vs_lm !== null,
            value: (100 * response.data.serve_margin.vs_lm).toFixed(1),
            icon: response.data.serve_margin.vs_lm > 0 ? "north" : "south",
            color:
              response.data.serve_margin.vs_lm > 0
                ? classes.successText
                : classes.dangerText,
          },
          vs_ytd: {
            valid: response.data.serve_margin.vs_ytd !== null,
            value: (100 * response.data.serve_margin.vs_ytd).toFixed(1),
            icon: response.data.serve_margin.vs_ytd > 0 ? "north" : "south",
            color:
              response.data.serve_margin.vs_ytd > 0
                ? classes.successText
                : classes.dangerText,
          },
        });
        setTopChannelsTable(format_table_results(response.data.top_channels));
        setTopCategoriesTable(
          format_table_results(response.data.top_categories),
        );
        setTopRegionsTable(format_table_results(response.data.top_regions));
        setMapToDisplay(() => {
          switch (response.data.map_to_display) {
            case "ar":
              return { map: arMill, strokeWidth: 1 };
            case "br":
              return { map: brMill, strokeWidth: 300 };
          }
        });
        setMapData(response.data.map_data);
        setIsShowMap(false);
        setTimeout(() => {
          setIsShowMap(true);
        });
        graphMonths = response.data.monthly_results.months;
        let translatedMonths = translate_months(graphMonths);
        setMonthlyVolumeChart({
          ...monthlyVolumeChart,
          data: {
            labels: translatedMonths,
            series: [response.data.monthly_results.volume],
          },
        });
        setMonthlyNetRevenueChart({
          ...monthlyVolumeChart,
          data: {
            labels: translatedMonths,
            series: [response.data.monthly_results.net_revenue],
          },
        });
        setMonthlyServeMarginChart({
          ...monthlyServeMarginChart,
          data: {
            labels: translatedMonths,
            series: [response.data.monthly_results.serve_margin],
          },
        });
        setDataLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    moment.locale(i18n.language);
    let translatedMonths = translate_months(graphMonths);
    setMonthlyVolumeChart({
      ...monthlyVolumeChart,
      data: {
        ...monthlyVolumeChart.data,
        labels: translatedMonths,
      },
    });
    setMonthlyNetRevenueChart({
      ...monthlyNetRevenueChart,
      data: {
        ...monthlyNetRevenueChart.data,
        labels: translatedMonths,
      },
    });
    setMonthlyServeMarginChart({
      ...monthlyServeMarginChart,
      data: {
        ...monthlyServeMarginChart.data,
        labels: translatedMonths,
      },
    });
  }, [i18n.language]);

  useLayoutEffect(() => {
    let val = volumeCard.value;
    let { div, x } = getOom(val);
    x = x === "" ? x : " " + t("oom." + x);
    setVolumeOom({ div: div, x: x });
  }, [volumeCard, i18n.language]);
  useLayoutEffect(() => {
    let val = netRevenueCard.value;
    let { div, x } = getOom(val);
    x = x === "" ? x : " " + t("oom." + x);
    setRevenueOom({ div: div, x: x });
  }, [netRevenueCard, i18n.language]);

  const getAdjustedVolumeData = () => {
    let data = structuredClone(monthlyVolumeChart.data);
    let series = [];
    monthlyVolumeChart.data.series[0].map((val) => {
      series.push(val / volumeOom.div);
    });
    data = {
      ...data,
      series: [series],
    };
    return data;
  };
  const getAdjustedRevenueData = () => {
    let data = structuredClone(monthlyNetRevenueChart.data);
    let series = [];
    monthlyNetRevenueChart.data.series[0].map((val) => {
      series.push(val / revenueOom.div);
    });
    data = {
      ...data,
      series: [series],
    };
    return data;
  };

  const cardSpinner = () => {
    if (!dataLoaded)
      return (
        <>
          <div className={classes.cardSpinner}>
            <ThreeDots
              color={userDetails.implementation_color}
              ariaLabel="loading"
              wrapperStyle={{ marginTop: "5px", marginBottom: "16px" }}
              wrapperClass=""
            />
          </div>
        </>
      );
    return null;
  };
  const tableSpinner = () => {
    if (!dataLoaded)
      return (
        <>
          <div className={classes.tableSpinner}>
            <ThreeDots
              width="100px"
              height="100px"
              color={userDetails.implementation_color}
              ariaLabel="loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    return null;
  };

  const cardFooterDate = () => {
    let content;
    if (dataLoaded) {
      content = (
        <>
          {t("months." + period.month)}
          {"-"}
          {period.year.slice(-2)}
        </>
      );
    } else {
      content = <div style={{ height: "23px" }}></div>;
    }
    return (
      <GridItem xs={3} container justifyContent="flex-end">
        <div className={classes.stats}>{content}</div>
      </GridItem>
    );
  };

  const getInfoChangerData = (card, symbol) => {
    let data = [];
    if (card.vs_lm.valid) {
      data.push(
        <div key={1}>
          <span className={card.vs_lm.color}>
            <Icon>{card.vs_lm.icon}</Icon>
            {card.vs_lm.value + symbol}
          </span>
          &nbsp;
          {t("analysis.vs_lm")}
        </div>,
      );
    }
    if (card.vs_ly.valid) {
      data.push(
        <div key={2}>
          <span className={card.vs_ly.color}>
            <Icon>{card.vs_ly.icon}</Icon>
            {card.vs_ly.value + symbol}
          </span>
          &nbsp;
          {t("analysis.vs_ly")}
        </div>,
      );
    }
    if (card.vs_ytd.valid) {
      data.push(
        <div key={3}>
          <span className={card.vs_ytd.color}>
            <Icon>{card.vs_ytd.icon}</Icon>
            {card.vs_ytd.value + symbol}
          </span>
          &nbsp;
          {t("analysis.vs_ytd")}
        </div>,
      );
    }
    return data;
  };

  return (
    <div>
      <GridContainer
        className={classes.cardsWrapper}
        style={{ marginTop: "-40px" }}
      >
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="client" stats icon>
              <CardIcon color="client">
                <Icon>local_shipping</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>
                {dataLoaded && t("overview.volume")}
              </p>
              {dataLoaded && (
                <h3 className={classes.cardTitle}>
                  {(volumeCard.value / volumeOom.div).toFixed(
                    volumeCard.value / volumeOom.div < 10 ? 2 : 1,
                  ) + volumeOom.x}{" "}
                  <small>{volumeCard.unit}</small>
                </h3>
              )}
              {cardSpinner()}
            </CardHeader>
            <CardFooter stats>
              <GridContainer>
                {dataLoaded && (
                  <GridItem xs={9}>
                    <div className={classes.stats}>
                      <InfoChanger
                        info={getInfoChangerData(volumeCard, "%")}
                        interval={5000}
                      />
                    </div>
                  </GridItem>
                )}
                {cardFooterDate()}
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="client" stats icon>
              <CardIcon color="client">
                <Icon>monetization_on</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>
                {dataLoaded && netRevenueCard.name}
              </p>
              {dataLoaded && (
                <h3 className={classes.cardTitle}>
                  {(netRevenueCard.value / revenueOom.div).toFixed(
                    netRevenueCard.value / revenueOom.div < 10 ? 2 : 1,
                  )}{" "}
                  <small>{netRevenueCard.unit + revenueOom.x}</small>
                </h3>
              )}
              {cardSpinner()}
            </CardHeader>
            <CardFooter stats>
              <GridContainer>
                {dataLoaded && (
                  <GridItem xs={9}>
                    <div className={classes.stats}>
                      <InfoChanger
                        info={getInfoChangerData(netRevenueCard, "%")}
                        interval={5000}
                      />
                    </div>
                  </GridItem>
                )}
                {cardFooterDate()}
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="client" stats icon>
              <CardIcon color="client">
                <Icon>account_balance_wallet</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>
                {dataLoaded && serveMarginCard.name}
              </p>
              {dataLoaded && (
                <h3 className={classes.cardTitle}>
                  {serveMarginCard.value} <small>%</small>
                </h3>
              )}
              {cardSpinner()}
            </CardHeader>
            <CardFooter stats>
              <GridContainer>
                {dataLoaded && (
                  <GridItem xs={9}>
                    <div className={classes.stats}>
                      <InfoChanger
                        info={getInfoChangerData(serveMarginCard, " pp")}
                        interval={5000}
                      />
                    </div>
                  </GridItem>
                )}
                {cardFooterDate()}
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="client" stats icon>
              <CardIcon color="client">
                <Icon>alarm_on</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>
                {dataLoaded && t("overview.last_update")}
              </p>
              {dataLoaded && (
                <h3 className={classes.cardTitle}>
                  {period.last_update.local().format("L")}
                </h3>
              )}
              {cardSpinner()}
            </CardHeader>
            <CardFooter stats>
              {dataLoaded && (
                <div className={classes.stats}>
                  {period.months_pending_count === 0 && (
                    <span className={classes.successColor}>
                      <Done />
                      {t("overview.no_pending_updates")}
                    </span>
                  )}
                  {period.months_pending_count === 1 && (
                    <span className={classes.warningColor}>
                      <Update />
                      {t("overview.update_pending1")}{" "}
                      {t("months." + period.latest_modeling_month[1])}
                      {"-"}
                      {period.latest_modeling_month[0]
                        .toString()
                        .slice(-2)}{" "}
                      {t("overview.update_pending2")}
                    </span>
                  )}
                  {period.months_pending_count > 1 && (
                    <span className={classes.warningColor}>
                      <Update />
                      {period.months_pending_count}{" "}
                      {t("overview.months_with_pending_calculations")}
                    </span>
                  )}
                </div>
              )}
              {!dataLoaded && <div style={{ height: "23px" }}></div>}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ marginTop: "-34px" }}>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="client" icon>
              <h4 className={classes.cardIconTitle}>
                {t("overview.serve_margin_top_channels_categories_locations")}
                {dataLoaded && (
                  <small>
                    {" - "}
                    {t("months." + period.month)}
                    {"-"}
                    {period.year.slice(-2)}
                  </small>
                )}
              </h4>
            </CardHeader>
            <CardBody style={{ minHeight: "318px" }}>
              <GridContainer justifyContent="space-between">
                <GridItem xs={12} sm={12} md={12} lg={3}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Table
                        hover
                        customCellClasses={[tableClasses.right]}
                        customClassesForCells={[1]}
                        tableData={topChannelsTable}
                      />
                    </GridItem>
                    <Hidden lgUp>
                      <GridItem xs={12}>
                        <div style={{ height: "16px" }}></div>
                      </GridItem>
                    </Hidden>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={3}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Table
                        hover
                        customCellClasses={[tableClasses.right]}
                        customClassesForCells={[1]}
                        tableData={topCategoriesTable}
                      />
                    </GridItem>
                    <Hidden lgUp>
                      <GridItem xs={12}>
                        <div style={{ height: "16px" }}></div>
                      </GridItem>
                    </Hidden>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={3}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <Table
                        hover
                        customCellClasses={[tableClasses.right]}
                        customClassesForCells={[1]}
                        tableData={topRegionsTable}
                      />
                    </GridItem>
                    <Hidden lgUp>
                      <GridItem xs={12}>
                        <div style={{ height: "16px" }}></div>
                      </GridItem>
                    </Hidden>
                  </GridContainer>
                </GridItem>
                <GridItem xs={false} sm={12} md={12} lg={3}>
                  <div
                    style={{
                      width: "100%",
                      height: "280px",
                    }}
                  >
                    {isShowMap && (
                      <VectorMap
                        map={mapToDisplay.map}
                        backgroundColor="transparent"
                        zoomOnScroll={false}
                        containerStyle={{
                          width: "100%",
                          height: "100%",
                        }}
                        containerClassName="map"
                        regionStyle={{
                          initial: {
                            fill: "#e4e4e4",
                            "fill-opacity": 0.8,
                            stroke: "white",
                            "stroke-width": mapToDisplay.strokeWidth,
                            "stroke-opacity": 1,
                          },
                        }}
                        series={{
                          regions: [
                            {
                              values: mapData,
                              scale: [
                                "#F8696B",
                                "#FBAA77",
                                "#FFEB84",
                                "#B1D580",
                                "#63BE7B",
                              ],
                              // normalizeFunction: "polynomial",
                            },
                          ],
                        }}
                        onRegionTipShow={(e, el, code) => {
                          el.html(
                            `${el.html()} | ${(100 * mapData[code]).toFixed(1)}%`,
                          );
                        }}
                      />
                    )}
                  </div>
                </GridItem>
                {tableSpinner()}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer style={{ marginTop: "-16px" }}>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader
              color={
                userDetails.implementation_tone === "color"
                  ? "info"
                  : userDetails.implementation_tone === "gray"
                    ? "gray"
                    : "client"
              }
              className={classes.cardHeaderHover}
            >
              <ChartistGraph
                className="ct-chart-white-colors"
                data={getAdjustedVolumeData()}
                type="Bar"
                options={monthlyVolumeChart.options}
                listener={monthlyVolumeChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title={t("overview.view_details")}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button simple color="client" justIcon>
                    <Search className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>
                {dataLoaded && t("overview.volume")}
                {!dataLoaded && (
                  <ThreeDots
                    color={userDetails.implementation_color}
                    width={"50px"}
                    height={"50px"}
                    wrapperStyle={{ marginTop: "-15px", marginBottom: "-10px" }}
                    ariaLabel="loading"
                  />
                )}
              </h4>
              <p className={classes.cardCategory}>
                {(volumeOom.x === "" ? "" : volumeOom.x + " ") +
                  volumeCard.unit}
                &nbsp;&#x2502;&nbsp;
                {t("overview.last_12_months")}
              </p>
            </CardBody>
            <CardFooter chart>
              {dataLoaded && (
                <div className={classes.stats}>
                  <AccessTime />
                  {t("overview.updated")} {period.last_update.local().fromNow()}
                </div>
              )}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader
              color={
                userDetails.implementation_tone === "color"
                  ? "warning"
                  : userDetails.implementation_tone === "gray"
                    ? "gray"
                    : "client"
              }
              className={classes.cardHeaderHover}
            >
              <ChartistGraph
                className="ct-chart-white-colors"
                data={getAdjustedRevenueData()}
                type="Bar"
                options={monthlyNetRevenueChart.options}
                responsiveOptions={monthlyNetRevenueChart.responsiveOptions}
                listener={monthlyNetRevenueChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title={t("overview.view_details")}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button simple color="client" justIcon>
                    <Search className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>
                {dataLoaded && netRevenueCard.name}
                {!dataLoaded && (
                  <ThreeDots
                    color={userDetails.implementation_color}
                    width={"50px"}
                    height={"50px"}
                    wrapperStyle={{ marginTop: "-15px", marginBottom: "-10px" }}
                    ariaLabel="loading"
                  />
                )}
              </h4>
              <p className={classes.cardCategory}>
                {netRevenueCard.unit + revenueOom.x}
                &nbsp;&#x2502;&nbsp;
                {t("overview.last_12_months")}
              </p>
            </CardBody>
            <CardFooter chart>
              {dataLoaded && (
                <div className={classes.stats}>
                  <AccessTime />
                  {t("overview.updated")} {period.last_update.local().fromNow()}
                </div>
              )}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart className={classes.cardHover}>
            <CardHeader
              color={
                userDetails.implementation_tone === "color"
                  ? "danger"
                  : userDetails.implementation_tone === "gray"
                    ? "gray"
                    : "client"
              }
              className={classes.cardHeaderHover}
            >
              <ChartistGraph
                className="ct-chart-white-colors"
                data={monthlyServeMarginChart.data}
                type="Line"
                options={monthlyServeMarginChart.options}
                listener={monthlyServeMarginChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Tooltip
                  id="tooltip-top"
                  title={t("overview.view_details")}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button simple color="client" justIcon>
                    <Search className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              </div>
              <h4 className={classes.cardTitle}>
                {dataLoaded && serveMarginCard.name}
                {!dataLoaded && (
                  <ThreeDots
                    color={userDetails.implementation_color}
                    width={"50px"}
                    height={"50px"}
                    wrapperStyle={{ marginTop: "-15px", marginBottom: "-10px" }}
                    ariaLabel="loading"
                  />
                )}
              </h4>
              <p className={classes.cardCategory}>
                {t("overview.last_12_months")}
              </p>
            </CardBody>
            <CardFooter chart>
              {dataLoaded && (
                <div className={classes.stats}>
                  <AccessTime />
                  {t("overview.updated")} {period.last_update.local().fromNow()}
                </div>
              )}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
