import React, { useEffect, useState, useContext, useRef } from "react";
import { useImmer } from "use-immer";
import AuthContext from "../../contexts/AuthProvider";
import cx from "classnames";
import { client } from "../../services/axiosClient";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import {
  AccountBalance,
  Clear,
  Add,
  Remove,
  Save,
  Close,
} from "@material-ui/icons";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
library.add(faCheck);

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardHeader from "../../components/Card/CardHeader.js";
import { ThreeDots } from "react-loader-spinner";

import exchangeRatesStyle from "../../assets/jss/material-dashboard-pro-react/views/exchangeRatesStyle";

import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { toast } from "react-toastify";
import { Slide as ToastSlide } from "react-toastify";
import SnackbarContent from "../../components/Snackbar/SnackbarContent";

export default function ExchangeRates() {
  let { userDetails } = useContext(AuthContext);

  const client_styles = (theme) => ({
    ...exchangeRatesStyle(theme, userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [savePending, setSavePending] = useState(false);
  const [exchangeRatesData, setExchangeRatesData] = useImmer({});

  const [addCurrencyDialogOpen, setAddCurrencyDialogOpen] = useState(false);
  const [currencyToAdd, setCurrencyToAdd] = useState("");
  const currencyToAddRef = useRef(null);
  const [removeCurrencyDialogOpen, setRemoveCurrencyDialogOpen] =
    useState(false);
  const [currencyToRemove, setCurrencyToRemove] = useState("");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    client
      .post("api/exchange_rates_get_current_data/")
      .then((response) => {
        setExchangeRatesData(response.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        notify(
          t("modeling_exchange_rates." + error.response.data.error_code),
          "error",
        );
        setDataLoaded(true);
        console.log(error);
      });
  }, []);

  const notify = (message, type = "info") =>
    toast(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
      type: type,
      transition: ToastSlide,
    });

  const loadingSpinner = () => {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <ThreeDots
          width="100px"
          height="100px"
          color={userDetails.implementation_color}
          ariaLabel="loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  };

  const handleRateBlur = (e, currency, month) => {
    let val = e.target.value;
    val = parseFloat(val);
    if (isNaN(val)) {
      val = null;
    } else {
      if (val <= 0) {
        val = null;
      }
    }
    if (val !== null) {
      val = parseFloat(val.toFixed(6));
    }
    let change;
    if (exchangeRatesData.rates[currency][month]) {
      change = val !== exchangeRatesData.rates[currency][month];
    } else {
      change = val !== null;
    }
    if (change) {
      setExchangeRatesData((draft) => {
        draft.rates[currency][month] = val;
      });
      setSavePending(true);
    }
  };
  const handleRateChange = (e, currency, month) => {
    setExchangeRatesData((draft) => {
      draft["rates"][currency][month] = e.target.value;
    });
    setSavePending(true);
  };
  const handleAddCurrencyButtonClick = (e) => {
    e.target.blur();
    setCurrencyToAdd("");
    setAddCurrencyDialogOpen(true);
    setTimeout(() => {
      currencyToAddRef.current.firstChild.focus();
    }, 200);
  };
  const handleCurrencyToAddChange = (e) => {
    setCurrencyToAdd(e.target.value.toUpperCase());
  };
  const handleCurrencyAddConfirm = () => {
    if (
      exchangeRatesData.currencies.includes(currencyToAdd) ||
      exchangeRatesData.standard_currency === currencyToAdd ||
      exchangeRatesData.currencies.length >= 8
    )
      return false;
    let currency_to_add = currencyToAdd;
    setCurrencyToAdd("");
    let new_values = {};
    for (let i = 0; i < exchangeRatesData.months.length; i++) {
      new_values[exchangeRatesData.months[i]] = null;
    }
    setExchangeRatesData((draft) => {
      draft.currencies.push(currency_to_add);
      draft.rates[currency_to_add] = new_values;
    });
    setAddCurrencyDialogOpen(false);
    setSavePending(true);
  };
  const handleRemoveCurrencyButtonClick = (e) => {
    e.target.blur();
    setCurrencyToRemove("");
    setRemoveCurrencyDialogOpen(true);
  };
  const handleCurrencyToRemoveSelect = (e) => {
    setCurrencyToRemove(e.target.value);
  };
  const handleCurrencyRemoveConfirm = () => {
    let currency_to_remove = currencyToRemove;
    setCurrencyToRemove("");
    let index = exchangeRatesData.currencies.indexOf(currency_to_remove);
    setExchangeRatesData((draft) => {
      draft.currencies.splice(index, 1);
      delete draft.rates[currency_to_remove];
    });
    setRemoveCurrencyDialogOpen(false);
    setSavePending(true);
  };
  const currencyOptions = () => {
    if (!exchangeRatesData.currencies) return null;
    return exchangeRatesData.currencies.map((currency, key) => (
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        value={currency}
        key={key}
      >
        {currency}
      </MenuItem>
    ));
  };
  const handleSaveButtonClick = () => {
    if (!savePending || saving) return false;
    let params = {
      currencies: exchangeRatesData.currencies,
      rates: exchangeRatesData.rates,
    };
    setSaving(true);
    setSavePending(false);
    client
      .post("api/exchange_rates_save/", params)
      .then(() => {
        notify(t("modeling_exchange_rates.saved_successfully"));
        setSaving(false);
      })
      .catch((error) => {
        notify(
          t("modeling_exchange_rates." + error.response.data.error_code),
          "error",
        );
        setSaving(false);
        setSavePending(true);
        console.log(error);
      });
  };

  const addCurrencyDialog = () => {
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={addCurrencyDialogOpen}
        keepMounted
        onClose={() => setAddCurrencyDialogOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={cx({
            [classes.modalHeader]: true,
          })}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAddCurrencyDialogOpen(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            <span className={classes.modalHeader}>
              {t("modeling_exchange_rates.add_currency")}
            </span>
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ paddingBottom: "0" }}
        >
          <CustomInput
            ref={currencyToAddRef}
            value={currencyToAdd}
            onChange={(e) => handleCurrencyToAddChange(e)}
            onEnterKey={() => handleCurrencyAddConfirm()}
            labelText={t("modeling_exchange_rates.currency_to_add")}
            id="currency_to_add"
            formControlProps={{
              fullWidth: true,
              style: { marginTop: "-10px" },
            }}
            inputProps={{
              type: "text",
              maxLength: 3,
              autoComplete: "off",
            }}
            client
          />
          <div
            className={cx({
              [classes.currencyAlreadyExistsWarning]: true,
              [classes.invisible]:
                exchangeRatesData.currencies &&
                !exchangeRatesData.currencies.includes(currencyToAdd) &&
                exchangeRatesData.standard_currency !== currencyToAdd,
            })}
          >
            {t("modeling_exchange_rates.currency_already_exists")}
          </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => handleCurrencyAddConfirm()}
            color="info"
            simple
            disabled={
              currencyToAdd.length !== 3 ||
              exchangeRatesData.currencies.includes(currencyToAdd) ||
              exchangeRatesData.standard_currency === currencyToAdd
            }
          >
            {t("modeling_exchange_rates.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const removeCurrencyDialog = () => {
    return (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={removeCurrencyDialogOpen}
        keepMounted
        onClose={() => setRemoveCurrencyDialogOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={cx({
            [classes.modalHeader]: true,
          })}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setRemoveCurrencyDialogOpen(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            <span className={classes.modalHeader}>
              {t("modeling_exchange_rates.remove_currency")}
            </span>
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="currency-select"
              className={classes.selectLabel}
            >
              {t("modeling_exchange_rates.select_currency_to_remove")}
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              classes={{
                select: classes.select,
              }}
              value={currencyToRemove}
              onChange={handleCurrencyToRemoveSelect}
              inputProps={{
                name: "currencySelect",
                id: "currency-select",
              }}
            >
              {currencyOptions()}
            </Select>
          </FormControl>
          <div className={classes.removeCurrencyWarning}>
            {t("modeling_exchange_rates.remove_currency_warning")}
          </div>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => handleCurrencyRemoveConfirm()}
            color="info"
            simple
            disabled={currencyToRemove === ""}
          >
            {t("modeling_exchange_rates.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      <GridContainer style={{ marginTop: "-40px" }}>
        <GridItem xs={12}>
          <div>
            <Card>
              <CardHeader color="client" icon>
                <CardIcon color="client">
                  <AccountBalance />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t("modeling_exchange_rates.exchange_rates")}
                </h4>
              </CardHeader>
              <CardBody className={classes.cardBody}>
                <GridContainer justifyContent="flex-start">
                  {dataLoaded && Object.keys(exchangeRatesData).length > 0 && (
                    <>
                      <div
                        className={cx({
                          [classes.topInformation]: true,
                          [classes.standardCurrency]: true,
                        })}
                      >
                        {t("modeling_exchange_rates.standard_currency") + ": "}
                        <strong>{exchangeRatesData.standard_currency}</strong>
                      </div>
                      <div
                        className={cx({
                          [classes.topInformation]: true,
                          [classes.additionalCurrencies]: true,
                        })}
                      >
                        {t("modeling_exchange_rates.additional_currencies")}
                      </div>
                      <div
                        className={cx({
                          [classes.additionalCurrencyButtons]: true,
                        })}
                      >
                        <Button
                          aria-label="add_currency"
                          color="client"
                          size={"sm"}
                          onClick={(e) => handleAddCurrencyButtonClick(e)}
                          disabled={
                            exchangeRatesData.currencies.length >= 8 || saving
                          }
                        >
                          <Add />
                          {t("modeling_exchange_rates.add_currency")}
                        </Button>
                        <Button
                          aria-label="remove_currency"
                          color="client"
                          size={"sm"}
                          onClick={(e) => handleRemoveCurrencyButtonClick(e)}
                          disabled={
                            exchangeRatesData.currencies.length === 0 || saving
                          }
                        >
                          <Remove />
                          {t("modeling_exchange_rates.remove_currency")}
                        </Button>
                        <Button
                          aria-label="add_currency"
                          color={savePending ? "warning" : "client"}
                          size={"sm"}
                          className={cx({ [classes.attention]: savePending })}
                          onClick={() => handleSaveButtonClick()}
                          disabled={!savePending || saving}
                        >
                          <Save />
                          {t("modeling_exchange_rates.save")}
                        </Button>
                        {saving && (
                          <div
                            style={{
                              display: "inline-block",
                              marginLeft: "5px",
                            }}
                          >
                            <ThreeDots
                              width="30px"
                              height="10px"
                              color={userDetails.implementation_color}
                              ariaLabel="loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                            />
                          </div>
                        )}
                      </div>
                      <div
                        className={cx({
                          [classes.comments]: true,
                        })}
                      >
                        {t("modeling_exchange_rates.instructions")}
                        {":"}
                        {[...Array(5).keys()].map((key, val) => {
                          return (
                            <React.Fragment key={key}>
                              <br />
                              <span>
                                {"• "}
                                {t(
                                  "modeling_exchange_rates.comments.comment" +
                                    (val + 1),
                                )}
                              </span>
                            </React.Fragment>
                          );
                        })}
                      </div>
                      {/* table */}
                      <div className={classes.tableWrapper}>
                        <div
                          className={cx({
                            [classes.currencyTable]: true,
                          })}
                        >
                          {/* header */}
                          <div
                            className={cx({
                              [classes.currencyTableHeader]: true,
                              [classes.currencyTableRow]: true,
                            })}
                          >
                            <div
                              className={cx({
                                [classes.currencyTableColMonth]: true,
                              })}
                            >
                              {t("modeling_exchange_rates.month")}
                            </div>
                            {exchangeRatesData.currencies.map(
                              (currency, key) => {
                                return (
                                  <div
                                    key={key}
                                    className={cx({
                                      [classes.currencyTableColCurrency]: true,
                                    })}
                                  >
                                    {currency}
                                  </div>
                                );
                              },
                            )}
                          </div>
                          {/* body */}
                          {exchangeRatesData.months.map((month, key1) => {
                            return (
                              <div
                                key={key1}
                                className={cx({
                                  [classes.currencyTableRow]: true,
                                })}
                              >
                                <div
                                  className={cx({
                                    [classes.currencyTableBodyCol]: true,
                                    [classes.currencyTableColMonth]: true,
                                  })}
                                >
                                  {month}
                                </div>
                                {exchangeRatesData.currencies.map(
                                  (currency, key2) => {
                                    return (
                                      <div
                                        key={key2}
                                        className={cx({
                                          [classes.currencyTableBodyCol]: true,
                                          [classes.currencyTableColCurrency]: true,
                                        })}
                                      >
                                        <CustomInput
                                          id="currency_value"
                                          value={
                                            exchangeRatesData.rates[currency][
                                              month
                                            ]
                                              ? exchangeRatesData.rates[
                                                  currency
                                                ][month].toString()
                                              : ""
                                          }
                                          onChange={(e) =>
                                            handleRateChange(e, currency, month)
                                          }
                                          onBlur={(e) =>
                                            handleRateBlur(e, currency, month)
                                          }
                                          formControlProps={{
                                            style: {
                                              paddingTop: "0",
                                              paddingBottom: "0",
                                              marginTop: "-2px",
                                              marginBottom: "0",
                                              width: "100px",
                                            },
                                          }}
                                          inputProps={{
                                            type: "number",
                                            autoComplete: "off",
                                            disabled: false,
                                            endAdornment: (
                                              <InputAdornment
                                                position="end"
                                                className={
                                                  classes.inputAdornment
                                                }
                                              >
                                                {!exchangeRatesData.rates[
                                                  currency
                                                ][month] && (
                                                  <Clear
                                                    className={
                                                      classes.inputAdornmentIconError
                                                    }
                                                  />
                                                )}
                                              </InputAdornment>
                                            ),
                                          }}
                                          client
                                        />
                                      </div>
                                    );
                                  },
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  )}
                  {!dataLoaded && loadingSpinner()}
                  {dataLoaded &&
                    Object.keys(exchangeRatesData).length === 0 && (
                      <SnackbarContent
                        message={t("modeling_exchange_rates.error_on_loading")}
                        color="danger"
                        paperClass={classes.snackbarPaper}
                        messageClass={classes.snackbarMessage}
                      />
                    )}
                </GridContainer>
              </CardBody>
            </Card>
          </div>
        </GridItem>
      </GridContainer>
      {addCurrencyDialog()}
      {removeCurrencyDialog()}
    </div>
  );
}
