import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import AuthContext from "../../contexts/AuthProvider";

import Tooltip from "@material-ui/core/Tooltip";

import {
  blackColor,
  dangerColor,
  grayColor,
  hexToRgb,
  whiteColor,
} from "../../assets/jss/material-dashboard-pro-react";

import {
  faChevronRight,
  faBackwardStep,
  faForwardStep,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThreeDots } from "react-loader-spinner";
import HelpTooltip from "../HelpTooltip/HelpTooltip";

const spacing = 3;
const scaleFrom = 8;
const marginRange = 0.2;
const color1 = [118, 16, 15];
const color2 = [168, 23, 22];
const color3 = [201, 128, 126];
const color4 = [233, 233, 229];
const color5 = [164, 190, 191];
const color6 = [96, 147, 154];
const color7 = [27, 104, 116];

const currency = "ARS";
const valueNames = {
  size: "Venta bruta",
  color: "Margen de servir",
};

const styles = () => ({
  wrapper: {
    width: "100%",
    height: "100%",
    flexGrow: "1",
    backgroundColor: whiteColor,
    borderRadius: "2px",
    position: "relative",
  },
  pathDescription: {
    position: "absolute",
    top: "1px",
    left: "4px",
    textTransform: "uppercase",
    fontSize: "clamp(1.2vh, 12px, 1.5vh)",
    "& > div": {
      display: "inline-block",
      "& > span": {
        marginRight: "5px",
      },
    },
  },
  stepButton: {
    fontSize: "16px",
    padding: "0 5px",
    color: whiteColor,
    backgroundColor: grayColor[6],
    borderRadius: "2px",
    transition: "all 0.2s",
    display: "inline-block",
    "&.enabled": {
      cursor: "pointer",
      "&:hover": {
        // opacity: "0.7",
        boxShadow: "0 0 4px 1px rgba(" + hexToRgb(blackColor) + ", 0.4)",
        transform: "scale(1.05)",
      },
    },
    "&:not(.enabled)": {
      opacity: "0.1",
    },
    "&.back": {
      marginRight: "10px !important",
    },
    "&.forward": {
      marginLeft: "6px !important",
    },
  },
  pathDescriptionItem: {
    display: "inline-block",
    transform: "translateY(-1px)",
    lineHeight: "1.3",
    padding: "0",
    "&:not(.fade)": {
      fontWeight: "bold",
      boxShadow: "inset 0 -2px 0 0 " + grayColor[6],
    },
    "&.fade": {
      cursor: "pointer",
      // fontSize: "clamp(1.1vh, 11px, 1.4vh)",
      color: grayColor[9],
    },
  },
  pathDescriptionIcon: {
    fontSize: "14px",
  },
  pathDescriptionDivider: {
    color: grayColor[9],
    display: "inline-block",
    fontSize: "clamp(1.0vh, 10px, 1.2vh)",
    transform: "translateY(-1px)",
  },
  legend: {
    position: "absolute",
    bottom: "-3px",
    left: "4px",
    color: grayColor[0],
    fontSize: "clamp(1.2vh, 12px, 1.5vh)",
    "& > div": {
      display: "inline-block",
      "& > span": {
        marginRight: "5px",
      },
    },
  },
  colorLegend: {
    position: "absolute",
    top: "0",
    right: "4px",
  },
  colorLegendScale: {
    width: "144px",
    height: "12px",
    borderRadius: "3px",
    background: `linear-gradient(to right, rgb(${color1[0]},${color1[1]},${color1[2]}), rgb(${color2[0]},${color2[1]},${color2[2]}), rgb(${color3[0]},${color3[1]},${color3[2]}), rgb(${color4[0]},${color4[1]},${color4[2]}), rgb(${color5[0]},${color5[1]},${color5[2]}), rgb(${color6[0]},${color6[1]},${color6[2]}), rgb(${color7[0]},${color7[1]},${color7[2]}))`,
    position: "relative",
    "& > div": {
      position: "absolute",
      fontSize: "clamp(1.2vh, 12px, 1.5vh)",
      lineHeight: "1",
      marginTop: "14px",
      borderRadius: "3px",
    },
    "& > div:first-child": {
      textAlign: "left",
      left: "0",
      top: "50%",
      transform: "translate(0, -50%)",
    },
    "& > div:nth-child(2)": {
      textAlign: "center",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    "& > div:nth-child(3)": {
      textAlign: "right",
      left: "100%",
      top: "50%",
      transform: "translate(-100%, -50%)",
    },
  },
  valuesOmitted: {
    position: "absolute",
    top: "1px",
    right: "156px",
    color: grayColor[0],
    fontSize: "clamp(1.2vh, 12px, 1.5vh)",
  },
  canvas: {
    backgroundColor: grayColor[13],
    position: "absolute",
    left: "1px",
    right: "1px",
    top: "31px",
    bottom: "1px",
    cursor: "pointer",
  },
  noDataNotice: {
    position: "absolute",
    fontSize: "20px",
    fontStyle: "italic",
    textAlign: "center",
    width: "100%",
    top: "29%",
    transform: "translate(0, -50%)",
    color: grayColor[0],
    zIndex: 1,
  },
  noDataNoticeArrow: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    "&.arrow1": {
      top: "16%",
      animationDelay: "0.5s",
    },
    "&.arrow2": {
      top: "18%",
      animationDelay: "0.3s",
    },
    "&.arrow3": {
      top: "20%",
      animationDelay: "0.1s",
    },
    animation: "$arrow-fade 1.5s infinite ease-in-out",
  },
  "@keyframes arrow-fade": {
    "0%": {
      opacity: "0.7",
    },
    "50%": {
      opacity: "0.1",
    },
    "100%": {
      opacity: "0.7",
    },
  },
  box: {
    position: "absolute",
  },
  parent: {
    zIndex: "1",
    outline: "2px solid white",
  },
  child: {
    userSelect: "none",
    fontSize: "clamp(1.2vh, 0.8rem, 1.5vh)",
    padding: "0.4vh",
    zIndex: "2",
    outline: "1px solid #ffffff88",
    transition: "opacity 0.15s",
    "&.canvasHover.parentHover:not(.childHover)": {
      opacity: "0.75",
    },
    "&.canvasHover:not(.parentHover):not(.childHover)": {
      opacity: "0.2",
    },
  },
  boxName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&.white": {
      color: whiteColor,
    },
  },
  mouseOverInfo: {
    position: "absolute",
    padding: "4px 6px",
    backgroundColor: whiteColor,
    opacity: "0.85",
    fontSize: "12px",
    lineHeight: "1.4",
    minWidth: "200px",
    zIndex: "10000",
    pointerEvents: "none",
    borderRadius: "2px",
    transition: "opacity 0.1s",
    "&.hidden": {
      opacity: "0",
    },
  },
  mouseOverValue: {
    fontWeight: "bold",
    "&.negative": {
      color: dangerColor[0],
    },
  },
  hidden: {
    visibility: "hidden",
  },
  clickedBox: {
    position: "absolute",
    zIndex: "3",
    borderRadius: "20%",
    transform: "scale(0)",
    animation: "$ripple-animation 600ms cubic-bezier(0,.6,.55,.95)",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    pointerEvents: "none",
  },
  "@keyframes ripple-animation": {
    to: {
      transform: "scale(1.0)",
      borderRadius: "0",
      opacity: "0",
    },
  },
  parentTag: {
    position: "absolute",
    fontSize: "12px",
    lineHeight: "1",
    padding: "2px",
    transform: "translate(0, calc(-100% - 1px))",
    borderRadius: "2px",
    color: whiteColor,
    backgroundColor: blackColor,
    zIndex: "4",
    opacity: "0.8",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    pointerEvents: "none",
  },
  selectedBox: {
    position: "absolute",
    zIndex: "500",
    pointerEvents: "none",
    "& > div": {
      position: "relative",
      width: "100%",
      height: "100%",
      "& > span": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: whiteColor + "44",
      },
    },
  },
});
const useStyles = makeStyles(styles);
const formatter = new Intl.NumberFormat("us-EN");

const Treemap = (props) => {
  let { userDetails } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const {
    isLoading,
    levels,
    dimensionNames,
    setSelection,
    setPnlSelection,
    levelsCount,
    parentLevel,
    setParentLevel,
    childrenLevel,
    data,
    totalNodes,
    avgServeMargin,
    selectedBox,
    setSelectedBox,
  } = props;

  const dataRef = useRef([]);
  const [treemap, setTreemap] = useState([]);

  const canvasRef = useRef(null);
  const mouseOverRef = useRef(null);
  const [mouseOverInfo, setMouseOverInfo] = useState(null);
  const [mouseOverPosition, setMouseOverPosition] = useState({ x: 0, y: 0 });
  const [mouseMoving, setMouseMoving] = useState(false);
  const mouseMovingTimer = useRef(null);
  const [canvasHover, setCanvasHover] = useState(false);
  const valuesOmitted = useRef(false);
  const [clickedBox, setClickedBox] = useState({
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });
  const [showClickedBox, setShowClickedBox] = useState(false);
  const [parentTagPos, setParentTagPos] = useState({ x: 0, y: 0 });

  const getColorPercentiles = (arr) => {
    if (avgServeMargin.current) {
      const min = avgServeMargin.current - marginRange;
      const q1 = avgServeMargin.current - (2 / 3) * marginRange;
      const q2 = avgServeMargin.current - (1 / 3) * marginRange;
      const q3 = avgServeMargin.current;
      const q4 = avgServeMargin.current + (1 / 3) * marginRange;
      const q5 = avgServeMargin.current + (2 / 3) * marginRange;
      const max = avgServeMargin.current + marginRange;
      return { min, q1, q2, q3, q4, q5, max };
    } else {
      let values = [];
      const extractValues = (o) => {
        for (let key in o) {
          if (typeof o[key] === "object") {
            extractValues(o[key]);
          } else if (key === "color" && !Object.keys(o).includes("children")) {
            if (o[key]) values.push(o[key]);
          }
        }
      };
      extractValues(arr);
      if (values.length === 0)
        return {
          min: null,
          q1: null,
          q2: null,
          q3: null,
          q4: null,
          q5: null,
          max: null,
        };
      values.sort((a, b) => a - b);
      const min = values[0];
      const q1 = values[Math.floor((1 / 6) * values.length) - 1];
      const q2 = values[Math.floor((2 / 6) * values.length) - 1];
      const q3 = values[Math.floor((3 / 6) * values.length) - 1];
      const q4 = values[Math.floor((4 / 6) * values.length) - 1];
      const q5 = values[Math.floor((5 / 6) * values.length) - 1];
      const max = values[values.length - 1];
      return { min, q1, q2, q3, q4, q5, max };
    }
  };
  const [colorPercentiles, setColorPercentiles] = useState(() => {
    const { min, q1, q2, q3, q4, q5, max } = getColorPercentiles(data);
    return { min, q1, q2, q3, q4, q5, max };
  });
  const dataHasChildren = useRef(
    data.some((obj) => Object.prototype.hasOwnProperty.call(obj, "children")),
  );

  const calculateTreemap = (data = dataRef.current) => {
    const colorPercentiles = getColorPercentiles(data);

    // region size functions
    const getTotalSize = (arr, positiveOnly = false) => {
      return arr.reduce((sum, item) => {
        if (positiveOnly) {
          return sum + Math.max(item.size || 0, 0);
        } else {
          return sum + (item.size || 0);
        }
      }, 0);
    };
    const getOrientation = (canvas) => {
      return canvas.x1 - canvas.x0 > canvas.y1 - canvas.y0 ? "left" : "top";
    };
    const getNecessarySize = (canvas) => {
      let offset = 0.3 - ((0.3 - 0.15) * Math.min(canvas.n, 250)) / 250;
      return canvas.s * offset;
    };
    const getBox = (
      canvas,
      orientation,
      size,
      nodeGroupTotalSize,
      nodeGroupConsumed,
      nodeGroupParticipationOnCanvasRemaining,
      isChild,
    ) => {
      let x, y, width, height;
      if (orientation === "left") {
        let addedX = isChild || canvas.x0 === 0 ? 0 : spacing / 2;
        let addedY =
          isChild || (canvas.y0 === 0 && nodeGroupConsumed === 0)
            ? 0
            : spacing / 2;
        x = canvas.x0 + addedX;
        y =
          canvas.y0 +
          (nodeGroupConsumed / nodeGroupTotalSize) * (canvas.y1 - canvas.y0) +
          addedY;
        width =
          nodeGroupParticipationOnCanvasRemaining * (canvas.x1 - canvas.x0) -
          (isChild ? 0 : spacing / 2 + addedX);
        height =
          (size / nodeGroupTotalSize) * (canvas.y1 - canvas.y0) -
          (isChild ? 0 : spacing / 2 + addedY);
      } else {
        let addedX =
          isChild || (canvas.x0 === 0 && nodeGroupConsumed === 0)
            ? 0
            : spacing / 2;
        let addedY = isChild || canvas.y0 === 0 ? 0 : spacing / 2;
        x =
          canvas.x0 +
          (nodeGroupConsumed / nodeGroupTotalSize) * (canvas.x1 - canvas.x0) +
          addedX;
        y = canvas.y0 + addedY;
        width =
          (size / nodeGroupTotalSize) * (canvas.x1 - canvas.x0) -
          (isChild ? 0 : spacing / 2 + addedX);
        height =
          nodeGroupParticipationOnCanvasRemaining * (canvas.y1 - canvas.y0) -
          (isChild ? 0 : spacing / 2 + addedY);
      }
      return { x, y, width, height };
    };
    // endregion

    // region color functions
    const interpolateColor = (color1, color2, factor) => {
      const result = color1.slice();
      for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - result[i]));
      }
      return result;
    };
    function getColor(value, min, q1, q2, q3, q4, q5, max) {
      let color;
      if (value <= min) {
        color = color1;
      } else if (value >= max) {
        color = color7;
      } else if (value <= q1) {
        const factor = (value - min) / (q1 - min);
        color = interpolateColor(color1, color2, factor);
      } else if (value <= q2) {
        const factor = (value - q1) / (q2 - q1);
        color = interpolateColor(color2, color3, factor);
      } else if (value <= q3) {
        const factor = (value - q2) / (q3 - q2);
        color = interpolateColor(color3, color4, factor);
      } else if (value <= q4) {
        const factor = (value - q3) / (q4 - q3);
        color = interpolateColor(color4, color5, factor);
      } else if (value <= q5) {
        const factor = (value - q4) / (q5 - q4);
        color = interpolateColor(color5, color6, factor);
      } else {
        const factor = (value - q5) / (max - q5);
        color = interpolateColor(color6, color7, factor);
      }
      return { r: color[0], g: color[1], b: color[2] };
    }
    // endregion

    let boxes = [];
    const calculate = (canvas, data, parent) => {
      let dataLeft = structuredClone(data);
      let totalSize = getTotalSize(dataLeft);
      if (totalSize <= 0) return [];
      let totalPositiveSize = getTotalSize(dataLeft, true);
      let canvasState = {
        x0: canvas.x,
        y0: canvas.y,
        x1: canvas.width + canvas.x,
        y1: canvas.height + canvas.y,
        s: totalPositiveSize,
        n: data.length,
      };
      let nodeGroup = [];
      let nodeGroupTotalSize = 0;
      let nodeGroupConsumed = 0;
      let nodeGroupParticipationOnCanvasRemaining = 0;
      let orientation;
      while (dataLeft.length > 0) {
        let node = dataLeft[0];
        if (node.size > 0) {
          if (!nodeGroup.includes(node.name)) {
            // create node group
            orientation = getOrientation(canvasState);
            // let sizeNecessaryToSquare = getSizeNecessaryToSquare(canvasLeft[currentCanvas])
            let sizeNecessary = getNecessarySize(canvasState);
            let sizeSum = 0;
            for (let i = 0; i < dataLeft.length; i++) {
              if (dataLeft[i].size > 0) {
                nodeGroup.push(dataLeft[i].name);
                sizeSum += dataLeft[i].size;
                if (sizeSum >= sizeNecessary) {
                  nodeGroupTotalSize = sizeSum;
                  break;
                }
              }
            }
            nodeGroupParticipationOnCanvasRemaining =
              nodeGroupTotalSize / canvasState.s;
            nodeGroupConsumed = 0;
          }
          let box = getBox(
            canvasState,
            orientation,
            node.size,
            nodeGroupTotalSize,
            nodeGroupConsumed,
            nodeGroupParticipationOnCanvasRemaining,
            parent !== null || !dataHasChildren.current,
          );
          box = {
            ...box,
            parent: parent === null && dataHasChildren.current,
            rgb: getColor(
              node.color,
              colorPercentiles.min,
              colorPercentiles.q1,
              colorPercentiles.q2,
              colorPercentiles.q3,
              colorPercentiles.q4,
              colorPercentiles.q5,
              colorPercentiles.max,
            ),
            name:
              node.name !== "" ? node.name : "● " + t("results.blank") + " ●",
            size: node.size,
            color: node.color,
            parentName:
              parent === null
                ? null
                : parent.name !== ""
                  ? parent.name
                  : "● " + t("results.blank") + " ●",
            parentSize: parent?.size,
            parentColor: parent?.color,
          };
          boxes.push(box);
          if (nodeGroup.indexOf(node.name) < nodeGroup.length - 1) {
            nodeGroupConsumed += node.size;
          } else {
            // close node group
            nodeGroup = [];
            if (orientation === "left") {
              canvasState.x0 +=
                nodeGroupParticipationOnCanvasRemaining *
                (canvasState.x1 - canvasState.x0);
            } else {
              canvasState.y0 +=
                nodeGroupParticipationOnCanvasRemaining *
                (canvasState.y1 - canvasState.y0);
            }
          }
          canvasState.s -= node.size;
          if (node.children) {
            calculate(box, node.children, node);
          }
        }
        dataLeft.shift();
      }
    };
    const canvasRect = canvasRef.current.getBoundingClientRect();
    let canvas = {
      width: canvasRect.width,
      height: canvasRect.height,
      x: 0,
      y: 0,
    };
    calculate(canvas, data, null);
    return boxes;
  };

  const handleResize = () => {
    setTreemap(calculateTreemap(dataRef.current));
  };
  useEffect(() => {
    i18n.changeLanguage(i18n.language.slice(0, 2));
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setTreemap(calculateTreemap(dataRef.current));
  }, [i18n.language]);
  useEffect(() => {
    dataRef.current = data; // for further use in handleSize event
    const { min, q1, q2, q3, q4, q5, max } = getColorPercentiles(data);
    setColorPercentiles({ min, q1, q2, q3, q4, q5, max });
    dataHasChildren.current = data.some((obj) =>
      Object.prototype.hasOwnProperty.call(obj, "children"),
    );
    setTreemap(calculateTreemap());
    valuesOmitted.current = totalNodes.current >= 250;
  }, [data]);
  useEffect(() => {
    // place the selected box accordingly
    if (selectedBox.display) {
      for (let i = 0; i < treemap.length; i++) {
        if (
          treemap[i].name === selectedBox.childrenName &&
          treemap[i].parentName === selectedBox.parentName
        ) {
          setSelectedBox((draft) => {
            draft.x = treemap[i].x;
            draft.y = treemap[i].y;
            draft.width = treemap[i].width;
            draft.height = treemap[i].height;
          });
          break;
        }
      }
    }
    // set the mouse over with the new treemap
    if (canvasHover) {
      const canvas_posX = mouseOverPosition.x - 20;
      const canvas_posY = mouseOverPosition.y - 50;
      treemap.forEach((box) => {
        if (
          canvas_posX >= box.x &&
          canvas_posX <= box.x + box.width &&
          canvas_posY >= box.y &&
          canvas_posY <= box.y + box.height
        ) {
          let { name, size, color, parentName, parentSize, parentColor } = box;
          setMouseOverInfo({
            name,
            size,
            color,
            parentName,
            parentSize,
            parentColor,
          });
        }
      });
    }
  }, [treemap]);

  const handleCanvasMouseOver = () => {
    setCanvasHover(true);
  };

  const handleBoxMouseOver = (e, box) => {
    let { name, size, color, parentName, parentSize, parentColor } = box;
    setMouseOverInfo({
      name,
      size,
      color,
      parentName,
      parentSize,
      parentColor,
    });
    if (parentName) {
      setParentTagPos(getParentPos(parentName));
    }
  };
  const handleBoxMouseMove = (e) => {
    if (mouseOverInfo) {
      const canvasRect = canvasRef.current.getBoundingClientRect();
      const mouseOverInfoRect = mouseOverRef.current.getBoundingClientRect();
      let newPosX = e.pageX - canvasRect.left + window.scrollX + 20;
      let newPosY = e.pageY - canvasRect.top + window.scrollY + 50;
      let offsetX = 0,
        offsetY = 0;
      if (
        e.pageX + window.scrollX + mouseOverInfoRect.width + 20 >=
        canvasRect.right
      ) {
        offsetX = -mouseOverInfoRect.width - 30;
      }
      if (
        e.pageY + window.scrollY + mouseOverInfoRect.height + 20 >=
        canvasRect.bottom
      ) {
        offsetY = -mouseOverInfoRect.height - 20;
      }
      setMouseOverPosition({ x: newPosX + offsetX, y: newPosY + offsetY });
      setMouseMoving(true);
      clearTimeout(mouseMovingTimer.current);
      mouseMovingTimer.current = setTimeout(() => {
        setMouseMoving(false);
      }, 300);
    }
  };
  const setMouseOutInfo = () => {
    setCanvasHover(false);
    setMouseOverInfo(null);
  };

  const handleBoxOnClick = () => {
    let childrenName = mouseOverInfo.name;
    let parentName = mouseOverInfo.parentName;

    setPnlSelection((draft) => {
      draft[levels[parentLevel]] = childrenName;
    });
    if (levelsCount.current > parentLevel + 1) {
      setSelection((draft) => {
        draft[levels[parentLevel]] = childrenName;
      });
      setParentLevel((curLevel) => curLevel + 1);
    } else {
      setSelectedBox((draft) => {
        draft.display = true;
      });
    }

    // visual effect
    let x, y, width, height;
    for (let i = 0; i < treemap.length; i++) {
      if (
        treemap[i].name === childrenName &&
        treemap[i].parentName === parentName
      ) {
        x = treemap[i].x;
        y = treemap[i].y;
        width = treemap[i].width;
        height = treemap[i].height;
        break;
      }
    }

    // selectedBox = permanent
    // clickedBox = click effect only
    setSelectedBox((draft) => {
      draft.x = x;
      draft.y = y;
      draft.width = width;
      draft.height = height;
      draft.parentName = parentName;
      draft.childrenName = childrenName;
    });
    setClickedBox({ x, y, width, height });
    setShowClickedBox(true);
    setTimeout(() => {
      setShowClickedBox(false);
    }, 600);
  };
  const handleReturnLevelClick = (step) => {
    if (parentLevel <= 0) return;
    const elements_being_deleted = Object.values(levels)
      .slice(parentLevel - step, Object.values(levels).length)
      .filter((val) => val !== null);
    setSelection((draft) => {
      elements_being_deleted.forEach((el) => {
        delete draft[el];
      });
    });
    setPnlSelection((draft) => {
      elements_being_deleted.forEach((el) => {
        delete draft[el];
      });
    });
    setParentLevel((curLevel) => curLevel - step);
  };
  const handleAdvanceLevelClick = (step) => {
    if (parentLevel === levelsCount.current - step) return;
    setParentLevel((curLevel) => curLevel + step);
  };

  const treemapBox = (box, key) => {
    return (
      <div
        key={key}
        id={`box${key}`}
        className={cx({
          [classes.box]: true,
          [classes.parent]: box.parent,
          [classes.child]: !box.parent,
          ["childHover"]:
            mouseOverInfo &&
            (mouseOverInfo.parentName || !dataHasChildren.current) &&
            (mouseOverInfo.parentName === box.parentName ||
              !dataHasChildren.current) &&
            mouseOverInfo.name === box.name,
          ["parentHover"]:
            (mouseOverInfo &&
              mouseOverInfo.parentName &&
              mouseOverInfo.parentName === box.parentName) ||
            !dataHasChildren.current,
          ["canvasHover"]: canvasHover,
        })}
        style={{
          left: `${box.x}px`,
          top: `${box.y}px`,
          width: `${box.width}px`,
          height: `${box.height}px`,
          transform: `translateX(-${(100 * (1 - Math.min(box.width / scaleFrom, 1))) / 2}%) scaleX(${Math.min(box.width / scaleFrom, 1)}) translateY(-${(100 * (1 - Math.min(box.height / scaleFrom, 1))) / 2}%) scaleY(${Math.min(box.height / scaleFrom, 1)})`,
          backgroundColor: box.parent
            ? "white"
            : `rgb(${box.rgb.r}, ${box.rgb.g}, ${box.rgb.b})`,
        }}
        onMouseOver={(e) => handleBoxMouseOver(e, box)}
        onMouseMove={(e) => handleBoxMouseMove(e)}
        onClick={handleBoxOnClick}
      >
        {!box.parent && box.width >= 30 && box.height >= 30 && (
          <div
            className={cx({
              [classes.boxName]: true,
              ["white"]: (box.rgb.r + box.rgb.g + box.rgb.b) / 3 < 168,
            })}
            style={{
              width: box.width,
            }}
          >
            {box.name}
          </div>
        )}
      </div>
    );
  };
  const getParentPos = (parentName) => {
    for (let i = 0; i < treemap.length; i++) {
      if (treemap[i].name === parentName) {
        return { x: treemap[i].x, y: treemap[i].y };
      }
    }
  };

  // region Elements
  const spinner = isLoading && (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <ThreeDots
        width={"120px"}
        color={userDetails.implementation_color}
        ariaLabel="loading"
      />
    </div>
  );

  const pathDescription = (
    <div
      className={cx({
        [classes.pathDescription]: true,
        // [classes.hidden]: isLoading,
      })}
    >
      <div>
        <Tooltip
          placement={"top"}
          title={t("treemap.back_one_level")}
          PopperProps={{
            style: { marginBottom: "-8px" },
          }}
        >
          <span
            className={cx({
              [classes.stepButton]: true,
              ["back"]: true,
              ["enabled"]: parentLevel > 0 && !isLoading,
            })}
            onClick={() => handleReturnLevelClick(1)}
          >
            <FontAwesomeIcon icon={faBackwardStep} />
          </span>
        </Tooltip>
      </div>
      {Object.values(levels)
        .filter((val) => val !== null)
        .map((level, key) => {
          return (
            <div key={key}>
              <span
                className={cx({
                  [classes.pathDescriptionItem]: true,
                  ["fade"]: parentLevel !== key,
                })}
                onClick={
                  parentLevel > key
                    ? () => handleReturnLevelClick(parentLevel - key)
                    : parentLevel < key
                      ? () => handleAdvanceLevelClick(key - parentLevel)
                      : null
                }
              >
                {dimensionNames[level][i18n.language]}
              </span>
              {key + 1 < levelsCount.current && (
                <span
                  className={cx({
                    [classes.pathDescriptionDivider]: true,
                  })}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
              )}
            </div>
          );
        })}
      <div>
        <Tooltip
          placement={"top"}
          title={t("treemap.forward_one_level")}
          PopperProps={{
            style: { marginBottom: "-8px" },
          }}
        >
          <span
            className={cx({
              [classes.stepButton]: true,
              ["forward"]: true,
              ["enabled"]: parentLevel < levelsCount.current - 1 && !isLoading,
            })}
            onClick={() => handleAdvanceLevelClick(1)}
          >
            <FontAwesomeIcon icon={faForwardStep} />
          </span>
        </Tooltip>
      </div>
    </div>
  );

  const colorLegend = data.length > 0 && (
    <div
      className={cx({
        [classes.colorLegend]: true,
        [classes.hidden]: isLoading,
      })}
    >
      <div
        className={cx({
          [classes.colorLegendScale]: true,
        })}
      >
        <div>{(100 * colorPercentiles.min).toFixed(1)}%&minus;</div>
        <div>{(100 * colorPercentiles.q3).toFixed(1)}%</div>
        <div>{(100 * colorPercentiles.max).toFixed(1)}%&#43;</div>
      </div>
    </div>
  );

  const noDataNotice = data.length === 0 && (
    <>
      {[...Array(3).keys()].map((key) => {
        return (
          <div
            key={key}
            className={cx({
              [classes.noDataNoticeArrow]: true,
              ["arrow" + (key + 1)]: true,
              [classes.hidden]: isLoading,
            })}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              width="100px"
              height="30px"
              preserveAspectRatio="none"
              fill={userDetails.implementation_color}
            >
              <polygon points="50,0 100,93 100,100 50,7 0,100 0,93" />
            </svg>
          </div>
        );
      })}
      <div
        className={cx({
          [classes.noDataNotice]: true,
          [classes.hidden]: isLoading,
        })}
      >
        {t("treemap.no_data_notice")}
      </div>
    </>
  );

  const parentTag = childrenLevel.current && mouseOverInfo?.parentName && (
    <div
      className={cx({
        [classes.parentTag]: true,
      })}
      style={{
        left: `${parentTagPos.x}px`,
        top: `${parentTagPos.y}px`,
      }}
    >
      {`${dimensionNames[levels[parentLevel]][i18n.language]}: ${mouseOverInfo.parentName}`}
    </div>
  );

  const graph = (
    <div
      className={cx({
        [classes.canvas]: true,
        [classes.hidden]: isLoading,
      })}
      ref={canvasRef}
      onMouseOver={handleCanvasMouseOver}
      onMouseOut={setMouseOutInfo}
    >
      {treemap.map((box, key) => {
        return treemapBox(box, key);
      })}
      {showClickedBox && (
        <div
          className={cx({
            [classes.clickedBox]: true,
          })}
          style={{
            left: `${clickedBox.x}px`,
            top: `${clickedBox.y}px`,
            width: `${clickedBox.width}px`,
            height: `${clickedBox.height}px`,
          }}
        />
      )}
      {selectedBox.display && (
        <div
          className={cx({
            [classes.selectedBox]: true,
          })}
          style={{
            left: `${selectedBox.x}px`,
            top: `${selectedBox.y}px`,
            width: `${selectedBox.width}px`,
            height: `${selectedBox.height}px`,
          }}
        >
          <div>
            <span
              style={{
                fontSize: `${Math.min(selectedBox.height / 2, selectedBox.width * 0.8)}px`,
              }}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </span>
          </div>
        </div>
      )}
      {parentTag}
    </div>
  );

  const mouseOverInfoEl = (
    <div
      className={cx({
        [classes.mouseOverInfo]: true,
        ["hidden"]: !mouseOverInfo || mouseMoving || showClickedBox,
        [classes.hidden]: isLoading,
      })}
      style={{
        left: `${mouseOverPosition.x}px`,
        top: `${mouseOverPosition.y}px`,
      }}
      onMouseOver={(e) => e.preventDefault()}
      ref={mouseOverRef}
    >
      {mouseOverInfo && mouseOverInfo.parentName && (
        <>
          <div>
            {dimensionNames[levels[parentLevel]][i18n.language]}:{" "}
            <span
              className={cx({
                [classes.mouseOverValue]: true,
              })}
            >
              {mouseOverInfo.parentName}
            </span>
          </div>
          <div>
            {valueNames.size}:{" "}
            <span
              className={cx({
                [classes.mouseOverValue]: true,
              })}
            >
              {currency}{" "}
              {formatter.format(mouseOverInfo.parentSize).split(".")[0]}
            </span>
          </div>
          <div>
            {valueNames.color}:{" "}
            <span
              className={cx({
                [classes.mouseOverValue]: true,
                ["negative"]: mouseOverInfo.parentColor < 0,
              })}
            >
              {(100 * mouseOverInfo.parentColor).toFixed(1) + "%"}
            </span>
          </div>
          <br />
        </>
      )}
      {mouseOverInfo && (
        <>
          <div>
            {childrenLevel.current
              ? dimensionNames[levels[childrenLevel.current]][i18n.language]
              : dimensionNames[levels[parentLevel]][i18n.language]}
            :{" "}
            <span
              className={cx({
                [classes.mouseOverValue]: true,
              })}
            >
              {mouseOverInfo.name}
            </span>
          </div>
          <div>
            {valueNames.size}:{" "}
            <span
              className={cx({
                [classes.mouseOverValue]: true,
              })}
            >
              {currency} {formatter.format(mouseOverInfo.size).split(".")[0]}
            </span>
          </div>
          <div>
            {valueNames.color}:{" "}
            <span
              className={cx({
                [classes.mouseOverValue]: true,
                ["negative"]: mouseOverInfo.color < 0,
              })}
            >
              {(100 * mouseOverInfo.color).toFixed(1) + "%"}
            </span>
          </div>
        </>
      )}
    </div>
  );

  const valuesOmittedEl = valuesOmitted.current && (
    <div
      className={cx({
        [classes.valuesOmitted]: true,
        [classes.hidden]: isLoading,
      })}
    >
      {t("treemap.values_omitted")}
      <HelpTooltip
        tooltip={<div>{t("treemap.values_omitted_detail")}</div>}
        small
      />
    </div>
  );
  // endregion

  return (
    <div className={classes.wrapper}>
      {spinner}
      {pathDescription}
      {/*{legend}*/}
      {colorLegend}
      {noDataNotice}
      {graph}
      {mouseOverInfoEl}
      {valuesOmittedEl}
    </div>
  );
};

Treemap.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  levels: PropTypes.object.isRequired,
  dimensionNames: PropTypes.object.isRequired,
  setSelection: PropTypes.func.isRequired,
  setPnlSelection: PropTypes.func.isRequired,
  levelsCount: PropTypes.object.isRequired,
  parentLevel: PropTypes.number.isRequired,
  setParentLevel: PropTypes.func.isRequired,
  childrenLevel: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  totalNodes: PropTypes.object.isRequired,
  avgServeMargin: PropTypes.object.isRequired,
  selectedBox: PropTypes.object.isRequired,
  setSelectedBox: PropTypes.func.isRequired,
};

export default Treemap;
