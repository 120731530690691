/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import login from "assets/img/login-bg.jpg";
import logo_insights from "assets/img/logo_profit_insights.png";
import logo_integration from "assets/img/logo_integration_complete.png"

const useStyles = makeStyles(styles);

export default function Pages(props) {
  const { ...rest } = props;
  const wrapper = React.createRef();
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBgImage = () => {
    return login;
  };
  return (
    <div style={{ position: "relative" }}>
      {/*<AuthNavbar brandText={"Profit InSights"} {...rest} />*/}
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{backgroundImage: "url(" + getBgImage() + ")"}}
        >
          <img src={logo_insights} className={classes.profitInSightsLogo} alt={"Profit InSights logo"}/>
          {/*<img src={logo_integration} className={classes.integrationLogo} alt={"Integration logo"}/>*/}
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/auth" to="/auth/login"/>
          </Switch>
          <Footer integrationGray loginPage/>
        </div>
      </div>
    </div>
  );
}
