import React, { useContext, useState, useEffect } from "react";

// External libraries
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ThreeDots } from "react-loader-spinner";
import { toast, Slide as ToastSlide } from "react-toastify";

// Material-UI components and icons
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import Assignment from "@material-ui/icons/Assignment";
import Save from "@material-ui/icons/Save";

// Internal components and utilities
import AuthContext from "../../contexts/AuthProvider";
import { client } from "../../services/axiosClient";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import HelpTooltip from "components/HelpTooltip/HelpTooltip";
import cx from "classnames";
import styles from "../../assets/jss/material-dashboard-pro-react/views/userManagmentStyle.js";

export default function UserManagement() {
  const { userDetails } = useContext(AuthContext);
  const client_styles = (theme) => ({
    ...styles(theme, userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  // Allowed roles for access control
  const allowedRoles = ["CAD", "IMG", "DEV"];
  const isDevOrImg = ["DEV", "IMG"].includes(
    userDetails.user_role.toUpperCase().trim(),
  );

  // Redirect unauthorized users
  if (!allowedRoles.includes(userDetails.user_role.toUpperCase().trim())) {
    return <h1>{t("user_management.unauthorized")}</h1>;
  }

  // Notification for save actions with success/fail messages
  const notifySaveLog = (type) => {
    const message =
      type === "success"
        ? t("user_management.save_success")
        : t("user_management.save_failed");
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
      type: type === "success" ? "info" : "error",
      transition: ToastSlide,
    });
  };

  const [userList, setUserList] = useState([]);
  const [originalRoles, setOriginalRoles] = useState({});
  const [roleOptions, setRoleOptions] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [modifiedRoles, setModifiedRoles] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  // Fetch and format user list and roles from API
  useEffect(() => {
    const fetchUserList = async () => {
      try {
        const response = await client.post("api/user_management/");

        const { users, roles } = response.data;

        // Filter roles based on current user's role
        const filteredRoles = isDevOrImg
          ? roles
          : roles.filter((role) => role.code !== "CAD");

        setRoleOptions(
          filteredRoles.map((role) => ({
            value: role.code,
            label: t(`user_management.roles.${role.code.toLowerCase()}`),
          })),
        );

        // Map user data to include role codes and last login
        const transformedUsers = users.map((user) => {
          const roleCode =
            roles.find((role) => role.name === user.role)?.code || user.role;
          return {
            ...user,
            role: roleCode,
            lastLogin: user.lastLogin || null,
          };
        });

        setUserList(transformedUsers);

        // Initialize original roles for tracking changes
        const initialRoles = {};
        transformedUsers.forEach((user) => {
          initialRoles[user.id] = user.role;
        });
        setOriginalRoles(initialRoles);

        setDataLoaded(true);
      } catch (error) {
        console.error("Error fetching user list:", error);
      }
    };

    fetchUserList();
  }, [userDetails.token, isDevOrImg, t]);

  // Detect changes in modified roles
  useEffect(() => {
    setHasChanges(Object.keys(modifiedRoles).length > 0);
  }, [modifiedRoles]);

  // Update role options when language changes
  useEffect(() => {
    setRoleOptions((prevOptions) =>
      prevOptions.map((option) => ({
        ...option,
        label: t(`user_management.roles.${option.value.toLowerCase()}`),
      })),
    );
  }, [i18n.language, t]);

  // Handle role change and update modified roles
  const handleRoleChange = (event, userId) => {
    const newRoleCode = event.target.value;
    const user = userList.find((user) => user.id === userId);

    // Restrict changing role to/from "CAD" unless current user is "DEV" or "IMG"
    if ((user.role === "CAD" || newRoleCode === "CAD") && !isDevOrImg) {
      return;
    }

    setUserList((prevList) =>
      prevList.map((user) =>
        user.id === userId
          ? {
              ...user,
              role: newRoleCode,
              isRoleImmutable: ["DEV", "IMG"].includes(newRoleCode), // apenas DEV/IMG ficam imutáveis
            }
          : user,
      ),
    );

    // Track modified roles
    if (newRoleCode !== originalRoles[userId]) {
      setModifiedRoles((prevModifiedRoles) => ({
        ...prevModifiedRoles,
        [userId]: newRoleCode,
      }));
    } else {
      setModifiedRoles((prevModifiedRoles) => {
        const updatedModifiedRoles = { ...prevModifiedRoles };
        delete updatedModifiedRoles[userId];
        return updatedModifiedRoles;
      });
    }
  };

  // Save role changes to the server
  const handleSave = async () => {
    if (!hasChanges) {
      notifySaveLog("info");
      return;
    }

    setIsLoading(true);
    try {
      await client.post("api/user_management_save_roles/", {
        modifiedRoles,
      });

      // Update original roles with saved changes
      const updatedOriginalRoles = { ...originalRoles };
      Object.keys(modifiedRoles).forEach((userId) => {
        updatedOriginalRoles[userId] = modifiedRoles[userId];
      });
      setOriginalRoles(updatedOriginalRoles);
      setModifiedRoles({});
      setHasChanges(false);
      notifySaveLog("success");
    } catch (error) {
      console.error("Error saving changes:", error);
      notifySaveLog("error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <GridContainer style={{ marginTop: "-40px" }}>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="client" icon>
            <CardIcon color="client">
              <Assignment />
            </CardIcon>
            <h4
              className={classes.cardIconTitle}
              style={{ marginBottom: "0px !important" }}
            >
              {t("user_management.user_list")}
            </h4>
            <GridContainer
              justify="flex-end"
              style={{
                position: "absolute",
                top: "-5px",
              }}
            >
              <GridItem
                style={{
                  position: "absolute",
                  right: "35px",
                  top: "10px",
                }}
              >
                {/* Conditionally render the spinner if isLoading is true */}
                {isLoading && (
                  <ThreeDots
                    color={userDetails.implementation_color}
                    height={20}
                    width={20}
                    ariaLabel="loading"
                  />
                )}
              </GridItem>
              <GridItem>
                <Button
                  justIcon
                  color="client"
                  className={cx({
                    [classes.cardButton]: true,
                    [classes.attention]: hasChanges || isLoading,
                    [classes.loadingAnimation]: !isLoading,
                    [classes.actionDisabled]: !hasChanges || isLoading,
                  })}
                  onClick={handleSave}
                  disabled={!hasChanges || isLoading}
                >
                  <Save className={classes.cardButtonIcon} />
                </Button>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody style={{ paddingTop: "2px" }}>
            <div style={{ overflowX: dataLoaded ? "auto" : "hidden" }}>
              {!dataLoaded ? (
                <GridContainer justify="center" alignItems="center">
                  <ThreeDots
                    color={userDetails.implementation_color}
                    ariaLabel="loading"
                    height={"80px"}
                    width={"80px"}
                  />
                </GridContainer>
              ) : (
                <div
                  style={{
                    overflowX: "auto",
                    minWidth: "800px",
                  }}
                >
                  <Table className={`${classes.table} ${classes.compactTable}`}>
                    <TableHead>
                      <TableRow className={classes.tableRow}>
                        {["user", "email", "role", "last_login"].map(
                          (key, index) => (
                            <TableCell
                              key={index}
                              className={classes.tableHeadCell}
                            >
                              {t(`user_management.columns.${key}`)}
                              {key === "role" && (
                                <HelpTooltip
                                  tooltip={
                                    <div>
                                      {roleOptions.map((role) => (
                                        <div key={role.value}>
                                          <span className={classes.toolTipRole}>
                                            {t(
                                              `user_management.roles.${role.value.toLowerCase()}`,
                                            )}
                                            :
                                          </span>
                                          <span
                                            className={
                                              classes.toolTipDescription
                                            }
                                          >
                                            {" " +
                                              t(
                                                `user_management.roles_descriptions.${role.value.toLowerCase()}`,
                                              )}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  }
                                />
                              )}
                            </TableCell>
                          ),
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userList.map((user) => (
                        <TableRow key={user.id} className={classes.tableRow}>
                          <TableCell
                            className={classes.tableBodyCell}
                            style={{ width: "3%" }}
                          >
                            {user.name}
                          </TableCell>
                          <TableCell
                            className={classes.tableBodyCell}
                            style={{ width: "2%" }}
                          >
                            {user.email}
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: `1px solid rgba(0, 0, 0, 0.05)`,
                              width: "3%",
                            }}
                          >
                            {user.isRoleImmutable &&
                            !(isDevOrImg && user.role === "CAD") ? (
                              <span
                                className={classes.select}
                                style={{ paddingLeft: "1px" }}
                              >
                                {t(
                                  `user_management.roles.${user.code.toLowerCase()}`,
                                )}
                              </span>
                            ) : (
                              <FormControl
                                fullWidth
                                className={`${classes.selectFormControl} ${classes.selectFormControlOverride}`}
                                style={{ width: "70%" }}
                              >
                                <Select
                                  value={user.role}
                                  onChange={(event) =>
                                    handleRoleChange(event, user.id)
                                  }
                                  MenuProps={{
                                    className: classes.selectMenu,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                  classes={{
                                    select: `${classes.select} ${classes.selectFormControlOverride}`,
                                  }}
                                >
                                  {roleOptions.map((role) => (
                                    <MenuItem
                                      key={role.value}
                                      value={role.value}
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected:
                                          classes.selectMenuItemSelected,
                                      }}
                                    >
                                      {role.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </TableCell>

                          <TableCell
                            className={classes.tableBodyCell}
                            style={{ width: "2%" }}
                          >
                            {user.lastLogin
                              ? moment
                                  .utc(user.lastLogin)
                                  .local()
                                  .locale(i18n.language)
                                  .fromNow()
                                  .replace(/^\w/, (c) => c.toUpperCase())
                              : t("user_management.never_logged_in").replace(
                                  /^\w/,
                                  (c) => c.toUpperCase(),
                                )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
