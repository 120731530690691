/* useBackend = true means that the production backend will be used */
/* useBackend = false means that the local backend will be used */
/* this option only applies in local environment */
export const useBackend = true;

export const baseUrl =
  process.env.NODE_ENV && process.env.NODE_ENV === "development" && !useBackend
    ? "http://localhost:8000/"
    : "https://api.profit-insights.int.digital/";

export const getOom = (median) => {
  let oom = {};
  if (Math.abs(median) >= 1e9) {
    oom = {
      div: 1e9,
      x: "b",
    };
  } else if (Math.abs(median) >= 1e6) {
    oom = {
      div: 1e6,
      x: "m",
    };
  } else if (Math.abs(median) >= 1e3) {
    oom = {
      div: 1e3,
      x: "k",
    };
  } else {
    oom = {
      div: 1,
      x: "",
    };
  }
  return oom;
};

export const objectsEqual = (obj1, obj2) => {
  if (!obj1 || !obj2) return false;
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};
