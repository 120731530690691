import {
  cardTitle,
  dangerColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";
import modalStyle from "../modalStyle";
import customSelectStyle from "../customSelectStyle";

const exchangeRatesStyle = (theme, clientColor) => ({
  ...modalStyle(theme),
  ...customSelectStyle(theme, clientColor),
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  topInformation: {
    fontSize: "16px",
    width: "100%",
  },
  standardCurrency: {
    marginTop: "10px",
  },
  additionalCurrencies: {
    marginTop: "20px",
  },
  additionalCurrencyButtons: {
    marginTop: "0",
    marginLeft: "-2px",
    "& > button": {
      paddingLeft: "8px",
      paddingRight: "15px",
      marginRight: "5px",
      "& > span > svg": {
        marginTop: "-2px",
      },
    },
  },
  attention: {
    animation: "$attention 3s ease infinite",
  },
  "@keyframes attention": {
    "10%": {
      transform: "scale(1.1)",
    },
    "13.33%,20%": {
      transform: "rotate(-10deg) scale(1.1)",
    },
    "16.67%": {
      transform: "rotate(10deg) scale(1.1)",
    },
    "23.33%": {
      transform: "rotate(0deg) scale(1.1)",
    },
    "33%": {
      transform: "scale(1)",
    },
  },
  comments: {
    width: "100%",
    marginTop: "12px",
    fontSize: "12px",
    lineHeight: "1.4",
  },
  tableWrapper: {
    marginTop: "6px",
    width: "100%",
    display: "inline-block",
    overflow: "hidden",
    "@media (max-width: 1320px)": {
      overflow: "auto !important",
    },
  },
  currencyTable: {
    width: "100%",
    whiteSpace: "nowrap",
    display: "table-row",
  },
  currencyTableRow: {
    paddingLeft: "8px",
    paddingRight: "2px",
    width: "100%",
    height: "42px",
    display: "flex",
    justifyContent: "flex",
    alignItems: "center",
    "&$currencyTableRow:nth-child(even)": {
      backgroundColor: grayColor[13],
    },
  },
  currencyTableHeader: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  currencyTableColMonth: {
    width: "100px",
    paddingRight: "10px",
  },
  currencyTableColCurrency: {
    textAlign: "left",
    marginLeft: "6px",
    width: "100px",
    "&:not(currencyTableColCurrency:last-child)": {
      marginRight: "6px",
    },
  },
  currencyTableBodyCol: {},
  inputAdornment: {
    marginLeft: "0",
    position: "relative",
  },
  inputAdornmentIconError: {
    color: dangerColor[0] + "!important",
  },
  removeCurrencyWarning: {
    marginTop: "12px",
    fontSize: "12px",
    fontStyle: "italic",
    lineHeight: "1.4",
  },
  currencyAlreadyExistsWarning: {
    marginTop: "12px",
    fontSize: "12px",
    lineHeight: "1.4",
    color: dangerColor[0],
  },
  invisible: {
    visibility: "hidden",
  },
  snackbarPaper: {
    width: "100%",
  },
  snackbarMessage: {
    maxWidth: "100% !important",
  },
});

export default exchangeRatesStyle;
