/*eslint-disable*/
import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import {
  blackColor,
  dangerColor,
  grayColor, hexToRgb,
} from "../../assets/jss/material-dashboard-pro-react";
import AuthContext from "../../contexts/AuthProvider";

import { Close } from "@material-ui/icons";
import { ThreeDots } from "react-loader-spinner";

const spacing = 8;

const styles = (clientColor) => ({
  canvasRoot: {
    width: "100%",
    position: "relative",
    flexWrap: "wrap",
  },
  elementRoot: {
    backgroundColor: grayColor[8],
    padding: "1px 5px",
    borderRadius: "4px",
    whiteSpace: "nowrap",
    display: "inline-block",
    zIndex: "100",
    transition: "all 0.2s",
    marginRight: spacing,
    marginBottom: spacing,
  },
  drag: {
    cursor: "grab",
  },
  dragging: {
    opacity: "0.4",
  },
  selected: {
    color: clientColor,
    backgroundColor: grayColor[13],
  },
  divider: {
    width: "100%",
    margin: "1px 0 0 0",
    border: "0 0 1px 0",
    borderStyle: "solid",
    borderColor: clientColor,
    opacity: "0.4",
  },
  groupsWrapper: {
    width: "calc(100% + 8px)",
    marginTop: "9px",
    height: "38px",
    display: "flex",
    margin: "0 -4px",
  },
  groupRoot: {
    flex: "1",
    padding: "1px 8px",
    margin: "0 4px",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: grayColor[13],
    transition: "all 0.15s ease-in-out",
    position: "relative",
    "&.available.dragging": {
      boxShadow: "0 0 5px 0 rgba(" + hexToRgb(blackColor) + ", 0.3)",
    },
    "&:not(.available).dragging": {
      opacity: "0.3",
      pointerEvents: "none",
    },
    "&:not(.available)": {
      color: grayColor[0],
      opacity: "0.6",
    },
    "&.hovered.available": {
      backgroundColor: grayColor[15],
    },
    borderRadius: "3px",
  },
  groupName: {
    display: "flex",
    float: "left",
    alignItems: "center",
  },
  selectedElement: {
    position: "absolute",
    right: "25px",
    top: "50%",
    transition: "all 0.2s ease-in-out",
    "&:not(.withItem)": {
      fontSize: "16px",
      transform: "translate(0, -52%)",
    },
    "&.withItem": {
      fontSize: "14px",
      transform: "translate(0, -80%)",
    },
  },
  selectedItem: {
    position: "absolute",
    textAlign: "right",
    right: "25px",
    top: "50%",
    fontSize: "11px",
    color: grayColor[0],
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "80%",
  },
  removeSelectionButton: {
    position: "absolute",
    right: "4px",
    top: "50%",
    transform: "translate(0, -52%)",
    width: "18px",
    height: "18px",
    color: dangerColor[0],
    cursor: "pointer",
  },
  hidden: {
    visibility: "hidden",
  },
  disabled: {
    opacity: 0.7,
    pointerEvents: "none",
  },
});

const DraggableCanvas = (props) => {
  let { userDetails } = useContext(AuthContext);
  const client_styles = () => ({
    ...styles(userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();

  const { i18n } = useTranslation();

  const {
    isLoading,
    dimensions,
    dimensionNames,
    groupsName,
    levels,
    setLevels,
    disabled,
  } = props;

  const canvasRef = useRef(null);
  const elementRefs = useRef([]);
  const dividerRef = useRef(null);
  const groupsRefs = useRef([]);
  const levelsRef = useRef(levels);

  const levelsCount = () => Object.keys(levels).length;

  const groups = useRef(
    [...Array(levelsCount()).keys()].map((key) => {
      return key + 1;
    }),
  );
  const [hoveredGroup, setHoveredGroup] = useState(null);
  const [draggingElement, setDraggingElement] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(i18n.language.slice(0, 2));

    const handleDrop = (e) => {
      if (!e.currentTarget.classList.contains("available")) return;
      const item = e.dataTransfer.getData("text/plain");
      if (levelsRef.current[e.currentTarget.id.substring(1)] === item) return;
      e.preventDefault();
      let new_levels = {
        ...levelsRef.current,
        [e.currentTarget.id.substring(1)]: item,
      }
      Object.entries(new_levels).forEach(([key, val]) => {
        if (val === item && key !== e.currentTarget.id.substring(1)) {
          new_levels[key] = null;
        }
      });
      let new_levels_array = Object.values(new_levels).filter((val) => val !== null);
      new_levels = Object.fromEntries(Array.from({ length: Object.keys(new_levels).length }, (_, i) => [i, new_levels_array[i] ?? null]));
      setLevels(new_levels);
    };
    const handleDragOver = (e) => {
      e.preventDefault();
    };
    groupsRefs.current.map((ref) => {
      const dropElement = ref;
      dropElement.addEventListener("drop", handleDrop);
      dropElement.addEventListener("dragover", handleDragOver);
      return () => {
        dropElement.removeEventListener("drop", handleDrop);
        dropElement.removeEventListener("dragover", handleDragOver);
      };
    });
  }, []);

  useEffect(() => {
    levelsRef.current = levels;
  }, [levels]);

  const handleDragStart = (e) => {
    if (disabled) return;
    setDraggingElement(e.target.id);
    e.dataTransfer.setData("text/plain", e.target.id);
  };
  const handleDragEnd = () => {
    setDraggingElement(null);
    setHoveredGroup(null);
  };
  const removeSelectedElement = (group_key) => {
    if (disabled) return;

    // the above code fills the spaces between levels, in case one is the middle is removed
    let adjusted_levels = Array.from({ length: levelsCount() }, (_, i) => i).reduce((acc, key) => {
      acc[key] = null;
      return acc;
    }, {});
    let new_levels = {
      ...levels,
      [group_key]: null,
    };
    let list_of_levels_array = [];
    Object.values(new_levels).forEach((val) => {
      if (val && !list_of_levels_array.includes(val)) {
        adjusted_levels[list_of_levels_array.length] = val;
        list_of_levels_array.push(val);
      }
    });
    setLevels(adjusted_levels);
  };

  const handleDragEnterGroup = (e) => {
    e.preventDefault();
    setHoveredGroup(e.target.id);
  };
  const handleDragLeaveGroup = (e) => {
    e.preventDefault();
    setHoveredGroup(null);
  };

  const getFilledGroupsCount = () => {
    return Object.values(levels).filter((val) => val !== null).length;
  };
  const isElementSelected = (el) => {
    return (
      Object.values(levels).filter((val) => val === el).length > 0
    );
  };

  return (
    <div
      className={cx({
        [classes.canvasRoot]: true,
        [classes.disabled]: disabled,
      })}
      ref={canvasRef}
    >
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ThreeDots
            color={userDetails.implementation_color}
            ariaLabel="loading"
          />
        </div>
      )}
      <div className={cx({ [classes.hidden]: isLoading })}>
        {dimensions.map((el, key) => {
          return (
            <div
              key={key}
              ref={(e) => (elementRefs.current[key] = e)}
              id={el}
              className={cx({
                [classes.elementRoot]: true,
                [classes.drag]: true,
                [classes.dragging]: draggingElement === el,
                [classes.selected]: isElementSelected(el),
              })}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              draggable
            >
              {dimensionNames[el][i18n.language]}
            </div>
          );
        })}
      </div>
      <hr
        className={cx({
          [classes.divider]: true,
          [classes.hidden]: isLoading,
        })}
        ref={dividerRef}
      />
      <div
        className={cx({
          [classes.groupsWrapper]: true,
          [classes.hidden]: isLoading,
        })}
      >
        {groups.current.map((group, key) => {
          return (
            <div
              key={key}
              ref={(e) => (groupsRefs.current[key] = e)}
              id={"g" + key}
              className={cx({
                [classes.groupRoot]: true,
                ["available"]: getFilledGroupsCount() + 1 > key,
                ["dragging"]: draggingElement,
                ["hovered"]: hoveredGroup === "g" + key,
              })}
              onDragEnter={handleDragEnterGroup}
              onDragLeave={handleDragLeaveGroup}
            >
              <span
                className={classes.groupName}
                style={{ pointerEvents: draggingElement ? "none" : "auto" }}
                onDragEnter={(e) => e.stopPropagation()}
              >
                {groupsName}{group}
              </span>
              {levels[key] && (
                <>
                  <span
                    className={cx({
                      [classes.selectedElement]: true,
                      // ["withItem"]: levels[key],
                    })}
                    style={{ pointerEvents: draggingElement ? "none" : "auto" }}
                  >
                    {dimensionNames[levels[key]][i18n.language]}
                  </span>
                  <Close
                    className={classes.removeSelectionButton}
                    style={{pointerEvents: draggingElement ? "none" : "auto" }}
                    onClick={() => removeSelectedElement(key)}
                  />
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

DraggableCanvas.defaultProps = {};

DraggableCanvas.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  dimensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  dimensionNames: PropTypes.object.isRequired,
  groupsName: PropTypes.string.isRequired,
  levels: PropTypes.object.isRequired,
  setLevels: PropTypes.func.isRequired,
  disable: PropTypes.bool,
};

export default DraggableCanvas;
