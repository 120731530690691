import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import AuthContext from "../../contexts/AuthProvider";
import { client, fileDownloadClient } from "../../services/axiosClient";
import "moment/locale/es";
import "moment/locale/pt";
import cx from "classnames";
import { Slide as ToastSlide, toast } from "react-toastify";
import { useImmer } from "use-immer";
import { Translation, useTranslation } from "react-i18next";
import PerfectScrollbar from "perfect-scrollbar";
import Datetime from "react-datetime";
import moment, { isMoment } from "moment/moment";
import { ThreeDots } from "react-loader-spinner";
import ChartistGraph from "react-chartist";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Drawer from "@material-ui/core/Drawer";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import {
  AddCircle,
  Cancel,
  Check,
  CheckCircle,
  Clear,
  Close,
  DirectionsWalk,
  FilterList,
  GetApp,
  Help,
  HighlightOff,
  MenuOpen,
  NewReleases,
  OpenInBrowser,
  Pageview,
  PanTool,
  PostAdd,
  Save,
  Refresh,
} from "@material-ui/icons";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Timeline from "components/Timeline/Timeline";
import ButtonDropdown from "components/ButtonDropdown/ButtonDropdown";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import FilterTag from "components/FilterTag/FilterTag";
import FilterDropdown from "components/FilterDropdown/FilterDropdown";
import { linePercentageChart } from "../../variables/charts";

import styles from "../../assets/jss/material-dashboard-pro-react/views/initiativesStyle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import fileDownload from "js-file-download";
import { grayColor } from "../../assets/jss/material-dashboard-pro-react";

const benefitDecimals = 2;

const stripText = (text) => {
  return text.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
};

export default function Initiatives() {
  let { userDetails } = useContext(AuthContext);
  const client_styles = (theme) => ({
    ...styles(theme, userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [screenBusy, setScreenBusy] = useState(true);
  const [initiativesBusy, setInitiativesBusy] = useState(false);
  const [additionalDetailsModalOpen, setAdditionalDetailsModalOpen] =
    useState(false);
  const initLongDescriptionRef = useRef(null);
  const addUpdateTextRef = useRef(null);
  const [addUpdateModalOpen, setAddUpdateModalOpen] = useState(false);
  const [addUpdateEventType, setAddUpdateEventType] = useState("");
  const [addUpdateTrafficLight, setAddUpdateTrafficLight] = useState("");
  const addUpdateTrafficLightChoices = useRef(["1", "2", "3"]);
  const [
    addUpdateTrafficLightChoicesOpen,
    setAddUpdateTrafficLightChoicesOpen,
  ] = useState(false);
  const [addUpdateText, setAddUpdateText] = useState("");
  const [initiatives, setInitiatives] = useImmer({});
  const [initiativesSortedBy, setInitiativesSortedBy] =
    useState("benefit_global");
  const [initiativesFilters, setInitiativesFilters] = useImmer({
    active: true,
    on_hold: false,
    cancelled: false,
    finished: false,
    status1: true,
    status2: true,
    status3: true,
    updated: true,
    outdated: true,
  });
  const [selectedInitiative, setSelectedInitiative] = useState(null);
  const [initShortDescription, setInitShortDescription] = useState("");
  const [initShortDescriptionPrinted, setInitShortDescriptionPrinted] =
    useState(null);
  const [initLeader, setInitLeader] = useState("");
  const [initBenefit, setInitBenefit] = useState("0.0");
  const [initBenefitGlobal, setInitBenefitGlobal] = useState("0.0");
  const [initScopeMarginRatio, setInitScopeMarginRatio] = useState("1.0");
  const [initBenefitStart, setInitBenefitStart] = useState(null);
  const [initBenefitEnd, setInitBenefitEnd] = useState(null);
  const [initLongDescription, setInitLongDescription] = useState("");
  const [initShortDescriptionStatus, setInitShortDescriptionStatus] =
    useState(false);
  const [initLeaderStatus, setInitLeaderStatus] = useState(false);
  const [initBenefitStatus, setInitBenefitStatus] = useState(false);
  const [initBenefitStartStatus, setInitBenefitStartStatus] = useImmer(false);
  const [initBenefitEndStatus, setInitBenefitEndStatus] = useImmer(false);
  const shortDescriptionInput = useRef(null);
  const initiativesWrapper = useRef(null);
  const followUpWrapper = useRef(null);
  const [initScope, setInitScope] = useState([]);
  const [scopeChanged, setScopeChanged] = useState(false);
  const [metaLoaded, setMetaLoaded] = useState(false);
  const [commonFilters, setCommonFilters] = useImmer({});
  const [commonFiltersSequence, setCommonFiltersSequence] = useState([]);
  const [commonFiltersMode, setCommonFiltersMode] = useImmer({});
  const [commonFiltersIndexes, setCommonFiltersIndexes] = useImmer({});
  const [filterModalCurrentFilter, setFilterModalCurrentFilter] = useState("");
  const [filterModalFilterName, setFilterModalFilterName] = useState("");
  const [filterModalItemsChoices, setFilterModalItemsChoices] = useState([]);
  const [filterModalFilterMode, setFilterModalFilterMode] = useState("");
  const [filterModalItemsSelected, setFilterModalItemsSelected] = useState([]);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const [monthlyServeMarginChart, setMonthlyServeMarginChart] = useState({
    ...linePercentageChart,
    data: {
      labels: [],
      series: [[]],
    },
  });

  const [drawerOpen, setDrawerOpen] = useState(false);

  const filtersNames = useRef({});
  const filtersItems = useRef({});
  const initialFiltersObjects = useRef({});
  const initialFiltersStrings = useRef({});
  const filtersList = useRef([]);

  const notify = (text, type = "info", time = 3000) =>
    toast(t(text), {
      position: "top-right",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
      type: type,
      transition: ToastSlide,
    });

  const notifyDownload = () =>
    toast(t("tasks.download_will_start_shortly"), {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      pauseOnFocusLoss: false,
      theme: "light",
      type: "info",
      transition: ToastSlide,
    });

  const translate_months = (months_array) => {
    let months = [];
    for (let month of months_array) {
      months.push(t("months." + month));
    }
    return months;
  };

  useEffect(() => {
    i18n.changeLanguage(i18n.language.slice(0, 2));
    client
      .post("api/action_plans_initiatives/")
      .then((response) => {
        const filters = response.data.filters;
        filtersList.current = filters.list;
        filtersNames.current = filters.names;
        filtersItems.current = filters.items;
        let initialCommonFiltersObjects = {};
        let initialCommonFiltersStrings = {};
        Object.keys(filters.names).forEach((name) => {
          initialCommonFiltersObjects[name] = [];
          initialCommonFiltersStrings[name] = "";
        });
        initialFiltersObjects.current = initialCommonFiltersObjects;
        initialFiltersStrings.current = initialCommonFiltersStrings;

        setCommonFilters((draft) => {
          Object.entries(initialCommonFiltersObjects).forEach(
            ([key, val]) => (draft[key] = val),
          );
        });
        setCommonFiltersMode((draft) => {
          Object.entries(initialCommonFiltersStrings).forEach(
            ([key, val]) => (draft[key] = val),
          );
        });
        setCommonFiltersIndexes((draft) => {
          Object.entries(initialCommonFiltersObjects).forEach(
            ([key, val]) => (draft[key] = val),
          );
        });
        setMetaLoaded(true);
        let init = {
          ...response.data.initiatives,
          ["new"]: {
            short_description: "",
            leader: "",
            margin_benefit: 0,
            margin_benefit_global: 0,
            margin_benefit_ratio: 1,
            margin_benefit_start: null,
            margin_benefit_end: null,
            long_description: "",
            status: "new",
            scope: [
              {
                filters: {},
                filters_sequence: [],
              },
            ],
            follow_up: [
              {
                user_name: userDetails.username,
                description: "",
                traffic_light: 0,
                event_type: "creation",
                date: moment().startOf("day").format("YYYY-MM-DD HH:mm"),
              },
            ],
            serve_margin_chart: {
              months: [],
              serve_margin: [],
            },
          },
        };
        Object.keys(init).forEach((id) => (init[id].id = id));
        setInitiatives(init);
        setScreenBusy(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let ps1Instance = null;

    if (initiativesWrapper.current && navigator.platform.indexOf("Win") > -1) {
      ps1Instance = new PerfectScrollbar(initiativesWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
        wheelPropagation: false,
        maxScrollbarLength: 100,
        scrollingThreshold: 1000,
      });
    }

    return function cleanup() {
      if (ps1Instance) {
        ps1Instance.destroy();
      }
    };
  }, [initiatives]);

  useEffect(() => {
    let ps2Instance = null;

    if (followUpWrapper.current && navigator.platform.indexOf("Win") > -1) {
      ps2Instance = new PerfectScrollbar(followUpWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
        wheelPropagation: false,
        maxScrollbarLength: 100,
        scrollingThreshold: 1000,
      });
    }

    return function cleanup() {
      if (ps2Instance) {
        ps2Instance.destroy();
      }
    };
  }, [selectedInitiative, drawerOpen]);

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (selectedInitiative) {
      if (initiatives[selectedInitiative].scope !== initScope) {
        setInitScope([
          {
            filters: commonFilters,
            filters_sequence: commonFiltersSequence,
          },
        ]);
        setScopeChanged(true);
      }
      if (scopeChanged) {
        updateInitData();
      }
    }
  }, [commonFilters, commonFiltersSequence]);

  useEffect(() => {
    let benefitGlobal = (initBenefit * initScopeMarginRatio) / 100;
    setInitBenefitGlobal(benefitGlobal.toFixed(benefitDecimals));
  }, [initBenefit, initScopeMarginRatio]);

  const updateInitData = useCallback(() => {
    if (initiativesBusy) return;
    setInitiativesBusy(true);

    client
      .post("api/initiatives_update_scope_ratio/", {
        margin_benefit: parseFloat(initBenefit) / 100,
        filters: commonFilters,
        filters_sequence: commonFiltersSequence,
      })
      .then((response) => {
        if (response.data?.margin_benefit_ratio != null) {
          const newRatio = (100 * response.data.margin_benefit_ratio).toFixed(
            benefitDecimals,
          );
          if (newRatio !== initScopeMarginRatio) {
            setInitScopeMarginRatio(newRatio);
          }
        }
        let graphMonths = response.data.serve_margin_chart.months;
        let translatedMonths = translate_months(graphMonths);
        setMonthlyServeMarginChart({
          ...monthlyServeMarginChart,
          data: {
            labels: translatedMonths,
            series: [response.data.serve_margin_chart.serve_margin],
          },
        });
        setInitiativesBusy(false);
      })
      .catch(() => {
        setInitiativesBusy(false);
      });
  }, [initBenefit, commonFilters, commonFiltersSequence, initiativesBusy]);

  const handleInitiativeClick = (id) => {
    if (id === selectedInitiative) return false;
    setSelectedInitiative(id);
    setInitShortDescription(initiatives[id].short_description);
    setInitLeader(initiatives[id].leader);
    setInitBenefit(
      (100 * initiatives[id].margin_benefit).toFixed(benefitDecimals),
    );
    setInitBenefitGlobal(
      (100 * initiatives[id].margin_benefit_global).toFixed(benefitDecimals),
    );
    setInitScopeMarginRatio(
      (100 * initiatives[id].margin_benefit_ratio).toFixed(benefitDecimals),
    );
    setInitBenefitStart(
      initiatives[id].margin_benefit_start
        ? moment(initiatives[id].margin_benefit_start, "YYYY-MM")
        : null,
    );
    setInitBenefitEnd(
      initiatives[id].margin_benefit_end
        ? moment(initiatives[id].margin_benefit_end, "YYYY-MM")
        : null,
    );
    setInitLongDescription(initiatives[id].long_description);
    setInitShortDescriptionPrinted(initiatives[id].short_description);
    let scope = initiatives[id].scope[0];
    setCommonFilters((draft) => {
      Object.entries(scope.filters).forEach(([key, val]) => {
        draft[key] = val;
      });
    });
    setCommonFiltersSequence([...scope.filters_sequence]);
    setCommonFiltersIndexes((draft) => {
      Object.entries(scope.filters).forEach(([key, val]) => {
        draft[key] = getIndexes(filtersItems.current[key], val);
      });
    });
    setCommonFiltersMode((draft) => {
      Object.keys(scope.filters).forEach((key) => {
        draft[key] = "items";
      });
    });
    let graphMonths = initiatives[id].serve_margin_chart.months;
    let translatedMonths = translate_months(graphMonths);
    setMonthlyServeMarginChart({
      ...monthlyServeMarginChart,
      data: {
        labels: translatedMonths,
        series: [initiatives[id].serve_margin_chart.serve_margin],
      },
    });
    setInitShortDescriptionStatus(id !== "new");
    setInitLeaderStatus(id !== "new");
    setInitBenefitStatus(id !== "new");
    setInitBenefitStartStatus(id !== "new");
    setInitBenefitEndStatus(id !== "new");
  };

  const getDateFromNow = (date) => {
    if (!date) return null;
    return moment(date, "YYYY-MM-DD HH:mm").fromNow();
  };

  const getInitiativeStory = () => {
    if (!selectedInitiative || selectedInitiative === "new") return [];
    moment.locale(i18n.language);
    return initiatives[selectedInitiative].follow_up.map((fup) => {
      let fup_color =
        fup.event_type === "creation" || fup.event_type === "finishing"
          ? "client"
          : fup.traffic_light === 1
            ? "success"
            : fup.traffic_light === 2
              ? "warning"
              : "danger";

      return {
        inverted: true,
        badgeColor: fup_color,
        badgeIcon:
          fup.event_type === "creation"
            ? OpenInBrowser
            : fup.event_type === "on_hold"
              ? PanTool
              : fup.event_type === "update"
                ? DirectionsWalk
                : fup.event_type === "milestone"
                  ? NewReleases
                  : fup.event_type === "cancelling"
                    ? HighlightOff
                    : fup.event_type === "finishing"
                      ? CheckCircle
                      : Help,
        title: t("initiatives.follow_up." + fup.event_type),
        titleColor: fup_color,
        body: <p>{fup.description}</p>,
        footerTitle: (
          <>
            <span className={classes.fupUserName}>{fup.user_name}</span>
            {" — "}
            <span className={classes.fupDaysAgo}>
              {getDateFromNow(fup.date)}
            </span>
          </>
        ),
      };
    });
  };

  // Initiatives summary functions
  const getTotalBenefit = useCallback(() => {
    return Object.values(initiatives)
      .filter((init) => init.status === "active")
      .reduce((total, init) => total + init.margin_benefit_global, 0.0);
  }, [initiatives]);
  const getInitiativesCount = () => {
    let init_count = 0;
    for (const init of Object.values(initiatives)) {
      if (init.status === "active") init_count += 1;
    }
    return init_count;
  };
  const getInitiativesInAttentionCount = () => {
    let init_count = 0;
    for (const init of Object.values(initiatives)) {
      if (init.follow_up[0].traffic_light > 1) init_count += 1;
    }
    return init_count;
  };
  const getOutdatedInitiativesCount = () => {
    let init_count = 0;
    for (const init of Object.values(initiatives)) {
      if (init.status === "active") {
        let last_event = moment(init.follow_up[0].date, "YYYY-MM-DD HH:mm");
        let today = moment().startOf("day");
        if (moment.duration(today.diff(last_event)).asDays() > 28)
          init_count += 1;
      }
    }
    return init_count;
  };
  const handleDownloadButton = () => {
    notifyDownload();
    fileDownloadClient
      .post("api/initiatives_download_initiatives/")
      .then((response) => {
        fileDownload(
          response.data,
          response.headers["content-disposition"].split("filename=")[1],
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateInputStatus = (input_id, value) => {
    if (input_id === "short_description") {
      setInitShortDescriptionStatus(value !== "");
    } else if (input_id === "leader") {
      setInitLeaderStatus(value !== "");
    } else if (input_id === "benefit") {
      try {
        let val = parseFloat(value);
        setInitBenefitStatus(val > 0);
      } catch (e) {
        setInitBenefitStatus(false);
      }
    } else {
      if (input_id === "benefits_start") {
        if (isMoment(value)) {
          if (isMoment(initBenefitEnd)) {
            if (moment.duration(value.diff(initBenefitEnd)).asDays() <= 0) {
              setInitBenefitStartStatus(true);
              setInitBenefitEndStatus(true);
            } else {
              setInitBenefitStartStatus(false);
              setInitBenefitEndStatus(false);
            }
          } else {
            setInitBenefitStartStatus(true);
          }
        } else {
          setInitBenefitStartStatus(false);
        }
      } else if (input_id === "benefits_end") {
        if (isMoment(value)) {
          if (isMoment(initBenefitStart)) {
            if (moment.duration(value.diff(initBenefitStart)).asDays() >= 0) {
              setInitBenefitStartStatus(true);
              setInitBenefitEndStatus(true);
            } else {
              setInitBenefitStartStatus(false);
              setInitBenefitEndStatus(false);
            }
          } else {
            setInitBenefitEndStatus(true);
          }
        } else {
          setInitBenefitEndStatus(false);
        }
      }
    }
  };
  const handleInputChange = (event, identifier) => {
    if (event.target) {
      if (event.target.id === "short_description") {
        setInitShortDescription(event.target.value);
      } else if (event.target.id === "leader") {
        setInitLeader(event.target.value);
      } else if (event.target.id === "benefit") {
        setInitBenefit(event.target.value);
      }
      updateInputStatus(event.target.id, stripText(event.target.value));
    } else {
      if (identifier === "benefits_start") {
        if (isMoment(event)) {
          setInitBenefitStart(event);
        } else {
          setInitBenefitStart(null);
        }
      } else if (identifier === "benefits_end") {
        if (isMoment(event)) {
          setInitBenefitEnd(event);
        } else {
          setInitBenefitEnd(null);
        }
      }
      updateInputStatus(identifier, event);
    }
  };
  const handleCancelAdditionButton = () => {
    setSelectedInitiative(null);
  };
  const handleAdditionalDetailsButtonClick = () => {
    setAdditionalDetailsModalOpen(true);
    setTimeout(() => {
      initLongDescriptionRef.current.firstChild.focus();
    }, 100);
  };
  const handleAddUpdateButtonClick = () => {
    setAddUpdateEventType("");
    setAddUpdateTrafficLight("");
    setAddUpdateText("");
    setAddUpdateModalOpen(true);
  };
  const handleInitLongDescriptionChange = (event) => {
    setInitLongDescription(event.target.value);
  };
  const handleAddUpdateTextChange = (event) => {
    setAddUpdateText(event.target.value);
  };
  const handleInitLongDescriptionBlur = () => {
    setInitLongDescription((initLongDescription) => initLongDescription.trim());
  };
  const handleAddUpdateTextBlur = () => {
    setAddUpdateText((addUpdateText) => addUpdateText.trim());
  };
  const handleInputBlur = (event) => {
    if (event.target.id === "short_description") {
      setInitShortDescription((initShortDescription) =>
        stripText(initShortDescription),
      );
      if (stripText(initShortDescription) !== "") {
        setInitShortDescriptionPrinted(stripText(initShortDescription));
      } else {
        setInitShortDescriptionPrinted(null);
      }
    } else if (event.target.id === "leader") {
      setInitLeader((initLeader) => stripText(initLeader));
    } else if (event.target.id === "benefit") {
      try {
        let val = parseFloat(initBenefit);
        if (isNaN(val)) {
          setInitBenefit("0.00");
        } else {
          val = Math.min(Math.max(val, 0), 20);
          setInitBenefit(val.toFixed(benefitDecimals));
        }
      } catch (e) {
        setInitBenefit("0.00");
      }
    }
  };
  const handleAddUpdateEventTypeChange = (event) => {
    setAddUpdateEventType(event.target.value);
    if (["on_hold", "cancelling", "finishing"].includes(event.target.value)) {
      addUpdateTrafficLightChoices.current = ["0"];
      setAddUpdateTrafficLight("0");
      setTimeout(() => {
        addUpdateTextRef.current.firstChild.focus();
      }, 100);
    } else {
      addUpdateTrafficLightChoices.current = ["1", "2", "3"];
      if (addUpdateTrafficLight === "0") setAddUpdateTrafficLight("");
      setAddUpdateTrafficLightChoicesOpen(true);
    }
  };
  const handleAddUpdateTrafficLightChange = (event) => {
    setAddUpdateTrafficLight(event.target.value);
    setTimeout(() => {
      addUpdateTextRef.current.firstChild.focus();
    }, 100);
  };
  const inputsStatusOk = () => {
    return (
      initShortDescriptionStatus &&
      initLeaderStatus &&
      initBenefitStatus &&
      initBenefitStartStatus &&
      initBenefitEndStatus
    );
  };
  const isSavePending = () => {
    let init = initiatives[selectedInitiative];
    if (!init) return false;
    try {
      let description = init.short_description !== initShortDescription;
      let leader = init.leader !== initLeader;
      let benefit =
        (100 * init.margin_benefit).toFixed(benefitDecimals) !==
        parseFloat(initBenefit).toFixed(benefitDecimals);
      let start =
        init.margin_benefit_start !== initBenefitStart.format("YYYY-MM");
      let end = init.margin_benefit_end !== initBenefitEnd.format("YYYY-MM");
      let long_description = init.long_description !== initLongDescription;
      let ratio =
        (100 * init.margin_benefit_ratio).toFixed(benefitDecimals) !==
        parseFloat(initScopeMarginRatio).toFixed(benefitDecimals);

      return (
        description ||
        leader ||
        benefit ||
        start ||
        end ||
        long_description ||
        ratio
      );
    } catch {
      return false;
    }
  };
  const commitCurrentInit = (init) => {
    setInitiatives((draft) => {
      draft[init.id] = {
        ...draft[init.id],
        ...init,
      };
    });
  };
  const checkAndSaveInitiative = () => {
    if (!inputsStatusOk()) {
      notify(t("initiatives.save_error_input_errors"), "error", 4000);
      return false;
    }
    if (!isSavePending()) {
      notify(t("initiatives.save_error_no_mods"), "info", 3000);
      return false;
    }
    let init = structuredClone(initiatives[selectedInitiative]);
    setInitiativesBusy(true);
    init = {
      id: init.id,
      short_description: initShortDescription,
      long_description: initLongDescription,
      leader: initLeader,
      margin_benefit: parseFloat(initBenefit) / 100,
      margin_benefit_global: parseFloat(initBenefitGlobal) / 100,
      margin_benefit_ratio: parseFloat(initScopeMarginRatio) / 100,
      margin_benefit_start: initBenefitStart.format("YYYY-MM"),
      margin_benefit_end: initBenefitEnd.format("YYYY-MM"),
      scope: initScope,
    };
    client
      .post("api/initiatives_save_initiative/", init)
      .then((response) => {
        if (response.data.success) {
          if (init.id === "new") {
            init.id = response.data.id;
            init.status = "active";
            init.follow_up = response.data.follow_up;
            commitCurrentInit(init);
            setSelectedInitiative(response.data.id);
          } else {
            commitCurrentInit(init);
          }
        } else {
          notify(t("initiatives.xxxx." + response.data.error), "error", 5000);
        }
        setInitiativesBusy(false);
      })
      .catch((error) => {
        console.log(error);
        setInitiativesBusy(false);
      });
  };
  const getSortedInitiativesList = () => {
    // This function guarantees inactive initiatives to come always at the bottom
    const postSort = (list) => {
      return list.sort(function (a, b) {
        if (a["follow_up"] && b["follow_up"]) {
          let x = Math.min(a["follow_up"][0]["traffic_light"], 1);
          let y = Math.min(b["follow_up"][0]["traffic_light"], 1);
          return x < y ? 1 : x > y ? -1 : 0; // Z>A
        } else {
          return 0;
        }
      });
    };
    if (initiativesSortedBy === "benefit_global") {
      return postSort(
        Object.values(initiatives).sort(function (a, b) {
          let x = a["margin_benefit_global"];
          let y = b["margin_benefit_global"];
          return x < y ? 1 : x > y ? -1 : 0; // Z>A
        }),
      );
    } else if (initiativesSortedBy === "benefit") {
      return postSort(
        Object.values(initiatives).sort(function (a, b) {
          let x = a["margin_benefit"];
          let y = b["margin_benefit"];
          return x < y ? 1 : x > y ? -1 : 0; // Z>A
        }),
      );
    } else if (initiativesSortedBy === "description") {
      return postSort(
        Object.values(initiatives).sort(function (a, b) {
          let x = a["short_description"];
          let y = b["short_description"];
          return x < y ? -1 : x > y ? 1 : 0; // A>Z
        }),
      );
    } else if (initiativesSortedBy === "leader") {
      return postSort(
        Object.values(initiatives).sort(function (a, b) {
          let x = a["leader"];
          let y = b["leader"];
          return x < y ? -1 : x > y ? 1 : 0; // A>Z
        }),
      );
    } else if (initiativesSortedBy === "benefit_start") {
      return postSort(
        Object.values(initiatives).sort(function (a, b) {
          let x = moment(a["margin_benefit_start"], "YYYY-MM");
          let y = moment(b["margin_benefit_start"], "YYYY-MM");
          // moment.duration(today.diff(last_event)).asDays() > 28
          return moment.duration(x.diff(y)).asDays() < 0
            ? -1
            : moment.duration(x.diff(y)).asDays() > 0
              ? 1
              : 0; // A>Z
        }),
      );
    } else if (initiativesSortedBy === "status") {
      const status_dict = {
        active: 1,
        on_hold: 2,
        finished: 3,
        cancelled: 4,
      };
      return postSort(
        Object.values(initiatives).sort(function (a, b) {
          let x = a["follow_up"][0]["traffic_light"];
          let y = b["follow_up"][0]["traffic_light"];
          let x2 = status_dict[a["status"]];
          let y2 = status_dict[b["status"]];
          return x < y ? 1 : x > y ? -1 : x2 < y2 ? -1 : x2 > y2 ? 1 : 0; // Z>A
        }),
      );
    } else if (initiativesSortedBy === "last_update") {
      return postSort(
        Object.values(initiatives).sort(function (a, b) {
          let x = moment(a["follow_up"][0]["date"], "YYYY-MM-DD HH:mm");
          let y = moment(b["follow_up"][0]["date"], "YYYY-MM-DD HH:mm");
          // moment.duration(today.diff(last_event)).asDays() > 28
          return moment.duration(x.diff(y)).asDays() < 0
            ? -1
            : moment.duration(x.diff(y)).asDays() > 0
              ? 1
              : 0; // A>Z
        }),
      );
    }
    return Object.values(initiatives);
  };
  const getSortedFilteredInitiativesList = () => {
    return getSortedInitiativesList().map((init) => {
      let enter = true;
      if (init.status === "new") enter = false;
      if (!initiativesFilters.active && init.status === "active") enter = false;
      if (!initiativesFilters.on_hold && init.status === "on_hold")
        enter = false;
      if (!initiativesFilters.cancelled && init.status === "cancelled")
        enter = false;
      if (!initiativesFilters.finished && init.status === "finished")
        enter = false;
      if (
        init.status === "active" &&
        !initiativesFilters.status1 &&
        init.follow_up[0].traffic_light === 1
      )
        enter = false;
      if (
        init.status === "active" &&
        !initiativesFilters.status2 &&
        init.follow_up[0].traffic_light === 2
      )
        enter = false;
      if (
        init.status === "active" &&
        !initiativesFilters.status3 &&
        init.follow_up[0].traffic_light === 3
      )
        enter = false;
      if (
        !initiativesFilters.updated &&
        moment
          .duration(
            moment()
              .startOf("day")
              .diff(moment(init.follow_up[0].date, "YYYY-MM")),
          )
          .asDays() <= 28
      )
        enter = false;
      if (
        !initiativesFilters.outdated &&
        moment
          .duration(
            moment()
              .startOf("day")
              .diff(moment(init.follow_up[0].date, "YYYY-MM")),
          )
          .asDays() > 28
      )
        enter = false;
      return { ...init, enter: enter };
    });
  };
  const getFilterOptions = () => {
    let options = [];
    options.push({
      identified: "active",
      text: t("initiatives.filter_options.active"),
      active: initiativesFilters.active,
    });
    options.push({
      identified: "on_hold",
      text: t("initiatives.filter_options.on_hold"),
      active: initiativesFilters.on_hold,
    });
    options.push({
      identified: "cancelled",
      text: t("initiatives.filter_options.cancelled"),
      active: initiativesFilters.cancelled,
    });
    options.push({
      identified: "finished",
      text: t("initiatives.filter_options.finished"),
      active: initiativesFilters.finished,
    });
    options.push({ divider: true });
    options.push({
      identified: "status1",
      text: t("initiatives.filter_options.green_flag"),
      active: initiativesFilters.status1,
    });
    options.push({
      identified: "status2",
      text: t("initiatives.filter_options.yellow_flag"),
      active: initiativesFilters.status2,
    });
    options.push({
      identified: "status3",
      text: t("initiatives.filter_options.red_flag"),
      active: initiativesFilters.status3,
    });
    options.push({ divider: true });
    options.push({
      identified: "updated",
      text: t("initiatives.filter_options.updated"),
      active: initiativesFilters.updated,
    });
    options.push({
      identified: "outdated",
      text: t("initiatives.filter_options.outdated"),
      active: initiativesFilters.outdated,
    });
    return options;
  };
  const handleFilterSelection = (id) => {
    setInitiativesFilters((draft) => {
      draft[id] = !draft[id];
    });
  };
  const handleAddInitiativeClick = () => {
    handleInitiativeClick("new");
    setTimeout(() => shortDescriptionInput.current.firstChild.focus(), 100);
  };
  const isValidDate = (current) => {
    return current.isAfter(moment().startOf("month"));
  };
  class DatePickerBenefitDates extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        value: this.props.value,
        onChange: this.props.onChange,
        status: this.props.status,
      };
      this.renderInput = this.renderInput.bind(this);
    }
    render() {
      return (
        <Datetime
          value={this.props.value}
          timeFormat={false}
          closeOnSelect={true}
          dateFormat="YYYY-MM"
          locale={i18n.language}
          renderInput={this.renderInput}
          onChange={this.props.onChange}
          isValidDate={isValidDate}
        />
      );
    }
    renderInput(props) {
      return (
        <div>
          <CustomInput
            id={this.state.inputId}
            formControlProps={{
              fullWidth: true,
              style: { paddingTop: "0", marginTop: "-6px" },
            }}
            inputProps={{
              type: "text",
              autoComplete: "off",
              disabled:
                initiativesBusy ||
                (selectedInitiative !== "new" &&
                  initiatives[selectedInitiative] &&
                  initiatives[selectedInitiative].status !== "active"),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  {this.state.status && (
                    <Check className={classes.inputAdornmentIconSuccess} />
                  )}
                  {!this.state.status && (
                    <Clear className={classes.inputAdornmentIconError} />
                  )}
                </InputAdornment>
              ),
            }}
            client
            timeFormat={false}
            {...props}
          />
        </div>
      );
    }
  }
  DatePickerBenefitDates.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    status: PropTypes.bool,
  };
  const additionalDetailsDialog = (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={additionalDetailsModalOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setAdditionalDetailsModalOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={cx({
            [classes.modalHeader]: true,
          })}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAdditionalDetailsModalOpen(false)}
            disabled={initiativesBusy}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            <span className={classes.modalHeader}>
              {t("initiatives.additional_details")}
            </span>
          </h4>
          <div>
            {t("initiatives.initiative") +
              ": " +
              initiatives[selectedInitiative]?.short_description}
          </div>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer alignItems={"center"}>
            <GridItem xs={12} style={{ marginTop: "-36px" }}>
              <CustomInput
                labelText={t("initiatives.details_modal.detailed_description")}
                id="detailed_description"
                value={initLongDescription}
                onChange={handleInitLongDescriptionChange}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  // type: "text",
                  autoComplete: "off",
                  disabled:
                    initiativesBusy ||
                    (initiatives[selectedInitiative] &&
                      initiatives[selectedInitiative].status !== "active"),
                }}
                client
                multiline
                ref={initLongDescriptionRef}
                onBlur={handleInitLongDescriptionBlur}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            <GridItem>
              <GridContainer alignItems={"center"} style={{ height: "100%" }}>
                <GridItem>
                  <ThreeDots
                    visible={initiativesBusy}
                    color={userDetails.implementation_color}
                    ariaLabel="loading"
                    height={"12px"}
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={true}></GridItem>
            <GridItem>
              <Button
                onClick={() => setAdditionalDetailsModalOpen(false)}
                color="info"
                simple
                disabled={initiativesBusy}
              >
                {t("initiatives.details_modal.close")}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </Dialog>
    </>
  );
  const addUpdateEventTypeOptions = () => {
    return ["update", "milestone", "on_hold", "cancelling", "finishing"].map(
      (item, key) => (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={item}
          key={key}
        >
          {t("initiatives.event_type." + item)}
        </MenuItem>
      ),
    );
  };
  const addUpdateTrafficLightOptions = () => {
    return addUpdateTrafficLightChoices.current.map((item, key) => (
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        value={item}
        key={key}
      >
        {t("initiatives.traffic_light." + item)}
      </MenuItem>
    ));
  };
  const isAddUpdateReady = () => {
    return (
      (["on_hold", "cancelling", "finishing"].includes(addUpdateEventType) &&
        addUpdateTrafficLight === "0" &&
        addUpdateText.trim() !== "") ||
      (["update", "milestone"].includes(addUpdateEventType) &&
        ["1", "2", "3"].includes(addUpdateTrafficLight) &&
        addUpdateText.trim() !== "")
    );
  };
  const commitAddUpdate = (id, update) => {
    let new_status;
    if (update.event_type === "on_hold") {
      new_status = "on_hold";
    } else if (update.event_type === "cancelling") {
      new_status = "cancelled";
    } else if (update.event_type === "finishing") {
      new_status = "finished";
    } else {
      new_status = "active";
    }
    setInitiatives((draft) => {
      draft[id].status = new_status;
      draft[id].follow_up = [update, ...draft[id].follow_up];
    });
  };
  const saveAddUpdate = () => {
    setInitiativesBusy(true);
    let init_id = selectedInitiative;
    let update = {
      initiative_id: init_id,
      event_type: addUpdateEventType,
      traffic_light: parseInt(addUpdateTrafficLight),
      description: addUpdateText,
    };
    client
      .post("api/initiatives_add_update/", update)
      .then((response) => {
        if (response.data.success) {
          update = {
            ...update,
            id: response.data.id,
            user_name: response.data.user_name,
            date: response.data.date,
          };
          commitAddUpdate(init_id, update);
          setAddUpdateModalOpen(false);
        } else {
          notify(t("initiatives.errors.save_add_update"), "error", 5000);
        }
        setInitiativesBusy(false);
      })
      .catch((error) => {
        console.log(error);
        notify(t("initiatives.errors.save_add_update"), "error", 5000);
        setInitiativesBusy(false);
      });
  };
  const AddUpdateDialog = (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={addUpdateModalOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => setAddUpdateModalOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={cx({
            [classes.modalHeader]: true,
          })}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setAddUpdateModalOpen(false)}
            disabled={initiativesBusy}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            <span className={classes.modalHeader}>
              {t("initiatives.add_update")}
            </span>
          </h4>
          <div>
            {t("initiatives.initiative") +
              ": " +
              initiatives[selectedInitiative]?.short_description}
          </div>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer alignItems={"center"}>
            <GridItem xs={6} style={{ marginTop: "-16px" }}>
              <div style={{ paddingLeft: "2px" }}>
                <FormControl
                  fullWidth
                  className={
                    classes.selectFormControl + " " + classes.marginBottomSmall
                  }
                >
                  <InputLabel
                    htmlFor="event-type"
                    className={classes.selectLabel}
                    disabled={initiativesBusy}
                  >
                    {t("initiatives.add_update_modal.event_type")}
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={addUpdateEventType}
                    onChange={handleAddUpdateEventTypeChange}
                    inputProps={{
                      name: "eventType",
                      id: "event-type",
                    }}
                    disabled={initiativesBusy}
                  >
                    {addUpdateEventTypeOptions()}
                  </Select>
                </FormControl>
              </div>
            </GridItem>
            <GridItem xs={6} style={{ marginTop: "-16px" }}>
              <div style={{ paddingRight: "2px" }}>
                <FormControl
                  fullWidth
                  className={
                    classes.selectFormControl + " " + classes.marginBottomSmall
                  }
                >
                  <InputLabel
                    htmlFor="traffic-light"
                    className={classes.selectLabel}
                    shrink={addUpdateTrafficLight !== ""}
                    disabled={
                      addUpdateEventType === "" ||
                      addUpdateTrafficLight === "0" ||
                      initiativesBusy
                    }
                  >
                    {t("initiatives.add_update_modal.traffic_light")}
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={addUpdateTrafficLight}
                    onChange={handleAddUpdateTrafficLightChange}
                    inputProps={{
                      name: "trafficLight",
                      id: "traffic-light",
                    }}
                    disabled={
                      addUpdateEventType === "" ||
                      addUpdateTrafficLight === "0" ||
                      initiativesBusy
                    }
                    open={addUpdateTrafficLightChoicesOpen}
                    onOpen={() => setAddUpdateTrafficLightChoicesOpen(true)}
                    onClose={() => setAddUpdateTrafficLightChoicesOpen(false)}
                  >
                    {addUpdateTrafficLightOptions()}
                  </Select>
                </FormControl>
              </div>
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText={t("initiatives.add_update_modal.update_description")}
                id="add_update_description"
                ref={addUpdateTextRef}
                value={addUpdateText}
                onChange={handleAddUpdateTextChange}
                formControlProps={{
                  fullWidth: true,
                  style: { paddingTop: "0", marginBottom: "0" },
                }}
                inputProps={{
                  autoComplete: "off",
                  disabled: initiativesBusy,
                  maxLength: 1000,
                }}
                client
                multiline
                onBlur={handleAddUpdateTextBlur}
                disabled={initiativesBusy}
              />
            </GridItem>
            {addUpdateEventType === "on_hold" && (
              <GridItem xs={12} style={{ marginTop: "20px" }}>
                <SnackbarContent
                  message={t("initiatives.add_update_modal.on_hold_warning")}
                  color="warning"
                />
              </GridItem>
            )}
            {addUpdateEventType === "cancelling" && (
              <GridItem xs={12} style={{ marginTop: "20px" }}>
                <SnackbarContent
                  message={t("initiatives.add_update_modal.cancel_warning")}
                  color="warning"
                />
              </GridItem>
            )}
            {addUpdateEventType === "finishing" && (
              <>
                <GridItem xs={12} style={{ marginTop: "20px" }}>
                  <SnackbarContent
                    message={t("initiatives.add_update_modal.finish_warning1")}
                    color="warning"
                  />
                  <SnackbarContent
                    message={t("initiatives.add_update_modal.finish_warning2")}
                    color="info"
                  />
                </GridItem>
              </>
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            <GridItem>
              <GridContainer alignItems={"center"} style={{ height: "100%" }}>
                <GridItem>
                  <ThreeDots
                    visible={initiativesBusy}
                    color={userDetails.implementation_color}
                    ariaLabel="loading"
                    height={"12px"}
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={true}></GridItem>
            <GridItem>
              <Button
                onClick={saveAddUpdate}
                color="info"
                simple
                disabled={!isAddUpdateReady()}
              >
                {t("initiatives.add_update_modal.confirm")}
              </Button>
              <Button
                onClick={() => setAddUpdateModalOpen(false)}
                color="danger"
                simple
                disabled={initiativesBusy}
              >
                {t("initiatives.add_update_modal.cancel")}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </Dialog>
    </>
  );

  const filtersListTranslated = () => {
    return filtersList.current.map((filter) => {
      if (Object.keys(filtersNames.current).includes(filter)) {
        return filtersNames.current[filter][i18n.language];
      } else {
        return filter;
      }
    });
  };
  const filtersActiveList = () => {
    let activeList = [];
    Object.keys(filtersNames.current).map((filter, key) => {
      if (commonFilters[filter].length > 0) activeList.push(key);
    });
    return activeList;
  };
  const getIndexes = (filters, values) => {
    return values.map((item) => {
      return filters.indexOf(item);
    });
  };
  const filterMenuClickHandler = () => (event) => {
    const selectedFilter =
      typeof event === "string" ? event : filtersList.current[event];
    setFilterModalCurrentFilter(selectedFilter);
    setFilterModalFilterName(
      filtersNames.current[selectedFilter][i18n.language],
    );
    let choicesList = filtersItems.current[selectedFilter];
    setFilterModalItemsChoices(choicesList);
    setFilterModalFilterMode(commonFiltersMode[selectedFilter]);
    if (commonFiltersMode[selectedFilter] === "items") {
      setFilterModalItemsSelected(commonFiltersIndexes[selectedFilter]);
    } else {
      setFilterModalItemsSelected([]);
    }
    setFilterModalOpen(true);
  };
  const filtersSequence = () => {
    return filtersList.current.map((filter) => {
      return commonFiltersSequence.indexOf(filter) === -1
        ? ""
        : commonFiltersSequence.indexOf(filter) > 9
          ? "N"
          : (commonFiltersSequence.indexOf(filter) + 1).toString();
    });
  };
  const getFilterItems = (raw_items, mode) => {
    if (mode === "items") {
      return raw_items.map((month) => {
        return (
          t("months." + month[1].toString()) +
          "-" +
          month[0].toString().slice(-2)
        );
      });
    } else {
      return raw_items;
    }
  };

  const removeCommonFilter = (filter, item, index, all = false) => {
    let new_filter_items;
    if (commonFiltersMode[filter] === "items") {
      let item = filtersItems.current[filter][index];
      new_filter_items = all
        ? []
        : commonFilters[filter].filter(
            (items) => JSON.stringify(items) !== JSON.stringify(item),
          );
    } else {
      new_filter_items = all
        ? []
        : commonFilters[filter].filter((items) => items !== item);
    }
    setCommonFilters((draft) => {
      draft[filter] = new_filter_items;
    });
    let common_filters_sequence = commonFiltersSequence;
    if (new_filter_items.length === 0) {
      setCommonFiltersIndexes((draft) => {
        draft[filter] = [];
      });
      setCommonFiltersMode((draft) => {
        draft[filter] = "";
      });
      common_filters_sequence = commonFiltersSequence.filter(
        (f) => f !== filter,
      );
      setCommonFiltersSequence(common_filters_sequence);
    } else {
      setCommonFiltersIndexes((draft) => {
        draft[filter] = commonFiltersIndexes[filter].filter(
          (indexes) => indexes !== index,
        );
      });
    }
  };
  const getFiltersNamesInSequence = () => {
    return commonFiltersSequence.filter((f) =>
      Object.keys(filtersNames.current).includes(f),
    );
  };
  const currentScopeFilters = () => {
    return getFiltersNamesInSequence().map((filter, key) => {
      return (
        <FilterTag
          key={key}
          filtersList={filtersList.current}
          filtersNames={filtersNames.current}
          filter={filter}
          items={getFilterItems(
            commonFilters[filter],
            filter,
            commonFiltersMode[filter],
          )}
          indexes={commonFiltersIndexes[filter]}
          mode={commonFiltersMode[filter]}
          nameClickHandler={filterMenuClickHandler()}
          removeItemHandler={removeCommonFilter}
          color={"gray"}
        />
      );
    });
  };
  const filterModalItemsMenuItems = () => {
    let root_class_base = classes.selectMenuItem;
    let selected_class_base = classes.selectMenuItemSelected;
    let root_class, selected_class;
    let menu_items = [];
    filterModalItemsChoices.map((item, key) => {
      let display_item = item === "" ? "* " + t("results.blank") + " *" : item;
      // let item_meta = filtersItems.current[filterModalCurrentFilter][key];
      let available = true;
      root_class = root_class_base + (available ? "" : " unavailable");
      selected_class = selected_class_base + (available ? "" : " unavailable");
      menu_items.push(
        <MenuItem
          classes={{
            root: root_class,
            selected: selected_class,
          }}
          value={key}
          key={key}
        >
          {display_item}
        </MenuItem>,
      );
    });
    return menu_items;
  };
  const handleFilterModalItemsMenuSelect = (event) => {
    setFilterModalFilterMode(event.target.value.length > 0 ? "items" : "");
    setFilterModalItemsSelected(event.target.value);
  };
  const handleFilterModalItemsSelectAll = () => {
    setFilterModalFilterMode("items");
    setFilterModalItemsSelected(
      filterModalItemsChoices.map((_, key) => {
        return key;
      }),
    );
  };
  const handleFilterModalItemsSelectNone = () => {
    setFilterModalItemsSelected([]);
    if (filterModalFilterMode === "items") setFilterModalFilterMode("");
  };
  const handleFilterModalItemsInvertSelection = () => {
    let newSelection = [];
    filterModalItemsChoices.map((_, key) => {
      if (!filterModalItemsSelected.includes(key)) newSelection.push(key);
    });
    if (newSelection.length > 0) {
      setFilterModalFilterMode("items");
      setFilterModalItemsSelected(newSelection);
    } else {
      setFilterModalItemsSelected([]);
      if (filterModalFilterMode === "items") setFilterModalFilterMode("");
    }
  };
  const handleFilterModalClearFilterButton = () => {
    // let common_filters = {
    //   ...commonFilters,
    //   [filterModalCurrentFilter]: [],
    // };
    setCommonFilters((draft) => {
      draft[filterModalCurrentFilter] = [];
    });
    setCommonFiltersIndexes((draft) => {
      draft[filterModalCurrentFilter] = [];
    });
    // let common_filters_mode = {
    //   ...commonFiltersMode,
    //   [filterModalCurrentFilter]: "",
    // };
    setCommonFiltersMode((draft) => {
      draft[filterModalCurrentFilter] = "";
    });
    let common_filters_sequence = commonFiltersSequence.filter(
      (f) => f !== filterModalCurrentFilter,
    );
    setCommonFiltersSequence(common_filters_sequence);
    setFilterModalOpen(false);
  };

  const handleFilterModalApplyButton = () => {
    if (filterModalFilterMode === "") {
      notify("results.toasts.you_must_select_at_least_one_item");
      return;
    } else if (filterModalFilterMode === "items") {
      setCommonFiltersIndexes((draft) => {
        draft[filterModalCurrentFilter] = filterModalItemsSelected;
      });
      setCommonFilters((draft) => {
        draft[filterModalCurrentFilter] = filterModalItemsSelected.map(
          (index) => filtersItems.current[filterModalCurrentFilter][index],
        );
      });
      setCommonFiltersMode((draft) => {
        draft[filterModalCurrentFilter] = "items";
      });
      let common_filters_sequence = commonFiltersSequence;
      if (!commonFiltersSequence.includes(filterModalCurrentFilter)) {
        common_filters_sequence.push(filterModalCurrentFilter);
        setCommonFiltersSequence(common_filters_sequence);
      }
    }
    setFilterModalOpen(false);
  };

  const filtersDialog = (
    <>
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={filterModalOpen}
        keepMounted
        onClose={() => setFilterModalOpen(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="content-modal-slide-title"
          disableTypography
          className={cx({
            [classes.modalHeader]: true,
            [classes.center]: true,
          })}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setFilterModalOpen(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            <span className={classes.filterModalHeaderScope}>
              {t("initiatives.scope_filters")}
            </span>
            {" - "}
            <span className={classes.filterModalHeaderFilterName}>
              {filterModalFilterName}
            </span>
          </h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer alignItems={"center"}>
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={12}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.marginBottomSmall
                    }
                  >
                    <InputLabel
                      htmlFor="filter-items"
                      className={classes.selectLabel}
                    >
                      {t("results.filter_modal.select_items")}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={filterModalItemsSelected}
                      onChange={handleFilterModalItemsMenuSelect}
                      inputProps={{
                        name: "filterItems",
                        id: "filter-items",
                      }}
                      multiple
                    >
                      {filterModalItemsMenuItems()}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12}>
                  <GridContainer>
                    <GridItem className={classes.filterModalActionContainer}>
                      <div
                        className={classes.filterModalAction}
                        onClick={handleFilterModalItemsSelectAll}
                      >
                        {t("results.filter_modal.select_all")}
                      </div>
                    </GridItem>
                    <GridItem className={classes.filterModalActionContainer}>
                      <div
                        className={classes.filterModalAction}
                        onClick={handleFilterModalItemsSelectNone}
                      >
                        {t("results.filter_modal.select_none")}
                      </div>
                    </GridItem>
                    <GridItem className={classes.filterModalActionContainer}>
                      <div
                        className={classes.filterModalAction}
                        onClick={handleFilterModalItemsInvertSelection}
                      >
                        {t("results.filter_modal.invert_selection")}
                      </div>
                    </GridItem>
                    <GridItem xs={true}>
                      <div className={classes.filterModalCounter}>
                        {filterModalItemsSelected.length.toString() +
                          " " +
                          t("results.filter_modal.items_selected")}
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            {
              <GridItem>
                <Button
                  onClick={handleFilterModalClearFilterButton}
                  color="info"
                  simple
                >
                  {t("results.filter_modal.clear_filter")}
                </Button>
              </GridItem>
            }
            <GridItem xs={true}></GridItem>
            <GridItem>
              <Button
                onClick={handleFilterModalApplyButton}
                color="info"
                simple
              >
                {t("results.filter_modal.apply")}
              </Button>
              <Button
                onClick={() => setFilterModalOpen(false)}
                color="danger"
                simple
              >
                {t("results.filter_modal.close")}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </Dialog>
    </>
  );

  const storyDrawer = (
    <Drawer
      anchor={"right"}
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      className={classes.drawer}
      style={{ zIndex: 1200 }}
      BackdropProps={{
        style: { backgroundColor: "#000", opacity: "0.1", zIndex: 1199 },
      }}
      PaperProps={{
        style: {
          borderRadius: "10px",
          margin: "1vh 1vh 1vh 0",
          height: "98vh",
          width: "50vw",
          zIndex: 1200,
        },
      }}
    >
      <DialogTitle
        id="story-modal-title"
        disableTypography
        className={cx({
          [classes.drawerHeader]: true,
        })}
      >
        <GridContainer alignItems={"center"}>
          <GridItem xs>
            <h4 className={classes.drawerTitle}>{t("initiatives.history")}</h4>
            <p className={classes.drawerSubtitle}>
              {selectedInitiative === "new"
                ? t("initiatives.insert_data_new_initiative")
                : initShortDescriptionPrinted
                  ? t("initiatives.initiative") +
                    ": " +
                    initShortDescriptionPrinted
                  : t("initiatives.insert_description")}
            </p>
          </GridItem>
          <GridItem xs="auto">
            <Tooltip
              placement={"top"}
              title={t("initiatives.add_update")}
              PopperProps={{
                style: { marginBottom: "-8px" },
              }}
            >
              <Button
                justIcon
                color="client"
                className={classes.cardButton}
                onClick={handleAddUpdateButtonClick}
                disabled={
                  screenBusy ||
                  initiativesBusy ||
                  selectedInitiative === "new" ||
                  (initiatives[selectedInitiative] &&
                    (initiatives[selectedInitiative].status === "cancelled" ||
                      initiatives[selectedInitiative].status === "finished"))
                }
              >
                <div style={{ width: "100%" }}>
                  <PostAdd className={classes.cardButtonIcon} />
                </div>
              </Button>
            </Tooltip>
          </GridItem>
        </GridContainer>
      </DialogTitle>

      <div className={classes.drawerContentWrapper}>
        <div className={classes.drawerContent}>
          <div className={classes.stepsContainer}>
            <GridContainer>
              <GridItem xs={12}>
                <div
                  className={cx({
                    [classes.fullHeight]: true,
                    [classes.invisible]: selectedInitiative === "new",
                  })}
                >
                  <Card plain style={{ marginTop: "0", height: "100%" }}>
                    <CardBody plain className={classes.timeline}>
                      <div
                        className={classes.followUpWrapper}
                        ref={followUpWrapper}
                        style={{
                          position: "relative",
                          maxHeight: "calc(98vh - 100px)",
                          overflowY: "auto",
                          padding: "0 15px",
                        }}
                      >
                        <Timeline simple stories={getInitiativeStory()} />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Drawer>
  );

  return (
    <div>
      <GridContainer style={{ marginTop: "-28px" }}>
        <GridItem xs={12} md={6}>
          <div className={classes.cardsFullHeight}>
            <div className={classes.cardsWrapper}>
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <Card className={classes.card}>
                    <CardHeader color="client" stats icon>
                      <CardIcon color="client" className={classes.cardIcon}>
                        <Icon>receipt</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>
                        {t("initiatives.value_to_capture")}
                      </p>
                      <h3 className={classes.cardTitle}>
                        {(100 * getTotalBenefit()).toFixed(1)}
                        <span className={classes.verySmall}>
                          {" "}
                          p.p. {t("initiatives.in")}{" "}
                        </span>
                        {getInitiativesCount().toString()}
                        <span className={classes.verySmall}>
                          {" "}
                          {getInitiativesCount() === 0 && i18n.language !== "es"
                            ? t("initiatives.initiatives_lower_plural")
                            : getInitiativesCount() <= 1
                              ? t("initiatives.initiatives_lower")
                              : t("initiatives.initiatives_lower_plural")}
                        </span>
                      </h3>
                    </CardHeader>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Card
                    className={cx({
                      [classes.card]: true,
                      ["responsive"]: true,
                    })}
                  >
                    <CardHeader color="client" stats icon>
                      <p className={classes.cardCategory}>
                        {t("initiatives.initiatives_to_check")}
                      </p>
                      <h3 className={classes.cardTitle}>
                        {getInitiativesInAttentionCount().toString()}
                        <span className={classes.verySmall}>
                          {" "}
                          {t("initiatives.in_attention")} {t("initiatives.and")}{" "}
                        </span>
                        {getOutdatedInitiativesCount().toString()}
                        <span className={classes.verySmall}>
                          {" "}
                          {getOutdatedInitiativesCount() === 0 &&
                          i18n.language !== "es"
                            ? t("initiatives.outdated_plural")
                            : getOutdatedInitiativesCount() <= 1
                              ? t("initiatives.update")
                              : t("initiatives.outdated_plural")}
                        </span>
                      </h3>
                    </CardHeader>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
            <div className={classes.fullHeight}>
              <Card
                className={cx({
                  [classes.card]: true,
                  [classes.initiativesCard]: true,
                })}
              >
                <CardHeader color="client" icon>
                  <GridContainer>
                    <GridItem>
                      <p className={classes.cardCategory}>
                        {t("initiatives.list_of_initiatives")}
                      </p>
                    </GridItem>
                    <GridItem xs={true}></GridItem>
                    <GridItem>
                      <ButtonDropdown
                        button={
                          <Tooltip
                            placement={"top"}
                            title={t("initiatives.filters")}
                            PopperProps={{
                              style: { marginBottom: "-8px" },
                            }}
                          >
                            <Button
                              justIcon
                              color="client"
                              className={classes.cardButton}
                              disabled={
                                screenBusy ||
                                initiativesBusy ||
                                selectedInitiative === "new"
                              }
                            >
                              <div style={{ width: "100%" }}>
                                <FilterList
                                  className={classes.cardButtonIcon}
                                />
                              </div>
                            </Button>
                          </Tooltip>
                        }
                        dropdownList={getFilterOptions()}
                        onClick={handleFilterSelection}
                      />
                      <Tooltip
                        placement={"top"}
                        title={t("initiatives.download_data")}
                        PopperProps={{
                          style: { marginBottom: "-8px" },
                        }}
                      >
                        <Button
                          justIcon
                          color="client"
                          className={classes.cardButton}
                          onClick={handleDownloadButton}
                          disabled={
                            screenBusy ||
                            initiativesBusy ||
                            selectedInitiative === "new"
                          }
                        >
                          <div style={{ width: "100%" }}>
                            <GetApp className={classes.cardButtonIcon} />
                          </div>
                        </Button>
                      </Tooltip>
                      <Tooltip
                        placement={"top"}
                        title={t("initiatives.add_initiative")}
                        PopperProps={{
                          style: { marginBottom: "-8px" },
                        }}
                      >
                        <Button
                          justIcon
                          color="client"
                          className={classes.cardButton}
                          onClick={handleAddInitiativeClick}
                          disabled={
                            screenBusy ||
                            initiativesBusy ||
                            selectedInitiative === "new"
                          }
                        >
                          <div style={{ width: "100%" }}>
                            <AddCircle className={classes.cardButtonIcon} />
                          </div>
                        </Button>
                      </Tooltip>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody className={classes.initiativesCardBody}>
                  <div
                    className={cx({
                      [classes.initiativesRow]: true,
                      [classes.initiativesHeader]: true,
                    })}
                  >
                    <div
                      className={cx({
                        [classes.initiativeShortDescription]: true,
                        [classes.initiativeSorted]:
                          initiativesSortedBy === "description",
                      })}
                      onClick={() => setInitiativesSortedBy("description")}
                    >
                      {t("initiatives.description")}
                    </div>
                    <div
                      className={cx({
                        [classes.initiativeLeader]: true,
                        [classes.initiativeSorted]:
                          initiativesSortedBy === "leader",
                      })}
                      onClick={() => setInitiativesSortedBy("leader")}
                    >
                      {t("initiatives.leader")}
                    </div>
                    <div
                      className={cx({
                        [classes.initiativeGlobalBenefit]: true,
                        [classes.initiativeSorted]:
                          initiativesSortedBy === "benefit_global",
                      })}
                      onClick={() => setInitiativesSortedBy("benefit_global")}
                    >
                      {t("initiatives.benefit_global")}
                    </div>
                    <div
                      className={cx({
                        [classes.initiativeBenefit]: true,
                        [classes.initiativeSorted]:
                          initiativesSortedBy === "benefit",
                      })}
                      onClick={() => setInitiativesSortedBy("benefit")}
                    >
                      {t("initiatives.benefit")}
                    </div>
                    <div
                      className={cx({
                        [classes.initiativeBenefitStart]: true,
                        [classes.initiativeSorted]:
                          initiativesSortedBy === "benefit_start",
                      })}
                      onClick={() => setInitiativesSortedBy("benefit_start")}
                    >
                      {t("initiatives.capture_start")}
                    </div>
                    <div
                      className={cx({
                        [classes.initiativeStatus]: true,
                        [classes.initiativeSorted]:
                          initiativesSortedBy === "status",
                      })}
                      onClick={() => setInitiativesSortedBy("status")}
                    >
                      {t("initiatives.status")}
                    </div>
                    <div
                      className={cx({
                        [classes.initiativeDate]: true,
                        [classes.initiativeSorted]:
                          initiativesSortedBy === "last_update",
                      })}
                      onClick={() => setInitiativesSortedBy("last_update")}
                    >
                      {t("initiatives.update_date")}
                    </div>
                  </div>
                  <div
                    className={classes.initiativesWrapper}
                    ref={initiativesWrapper}
                  >
                    {getSortedFilteredInitiativesList().map(
                      (init, key) =>
                        init.enter && (
                          <div
                            className={cx({
                              [classes.initiativesRow]: true,
                              ["inactive"]: init.status !== "active",
                              ["selected"]: init.id === selectedInitiative,
                            })}
                            onClick={() => handleInitiativeClick(init.id)}
                            key={key}
                          >
                            <div
                              className={cx({
                                [classes.initiativeShortDescription]: true,
                              })}
                            >
                              {init.short_description}
                            </div>
                            <div
                              className={cx({
                                [classes.initiativeLeader]: true,
                              })}
                            >
                              {init.leader}
                            </div>
                            <div
                              className={cx({
                                [classes.initiativeGlobalBenefit]: true,
                              })}
                            >
                              {(
                                100 *
                                init.margin_benefit_ratio *
                                init.margin_benefit
                              ).toFixed(benefitDecimals)}
                              %
                            </div>
                            <div
                              className={cx({
                                [classes.initiativeBenefit]: true,
                              })}
                            >
                              {(100 * init.margin_benefit).toFixed(
                                benefitDecimals,
                              )}
                              %
                            </div>
                            <div
                              className={cx({
                                [classes.initiativeBenefitStart]: true,
                              })}
                            >
                              {t(
                                "months." +
                                  moment(
                                    init.margin_benefit_start,
                                    "YYYY-MM",
                                  ).format("M"),
                              )}
                              {"-"}
                              {moment(
                                init.margin_benefit_start,
                                "YYYY-MM",
                              ).format("YY")}
                            </div>
                            <div
                              className={cx({
                                [classes.initiativeStatus]: true,
                                [classes.initiativeStatusColor]: true,
                                ["selected"]: init.id === selectedInitiative,
                                ["status-" + init.status]: true,
                                ["trafficLight" +
                                (
                                  init.follow_up[0].traffic_light || 0
                                ).toString()]: true,
                              })}
                            ></div>
                            <div
                              className={cx({
                                [classes.initiativeDate]: true,
                                ["selected"]: init.id === selectedInitiative,
                                ["critical"]:
                                  moment
                                    .duration(
                                      moment()
                                        .startOf("day")
                                        .diff(
                                          moment(
                                            init.follow_up[0].date,
                                            "YYYY-MM-DD HH:mm",
                                          ),
                                        ),
                                    )
                                    .asDays() > 28,
                              })}
                            >
                              {moment(
                                init.follow_up[0].date,
                                "YYYY-MM-DD HH:mm",
                              ).format("D")}
                              {"-"}
                              {t(
                                "months." +
                                  moment(
                                    init.follow_up[0].date,
                                    "YYYY-MM-DD HH:mm",
                                  ).format("M"),
                              )}
                            </div>
                          </div>
                        ),
                    )}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </GridItem>
        <GridItem
          xs={12}
          md={6}
          className={cx({
            [classes.invisible]: !selectedInitiative,
          })}
        >
          <div className={classes.cardsFullHeight}>
            <div className={classes.cardsWrapper}>
              <Card
                className={cx({
                  [classes.card]: true,
                  ["newInitiative"]: selectedInitiative === "new",
                })}
              >
                <CardHeader color="client" icon>
                  <GridContainer>
                    <GridItem style={{ maxWidth: "70%" }}>
                      <p
                        className={cx({
                          [classes.cardCategory]: true,
                          ["italic"]:
                            !initShortDescriptionPrinted ||
                            selectedInitiative === "new",
                        })}
                      >
                        {selectedInitiative === "new"
                          ? t("initiatives.insert_data_new_initiative")
                          : initShortDescriptionPrinted
                            ? t("initiatives.initiative") +
                              ": " +
                              initShortDescriptionPrinted
                            : t("initiatives.insert_description")}
                      </p>
                    </GridItem>
                    <GridItem xs={true}></GridItem>
                    <GridItem>
                      <ThreeDots
                        color={userDetails.implementation_color}
                        ariaLabel="loading"
                        wrapperStyle={{}}
                        wrapperClass={cx({
                          [classes.initiativesSpinner]: true,
                          [classes.invisible]: !initiativesBusy,
                        })}
                      />
                    </GridItem>
                    <GridItem>
                      <Tooltip
                        placement={"top"}
                        title={t("initiatives.history")}
                        PopperProps={{
                          style: { marginBottom: "-8px" },
                        }}
                      >
                        <Button
                          justIcon
                          color="client"
                          className={classes.cardButton}
                          onClick={() => setDrawerOpen(true)}
                          disabled={screenBusy || initiativesBusy}
                        >
                          <div style={{ width: "100%" }}>
                            <MenuOpen className={classes.cardButtonIcon} />
                          </div>
                        </Button>
                      </Tooltip>
                      {selectedInitiative === "new" && (
                        <Tooltip
                          placement={"top"}
                          title={t("initiatives.cancel_addition")}
                          PopperProps={{
                            style: { marginBottom: "-8px" },
                          }}
                        >
                          <Button
                            justIcon
                            color="warning"
                            className={classes.cardButton}
                            onClick={handleCancelAdditionButton}
                          >
                            <div style={{ width: "100%" }}>
                              <Cancel className={classes.cardButtonIcon} />
                            </div>
                          </Button>
                        </Tooltip>
                      )}
                      <Tooltip
                        placement={"top"}
                        title={t("initiatives.additional_details")}
                        PopperProps={{
                          style: { marginBottom: "-8px" },
                        }}
                      >
                        <Button
                          justIcon
                          color="client"
                          className={classes.cardButton}
                          onClick={handleAdditionalDetailsButtonClick}
                          disabled={
                            screenBusy ||
                            initiativesBusy ||
                            selectedInitiative === "new"
                          }
                        >
                          <div style={{ width: "100%" }}>
                            <Pageview className={classes.cardButtonIcon} />
                          </div>
                        </Button>
                      </Tooltip>
                      <Tooltip
                        placement={"top"}
                        title={t("initiatives.save")}
                        PopperProps={{
                          style: { marginBottom: "-8px" },
                        }}
                      >
                        <Button
                          justIcon
                          color="client"
                          className={cx({
                            [classes.cardButton]: true,
                            [classes.attention]:
                              inputsStatusOk() &&
                              isSavePending() &&
                              !initiativesBusy,
                          })}
                          disabled={
                            screenBusy || initiativesBusy || !inputsStatusOk()
                          }
                          onClick={() => checkAndSaveInitiative()}
                        >
                          <div style={{ width: "100%" }}>
                            <Save className={classes.cardButtonIcon} />
                          </div>
                        </Button>
                      </Tooltip>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody style={{ padding: "0 15px" }}>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      md={8}
                      className={cx({
                        [classes.initiativeInput]: true,
                        ["first"]: true,
                        ["top"]: true,
                      })}
                    >
                      <label className={classes.inputDescription}>
                        {t("initiatives.description")}
                      </label>
                      <CustomInput
                        id="short_description"
                        ref={shortDescriptionInput}
                        value={initShortDescription}
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => handleInputBlur(e)}
                        formControlProps={{
                          fullWidth: true,
                          style: { paddingTop: "0", marginTop: "-6px" },
                        }}
                        inputProps={{
                          type: "text",
                          maxLength: 60,
                          autoComplete: "off",
                          disabled:
                            initiativesBusy ||
                            (selectedInitiative !== "new" &&
                              initiatives[selectedInitiative] &&
                              initiatives[selectedInitiative].status !==
                                "active"),
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.inputAdornment}
                            >
                              {initShortDescriptionStatus && (
                                <Check
                                  className={classes.inputAdornmentIconSuccess}
                                />
                              )}
                              {!initShortDescriptionStatus && (
                                <Clear
                                  className={classes.inputAdornmentIconError}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        client
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={4}
                      className={cx({
                        [classes.initiativeInput]: true,
                        ["top"]: true,
                      })}
                    >
                      <label className={classes.inputDescription}>
                        {t("initiatives.leader")}
                      </label>
                      <CustomInput
                        id="leader"
                        value={initLeader}
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => handleInputBlur(e)}
                        formControlProps={{
                          fullWidth: true,
                          style: { paddingTop: "0", marginTop: "-6px" },
                        }}
                        inputProps={{
                          type: "text",
                          maxLength: 20,
                          autoComplete: "off",
                          disabled:
                            initiativesBusy ||
                            (selectedInitiative !== "new" &&
                              initiatives[selectedInitiative] &&
                              initiatives[selectedInitiative].status !==
                                "active"),
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.inputAdornment}
                            >
                              {initLeaderStatus && (
                                <Check
                                  className={classes.inputAdornmentIconSuccess}
                                />
                              )}
                              {!initLeaderStatus && (
                                <Clear
                                  className={classes.inputAdornmentIconError}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        client
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={3}
                      className={cx({
                        [classes.initiativeInput]: true,
                      })}
                    >
                      <label className={classes.inputDescription}>
                        {t("initiatives.benefit")}
                      </label>
                      <CustomInput
                        id="benefit"
                        value={initBenefit}
                        onChange={(e) => handleInputChange(e)}
                        onBlur={(e) => handleInputBlur(e)}
                        formControlProps={{
                          fullWidth: true,
                          style: { paddingTop: "0", marginTop: "-6px" },
                        }}
                        inputProps={{
                          type: "number",
                          autoComplete: "off",
                          step: "0.05",
                          disabled:
                            initiativesBusy ||
                            (selectedInitiative !== "new" &&
                              initiatives[selectedInitiative] &&
                              initiatives[selectedInitiative].status !==
                                "active"),
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.inputAdornment}
                            >
                              %
                              {initBenefitStatus && (
                                <Check
                                  className={classes.inputAdornmentIconSuccess}
                                />
                              )}
                              {!initBenefitStatus && (
                                <Clear
                                  className={classes.inputAdornmentIconError}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        client
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={1}
                      className={cx({
                        [classes.initiativeInput]: true,
                      })}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label
                        className={classes.inputDescription}
                        style={{
                          fontSize: "18px",
                          marginLeft: "10px",
                        }}
                      >
                        ×
                      </label>
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={3}
                      className={cx({
                        [classes.initiativeInput]: true,
                      })}
                    >
                      <label className={classes.inputDescription}>
                        {t("initiatives.scope_margin_ratio")}
                      </label>
                      {initiativesBusy ? (
                        <ThreeDots
                          color={grayColor[0]}
                          ariaLabel="loading"
                          width={"20px"}
                          height={"12px"}
                          wrapperStyle={{}}
                          wrapperClass={classes.plSpinner}
                        />
                      ) : (
                        <CustomInput
                          id="scope_margin_ratio"
                          value={initScopeMarginRatio}
                          formControlProps={{
                            fullWidth: true,
                            style: { paddingTop: "0", marginTop: "-6px" },
                          }}
                          inputProps={{
                            type: "number",
                            disabled: true,
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                          client
                        />
                      )}
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip
                        placement={"top"}
                        title={t("initiatives.refresh")}
                        PopperProps={{
                          style: { marginBottom: "-8px" },
                        }}
                      >
                        <Refresh
                          className={classes.refreshIcon}
                          disabled={
                            screenBusy || initiativesBusy || !inputsStatusOk()
                          }
                          onClick={() => {
                            updateInitData();
                          }}
                        />
                      </Tooltip>
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={1}
                      className={cx({
                        [classes.initiativeInput]: true,
                      })}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <label
                        className={classes.inputDescription}
                        style={{ fontSize: "18px", marginLeft: "10px" }}
                      >
                        =
                      </label>
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={3}
                      className={cx({
                        [classes.initiativeInput]: true,
                      })}
                    >
                      <label className={classes.inputDescription}>
                        {t("initiatives.benefit_global")}
                      </label>
                      <CustomInput
                        id="benefit_global"
                        value={initBenefitGlobal}
                        onBlur={(e) => handleInputBlur(e)}
                        formControlProps={{
                          fullWidth: true,
                          style: { paddingTop: "0", marginTop: "-6px" },
                        }}
                        inputProps={{
                          type: "number",
                          disabled: true,
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        client
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      className={cx({
                        [classes.initiativeInput]: true,
                      })}
                    >
                      <label className={classes.inputDescription}>
                        {t("initiatives.capture_start")}
                      </label>
                      <DatePickerBenefitDates
                        value={initBenefitStart}
                        onChange={(e) => handleInputChange(e, "benefits_start")}
                        status={initBenefitStartStatus}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={6}
                      className={cx({
                        [classes.initiativeInput]: true,
                      })}
                    >
                      <label className={classes.inputDescription}>
                        {t("initiatives.capture_full_potential")}
                      </label>
                      <DatePickerBenefitDates
                        value={initBenefitEnd}
                        onChange={(e) => handleInputChange(e, "benefits_end")}
                        status={initBenefitEndStatus}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      md={12}
                      className={cx({
                        [classes.initiativeInput]: true,
                      })}
                    >
                      <label className={classes.inputDescription}>
                        {t("initiatives.scope")}
                      </label>
                      <Card className={classes.topCardShortSpacing}>
                        <CardBody className={classes.topCardBody}>
                          <GridContainer
                            alignItems={"center"}
                            style={{ marginBottom: "5px" }}
                          >
                            <GridItem>
                              {metaLoaded && (
                                <FilterDropdown
                                  dropPlacement={"bottom-start"}
                                  hoverColor={"client"}
                                  dropdownHeader={t(
                                    "results.add_change_filter",
                                  )}
                                  dropdownList={filtersListTranslated()}
                                  dropdownActiveItems={filtersActiveList()}
                                  dropdownFilterSequence={filtersSequence()}
                                  tooltip={t("results.add_change_filter")}
                                  onClick={filterMenuClickHandler()}
                                />
                              )}
                            </GridItem>
                            <GridItem xs={true}>
                              {currentScopeFilters()}
                              {!metaLoaded && (
                                <ThreeDots
                                  color={userDetails.implementation_color}
                                  ariaLabel="loading"
                                  height={"22px"}
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                />
                              )}
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </div>
            <Card chart className={classes.chartCard}>
              <CardHeader
                color={
                  userDetails.implementation_tone === "danger"
                    ? "danger"
                    : userDetails.implementation_tone === "gray"
                      ? "gray"
                      : "client"
                }
                className={classes.cardHeaderHover}
              >
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={
                    initiativesBusy
                      ? {
                          labels: [0],
                          series: [[0]],
                        }
                      : monthlyServeMarginChart.data
                  }
                  type="Line"
                  options={monthlyServeMarginChart.options}
                  listener={monthlyServeMarginChart.animation}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <h4 className={classes.cardTitle}>
                    <Translation>
                      {(t) => t("overview.serve_margin")}
                    </Translation>
                  </h4>
                  <p className={classes.cardCategory}>
                    <Translation>
                      {(t) => t("overview.last_12_months")}
                    </Translation>
                  </p>
                </div>
              </CardBody>
            </Card>
          </div>
        </GridItem>
      </GridContainer>
      {screenBusy && (
        <ThreeDots
          height="18px"
          color={userDetails.implementation_color}
          ariaLabel="loading"
          wrapperStyle={{}}
          wrapperClass={classes.screenSpinner}
        />
      )}
      {filtersDialog}
      {additionalDetailsDialog}
      {AddUpdateDialog}
      {storyDrawer}
    </div>
  );
}

Initiatives.propTypes = {
  location: PropTypes.object,
};
