import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import {
  blendColors,
  dangerColor,
  grayColor,
  whiteColor,
} from "../../assets/jss/material-dashboard-pro-react";
import AuthContext from "../../contexts/AuthProvider";

import { ThreeDots } from "react-loader-spinner";
import Tooltip from "@material-ui/core/Tooltip";

import FilterDropdown from "../FilterDropdown/FilterDropdown";
import { Sync } from "@material-ui/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faFilter,
  faPenToSquare,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

import { getOom } from "../../utils/utils";

const styles = (clientColor) => ({
  plWrapper: {
    position: "relative",
  },
  plHeader: {
    color: whiteColor,
    borderRadius: "5px 5px 0 0",
    paddingLeft: "5px",
    textAlign: "left",
    fontSize: "12px",
    paddingTop: "2px",
    paddingBottom: "2px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0",
    backgroundColor: clientColor,
  },
  plName: {
    maxWidth: "80%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  plActionWrapper: {
    fontSize: "14px",
    position: "absolute",
    right: "5px",
    top: "3px",
  },
  plAction: {
    display: "inline",
    margin: "0 5px",
    cursor: "pointer",
  },
  plOuterWrapper: {
    width: "100%",
    padding: "4px 0",
    display: "inline-block",
    fontSize: "12px",
    color: grayColor[6],
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  plErrorCover: {
    width: "100%",
    height: "calc(100% - 32px)",
    position: "absolute",
    backgroundColor: "white",
    opacity: "0.7",
    "&:hover": {},
  },
  plErrorRefreshIcon: {
    color: clientColor,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "& > svg": {
      height: "100px",
      width: "100px",
      cursor: "pointer",
      transition: "all 0.2s",
    },
    "&:hover": {
      "& > svg": {
        transform: "scale(1.2)",
      },
    },
  },
  plErrorMessage: {
    textAlign: "center",
    fontSize: "1.25vmin",
    padding: "0 6px",
    position: "absolute",
    left: "50%",
    bottom: "0",
    transform: "translate(-50%, -100%)",
    borderRadius: "3px",
    color: whiteColor,
    backgroundColor: dangerColor[0],
    opacity: "1",
  },
  plLineWrapper: {
    width: "100%",
    minHeight: "24px",
    display: "flex",
    lineHeight: "2",
    "& > div:first-child": {
      paddingLeft: "4px",
    },
    "& > div:last-child": {
      paddingRight: "4px",
    },
    transition: "opacity 0.2s",
    cursor: "pointer",
  },
  plLineDescription: {
    width: "55%",
    textOverflow: "ellipsis",
    "&.hidden": {
      display: "none",
    },
  },
  plValue: {
    width: "22.5%",
    textAlign: "right",
    "&.negative": {
      color: dangerColor[0],
    },
    "&.currency.expanded": {
      width: "44.444%",
    },
    "&.percentual.expanded": {
      width: "55.555%",
    },
    "&.currency:not(.expanded)": {
      width: "20%",
    },
    "&.percentual:not(.expanded)": {
      width: "25%",
    },
  },
  plNumber: {
    fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace;',
  },
  plVolumeValue: {
    width: "45%",
    "&.expanded": {
      width: "100% !important",
    },
  },
  plSpinner: {
    maxWidth: "20px",
  },
  plPercTotalVolume: {
    fontSize: "10px",
    lineHeight: "12px",
    color: grayColor[0],
    fontStyle: "italic",
  },
  plTableHeader: {
    color: whiteColor,
    backgroundColor: grayColor[6],
  },
  plTotal: {
    backgroundColor: grayColor[8],
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  plSubTotal: {
    backgroundColor: grayColor[13],
    "& > div:first-child": {
      paddingLeft: "12px !important",
    },
  },
  plLevel3: {
    color: grayColor[1],
  },
  highlighted: {
    backgroundColor:
      blendColors(grayColor[8], clientColor, 0.9) + " !important",
  },
  plLoadingDetails: {
    backgroundSize: "200% 100%",
    backgroundImage:
      "linear-gradient(to right, " +
      blendColors(grayColor[8], clientColor, 0.9) +
      " 50%, " +
      blendColors(grayColor[8], clientColor, 0.8) +
      " 50%)",
    "-webkit-transition": "background-position 3s ease-in-out",
    "-moz-transition": "background-position 3s ease-in-out",
    transition: "background-position 3s ease-in-out",
    "&:hover": {
      backgroundPosition: "-100% 0",
    },
  },
  plDrillDown: {
    "& > span:before": {
      fontFamily: "'Font Awesome 5 Free'",
      padding: "0 4px 0 0",
      fontWeight: "900",
      fontSize: "10px",
      cursor: "pointer",
    },
  },
  plDrillDownOpen: {
    "& > span:before": {
      content: "'\\f146'",
    },
  },
  plDrillDownClosed: {
    "& > span:before": {
      content: "'\\f0fe'",
    },
  },
  plIndentL2: {
    paddingLeft: "14px !important",
    "& > span:before": {
      color: grayColor[4],
      content: "'\\2517'",
      padding: "0 3px 0 0",
      fontSize: "10px",
    },
  },
  plComparison: {
    "&:before": {
      content: "'\\25cf'",
      marginRight: "4px",
      fontSize: "18px",
      lineHeight: "12px",
    },
    "&.n1:before": {
      color: "#F44336",
    },
    "&.n2:before": {
      color: "#F76C24",
    },
    "&.n3:before": {
      color: "#FB9612",
    },
    "&.n4:before": {
      color: "#FFC000",
    },
    "&.n5:before": {
      color: "#F6C737",
    },
    "&.n6:before": {
      color: "#EECE6E",
    },
    "&.n7:before": {
      color: "#E5D5A5",
    },
    "&.n8:before": {
      color: "#DDDDDD",
    },
    "&.n9:before": {
      color: "#C8D6C8",
    },
    "&.n10:before": {
      color: "#B3CFB4",
    },
    "&.n11:before": {
      color: "#9EC9A0",
    },
    "&.n12:before": {
      color: "#8AC28C",
    },
    "&.n13:before": {
      color: "#75BC78",
    },
    "&.n14:before": {
      color: "#60B564",
    },
    "&.n15:before": {
      color: "#4CAF50",
    },
  },
  hidden: {
    display: "none",
  },
});

const Pnl = (props) => {
  let { userDetails } = useContext(AuthContext);
  const client_styles = () => ({
    ...styles(userDetails.implementation_color),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();

  const formatter = new Intl.NumberFormat("us-EN");
  const { t, i18n } = useTranslation();

  const [drillDownsOpen, setDrillDownsOpen] = useState([]);
  const [oom, setOom] = useState({
    div: 1e6,
    x: " " + t("oom.m"),
  });
  const [showValueByVolumeDecimals, setShowValueByVolumeDecimals] =
    useState(false);

  const {
    plStructure,
    netRevenueAnchor,
    serveMarginAnchor,
    revenueAnchors,
    numPL,
    numPLs,
    maxPLs,
    plNames,
    plName,
    filterable,
    handleChangePLNameClick,
    handleMouseLeavePLLine,
    handleMouseOverPLLine,
    handleMouseLeavePLVal,
    handleMouseOverPLVal,
    mouseOverPLLine,
    mouseOverPLLineFade,
    mouseOverVal,
    timersAllowed,
    filtersListTranslated,
    filtersActiveList,
    filtersSequence,
    filterMenuClickHandler,
    addPL,
    removePL,
    plsOnError,
    retryPL,
    plsToRefresh,
    isRefreshing,
    plValues,
    volumeUnit,
    volumeL12M,
    currencyUnit,
    showValueType,
    isLoading,
  } = props;

  const plSpinner = (
    <GridContainer
      justifyContent={"flex-end"}
      alignItems={"center"}
      style={{ height: "100%" }}
    >
      <GridItem>
        <ThreeDots
          color={grayColor[0]}
          ariaLabel="loading"
          width={"20px"}
          height={"12px"}
          wrapperStyle={{}}
          wrapperClass={classes.plSpinner}
        />
      </GridItem>
    </GridContainer>
  );

  const getCriticalityLevel = (numPL, meta_name) => {
    let revenue = plValues[numPL][netRevenueAnchor.current];
    if (revenue <= 0) return "8"; // 8 = neutral value
    let val = plValues[numPL][meta_name] / revenue;
    let is_total = false;
    let ref = 0;
    plStructure.current.forEach((line) => {
      if (line.meta_name === meta_name) {
        ref = line.ref_value;
        is_total = line.level === 1;
      }
    });
    let min = ref * 0.8;
    let max = ref * 1.2;
    if (is_total) {
      return (
        1 +
        Math.min(Math.max(Math.round((14 * (val - min)) / (max - min)), 1), 14)
      ).toString();
    } else {
      return (
        15 -
        Math.min(Math.max(Math.round((14 * (val - min)) / (max - min)), 1), 14)
      ).toString();
    }
  };
  const getPlPercentValue = (numPL, name) => {
    if (plValues[numPL][netRevenueAnchor.current]) {
      return (
        (
          (100 * plValues[numPL][name]) /
          plValues[numPL][netRevenueAnchor.current]
        ).toFixed(1) + "%"
      );
    } else {
      return "-";
    }
  };

  useEffect(() => {
    let new_drill_downs_open = [];
    plStructure.current.forEach((plLine) => {
      if (plLine.is_parent) new_drill_downs_open.push(plLine.meta_name);
    });
    setDrillDownsOpen(new_drill_downs_open);
  }, [plStructure.current]);

  useLayoutEffect(() => {
    // changes the order of magnitude in which all the P&Ls are presented
    //    - fired when the plValues or language is modified
    //    - store results in 'oom' object
    let net_revenues = [];
    let serve_margins = [];
    let serve_margins_per_volume = [];
    let median = 0;
    let median_per_volume = 0;
    Object.values(plValues).forEach((value) => {
      if (Object.keys(value).includes(netRevenueAnchor.current)) {
        net_revenues.push(value[netRevenueAnchor.current]);
      }
      if (Object.keys(value).includes(serveMarginAnchor.current)) {
        serve_margins.push(value[serveMarginAnchor.current]);
        serve_margins_per_volume.push(
          value[serveMarginAnchor.current] / value["volume"],
        );
      }
    });
    if (net_revenues.length > 0) {
      const avg_revenue_and_margin = net_revenues.map(
        (num, index) => (num + serve_margins[index]) / 2,
      );
      const sorted = Array.from(avg_revenue_and_margin).sort((a, b) => a - b);
      const sorted_per_volume = Array.from(serve_margins_per_volume).sort(
        (a, b) => a - b,
      );
      const middle = Math.floor(sorted.length / 2);
      const middle_per_volume = Math.floor(sorted_per_volume.length / 2);
      median =
        sorted.length % 2 === 0
          ? (sorted[middle - 1] + sorted[middle]) / 2
          : sorted[middle];
      median_per_volume =
        sorted_per_volume.length % 2 === 0
          ? (sorted_per_volume[middle_per_volume - 1] +
              sorted_per_volume[middle_per_volume]) /
            2
          : sorted_per_volume[middle_per_volume];
    }
    let { div, x } = getOom(median);
    x = x === "" ? x : " " + t("oom." + x);
    setOom({ div: div, x: x });
    setShowValueByVolumeDecimals(median_per_volume < 100);
  }, [plValues, i18n.language]);

  const handleDrillDownClick = (meta_name) => {
    if (drillDownsOpen.includes(meta_name)) {
      setDrillDownsOpen(drillDownsOpen.filter((item) => item !== meta_name));
    } else {
      setDrillDownsOpen((drillDownsOpen) => [...drillDownsOpen, meta_name]);
    }
    return false;
  };

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <ThreeDots
          width={"120px"}
          color={userDetails.implementation_color}
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <div
      className={cx({
        [classes.plWrapper]: true,
      })}
    >
      {/* region P&L Header */}
      {(plNames || plName) && (
        <div
          className={cx({
            [classes.plHeader]: true,
            ["first"]: numPL === 1,
          })}
        >
          <div
            className={classes.plName}
            onClick={
              handleChangePLNameClick
                ? () => handleChangePLNameClick(numPL)
                : null
            }
          >
            {plNames
              ? plNames[numPL] && plNames[numPL] !== ""
                ? plNames[numPL]
                : "P&L " + numPL.toString()
              : plName}
          </div>
          <div className={classes.plActionWrapper}>
            {plNames && numPLs <= 4 && (
              <Tooltip title={t("pnl.add_edit_name")}>
                <div
                  className={classes.plAction}
                  onClick={
                    handleChangePLNameClick
                      ? () => handleChangePLNameClick(numPL)
                      : null
                  }
                >
                  <FontAwesomeIcon icon={faPenToSquare} />
                </div>
              </Tooltip>
            )}
            {filterable && (
              <div className={classes.plAction}>
                <FilterDropdown
                  button={<FontAwesomeIcon icon={faFilter} />}
                  dropPlacement={"bottom-start"}
                  hoverColor={"client"}
                  dropdownHeader={t("pnl.add_change_filter")}
                  dropdownList={filtersListTranslated()}
                  dropdownActiveItems={filtersActiveList("pl", numPL)}
                  dropdownFilterSequence={filtersSequence(numPL)}
                  tooltip={t("pnl.add_change_filter")}
                  onClick={filterMenuClickHandler("pl", numPL)}
                />
              </div>
            )}
            {addPL && numPLs < maxPLs && (
              <Tooltip title={t("pnl.clone_pnl")}>
                <div className={classes.plAction} onClick={() => addPL(numPL)}>
                  <FontAwesomeIcon icon={faClone} />
                </div>
              </Tooltip>
            )}
            {removePL && (
              <Tooltip title={t("pnl.remove")}>
                <div
                  className={classes.plAction}
                  onClick={() => removePL(numPL)}
                >
                  <FontAwesomeIcon icon={faX} />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      )}
      {/* endregion */}
      {/* region P&L Lines */}
      <div className={classes.plOuterWrapper}>
        {/* region P&L Error */}
        {plsOnError.includes(numPL) && (
          <div className={classes.plErrorCover}>
            <div
              className={classes.plErrorRefreshIcon}
              onClick={() => retryPL(numPL)}
            >
              <Sync />
            </div>
            <div className={classes.plErrorMessage}>
              {t("pnl.error_on_load")}
            </div>
          </div>
        )}
        {/* endregion */}
        {/* region P&L Volume */}
        <div className={classes.plLineWrapper}>
          <div
            className={cx({
              [classes.plLineDescription]: true,
              ["hidden"]: numPL > 1 && numPLs >= 4,
            })}
          >
            {t("pnl.volume")}
          </div>
          <div
            className={cx({
              [classes.plValue]: true,
              [classes.plNumber]: true,
              [classes.plVolumeValue]: true,
              ["expanded"]: numPL > 1 && numPLs >= 4,
            })}
          >
            {!isRefreshing &&
              !plsToRefresh.includes(numPL) &&
              (plValues[numPL].volume ? (
                <>
                  {formatter.format(plValues[numPL].volume).split(".")[0]}
                  <span className={classes.plUnit}>
                    {" " + volumeUnit.current}
                  </span>
                </>
              ) : (
                "-"
              ))}
            {(isRefreshing || plsToRefresh.includes(numPL)) && plSpinner}
          </div>
        </div>
        {volumeL12M.current && (
          <div className={classes.plLineWrapper}>
            <div
              className={cx({
                [classes.plLineDescription]: true,
                [classes.plPercTotalVolume]: true,
                ["hidden"]: numPL > 1 && numPLs >= 4,
              })}
            >
              {t("pnl.percent_of_total_volume")}
            </div>
            <div
              className={cx({
                [classes.plValue]: true,
                [classes.plNumber]: true,
                [classes.plPercTotalVolume]: true,
                [classes.plVolumeValue]: true,
                ["expanded"]: numPL > 1 && numPLs >= 4,
              })}
            >
              {!isRefreshing &&
                !plsToRefresh.includes(numPL) &&
                (plValues[numPL].volume && volumeL12M.current
                  ? (
                      (100 * plValues[numPL].volume) /
                      volumeL12M.current
                    ).toFixed(1) + "%"
                  : "-")}
              {(isRefreshing || plsToRefresh.includes(numPL)) && plSpinner}
            </div>
          </div>
        )}
        {/* endregion */}
        {/* region P&L Table Header */}
        <div className={classes.plLineWrapper}>
          <div
            className={cx({
              [classes.plLineDescription]: true,
              [classes.plTableHeader]: true,
              ["hidden"]: numPL > 1 && numPLs >= 4,
            })}
          >
            {"P&L"}
          </div>
          <div
            className={cx({
              [classes.plValue]: true,
              [classes.plTableHeader]: true,
              ["currency"]: true,
              ["expanded"]: numPL > 1 && numPLs >= 4,
            })}
          >
            {currencyUnit && !isRefreshing && !plsToRefresh.includes(numPL)
              ? showValueType === "value"
                ? currencyUnit + oom.x
                : currencyUnit + "/" + volumeUnit.current
              : "-"}
          </div>
          <div
            className={cx({
              [classes.plValue]: true,
              [classes.plTableHeader]: true,
              ["percentual"]: true,
              ["expanded"]: numPL > 1 && numPLs >= 4,
            })}
          >
            {"%"}
          </div>
        </div>
        {/* endregion */}
        {/* region P&L Financials */}
        <div
          onMouseLeave={handleMouseLeavePLLine ? handleMouseLeavePLLine : null}
        >
          {plStructure.current.map((val, key) => {
            return (
              <div
                key={key}
                className={cx({
                  [classes.plLineWrapper]: true,
                  [classes.plTotal]: val.level === 1,
                  [classes.plSubTotal]: val.level === 2,
                  [classes.plLevel3]: val.level === 3,
                  [classes.highlighted]:
                    mouseOverPLLine &&
                    mouseOverPLLine === val.meta_name &&
                    timersAllowed,
                  [classes.faded]:
                    mouseOverPLLineFade &&
                    mouseOverPLLineFade !== val.meta_name &&
                    timersAllowed &&
                    numPLs > 1,
                  [classes.hidden]:
                    val.parent && !drillDownsOpen.includes(val.parent),
                  [classes.plLoadingDetails]:
                    mouseOverPLLine === val.meta_name &&
                    mouseOverVal &&
                    timersAllowed,
                })}
                onMouseOut={
                  handleMouseLeavePLLine ? handleMouseLeavePLLine : null
                }
                onMouseOver={
                  handleMouseOverPLLine
                    ? () => handleMouseOverPLLine(val.meta_name)
                    : null
                }
              >
                <div
                  className={cx({
                    [classes.plLineDescription]: true,
                    [classes.plDrillDown]: val.is_parent,
                    [classes.plDrillDownOpen]:
                      val.is_parent && drillDownsOpen.includes(val.meta_name),
                    [classes.plDrillDownClosed]:
                      val.is_parent && !drillDownsOpen.includes(val.meta_name),
                    [classes.plIndentL2]: val.level === 3,
                    ["hidden"]: numPL > 1 && numPLs >= 4,
                  })}
                >
                  {val.is_parent && (
                    <span
                      onClick={
                        handleDrillDownClick
                          ? () => handleDrillDownClick(val.meta_name)
                          : null
                      }
                    ></span>
                  )}
                  {val.parent && <span></span>}
                  {val.name}
                </div>
                <div
                  className={cx({
                    [classes.plValue]: true,
                    [classes.plNumber]: true,
                    ["currency"]: true,
                    ["negative"]:
                      val.level === 1 &&
                      plValues[numPL][val.meta_name] &&
                      plValues[numPL][val.meta_name] < 0,
                    ["expanded"]: numPL > 1 && numPLs >= 4,
                  })}
                  onMouseOut={() =>
                    handleMouseLeavePLVal
                      ? handleMouseLeavePLVal(val.meta_name)
                      : null
                  }
                  onMouseOver={() =>
                    handleMouseOverPLVal
                      ? handleMouseOverPLVal(val.meta_name, numPL)
                      : null
                  }
                >
                  {!isRefreshing &&
                    !plsToRefresh.includes(numPL) &&
                    (plValues[numPL][val.meta_name] ? (
                      <span>
                        {showValueType === "value"
                          ? (plValues[numPL][val.meta_name] / oom.div).toFixed(
                              2,
                            )
                          : showValueByVolumeDecimals
                            ? formatter
                                .format(
                                  plValues[numPL][val.meta_name] /
                                    plValues[numPL]["volume"],
                                )
                                .split(".")[0] +
                              "." +
                              formatter
                                .format(
                                  plValues[numPL][val.meta_name] /
                                    plValues[numPL]["volume"],
                                )
                                .split(".")[1]
                                .substring(0, 2)
                            : formatter
                                .format(
                                  plValues[numPL][val.meta_name] /
                                    plValues[numPL]["volume"],
                                )
                                .split(".")[0]}
                      </span>
                    ) : (
                      "-"
                    ))}
                  {(isRefreshing || plsToRefresh.includes(numPL)) && plSpinner}
                </div>
                <div
                  className={cx({
                    [classes.plValue]: true,
                    [classes.plNumber]: true,
                    ["percentual"]: true,
                    ["negative"]:
                      val.level === 1 &&
                      plValues[numPL][val.meta_name] &&
                      plValues[numPL][val.meta_name] < 0,
                    ["expanded"]: numPL > 1 && numPLs >= 4,
                    [classes.plComparison]:
                      !isRefreshing &&
                      !plsToRefresh.includes(numPL) &&
                      val.ref_value !== null &&
                      plValues[numPL][val.meta_name] &&
                      !revenueAnchors.includes(val.meta_name),
                    ["n" + getCriticalityLevel(numPL, val.meta_name)]:
                      !isRefreshing &&
                      !plsToRefresh.includes(numPL) &&
                      val.ref_value !== null &&
                      !revenueAnchors.includes(val.meta_name),
                  })}
                  onMouseOut={
                    handleMouseLeavePLVal
                      ? () => handleMouseLeavePLVal(val.meta_name)
                      : null
                  }
                  onMouseOver={
                    handleMouseOverPLVal
                      ? () => handleMouseOverPLVal(val.meta_name, numPL)
                      : null
                  }
                >
                  {!isRefreshing &&
                    !plsToRefresh.includes(numPL) &&
                    (plValues[numPL][val.meta_name] ? (
                      <span>{getPlPercentValue(numPL, val.meta_name)}</span>
                    ) : (
                      "-"
                    ))}
                  {(isRefreshing || plsToRefresh.includes(numPL)) && plSpinner}
                </div>
              </div>
            );
          })}
        </div>
        {/* endregion */}
      </div>
      {/* endregion */}
    </div>
  );
};

Pnl.defaultProps = {
  revenueAnchors: [],
  numPL: 1,
  numPLs: 1,
  maxPLs: 1,
  plsOnError: [],
  plsToRefresh: [],
  showValueType: "value",
  oom: { x: "", div: 1 },
};

Pnl.propTypes = {
  plStructure: PropTypes.object.isRequired,
  netRevenueAnchor: PropTypes.object.isRequired,
  serveMarginAnchor: PropTypes.object.isRequired,
  revenueAnchors: PropTypes.array,
  numPL: PropTypes.number,
  numPLs: PropTypes.number,
  maxPLs: PropTypes.number,
  plNames: PropTypes.object,
  plName: PropTypes.string,
  filterable: PropTypes.bool,
  handleChangePLNameClick: PropTypes.func,
  handleMouseLeavePLLine: PropTypes.func,
  handleMouseOverPLLine: PropTypes.func,
  handleMouseLeavePLVal: PropTypes.func,
  handleMouseOverPLVal: PropTypes.func,
  mouseOverPLLine: PropTypes.string,
  mouseOverPLLineFade: PropTypes.string,
  mouseOverVal: PropTypes.bool,
  timersAllowed: PropTypes.bool,
  filtersListTranslated: PropTypes.func,
  filtersActiveList: PropTypes.func,
  filtersSequence: PropTypes.func,
  filterMenuClickHandler: PropTypes.func,
  addPL: PropTypes.func,
  removePL: PropTypes.func,
  plsOnError: PropTypes.array,
  retryPL: PropTypes.func,
  plsToRefresh: PropTypes.array,
  isRefreshing: PropTypes.bool,
  plValues: PropTypes.object.isRequired,
  volumeUnit: PropTypes.object.isRequired,
  volumeL12M: PropTypes.object.isRequired,
  currencyUnit: PropTypes.string.isRequired,
  showValueType: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

export default Pnl;
