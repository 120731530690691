import {
  blackColor,
  grayColor,
  whiteColor,
  dangerColor,
  successColor,
  hexToRgb,
  blendColors,
  cardTitle,
  warningColor,
  infoColor,
} from "assets/jss/material-dashboard-pro-react.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";

const initiativesStyle = (theme, clientColor) => ({
  ...customSelectStyle(theme, clientColor),
  ...customInputStyle(clientColor),
  ...modalStyle(theme),
  cardsWrapper: {
    width: "100%",
  },
  fullHeight: {
    marginTop: "-15px",
    height: "100%",
  },
  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    paddingBottom: "3px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&.italic": {
      fontStyle: "italic !important",
      paddingRight: "4px",
    },
  },
  cardIcon: {
    marginTop: "-12px !important",
    padding: "0 !important",
  },
  card: {
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "5px",
      marginBottom: "0",
    },
    "&.newInitiative": {
      border: "1px " + clientColor + " solid",
    },
  },
  verySmall: {
    color: grayColor[1],
    fontSize: "12px",
  },
  cardsFullHeight: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      height: "87vh",
    },
  },
  initiativesCard: {
    marginTop: "2px",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "21px",
    },
  },
  initiativesCardBody: {
    padding: "12px 0",
    [theme.breakpoints.up("sm")]: {
      height: "100%",
      position: "relative",
    },
  },
  initiativesWrapper: {
    fontSize: "11px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      overflow: "hidden",
      whiteSpace: "nowrap",
      top: "38px",
      bottom: "8px",
    },
  },
  initiativesRow: {
    display: "flex",
    width: "100%",
    paddingTop: "2px",
    paddingBottom: "0",
    "&:not($initiativesHeader)": {
      backgroundColor: whiteColor,
      cursor: "pointer",
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: grayColor[8],
      },
    },
    borderBottom: "1px #eee solid",
    "&.selected": {
      color: whiteColor,
      backgroundColor: clientColor + " !important",
      "&:hover": {
        backgroundColor: clientColor + "cc !important",
      },
    },
    "&.inactive:not(.selected)": {
      opacity: "0.5",
    },
  },
  initiativesHeader: {
    fontWeight: "bold",
    cursor: "pointer",
    color: whiteColor,
    backgroundColor: grayColor[6],
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: "1.5vmin",
    "& > div": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  initiativeShortDescription: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "37%",
    paddingLeft: "4px",
  },
  initiativeLeader: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: "center",
    width: "23%",
  },
  initiativeBenefit: {
    textAlign: "center",
    width: "16%",
  },
  initiativeGlobalBenefit: {
    textAlign: "center",
    width: "18%",
  },
  initiativeBenefitStart: {
    textAlign: "center",
    width: "16%",
  },
  initiativeStatus: {
    textAlign: "center",
    width: "9%",
  },
  initiativeStatusColor: {
    "&:before": {
      fontFamily: "'Font Awesome 5 Free'",
      fontWeight: "900",
      fontSize: "1.85vmin",
      color: grayColor[4],
      textShadow: "#999 0 0 3px",
    },
    "&.selected": {
      "&:before": {
        textShadow: "#fff 0 0 3px !important",
      },
    },
    "&.status-active": {
      "&.trafficLight1": {
        "&:before": {
          color: successColor[0],
          content: "'\\f111'",
        },
      },
      "&.trafficLight2": {
        "&:before": {
          color: warningColor[7],
          content: "'\\f111'",
        },
      },
      "&.trafficLight3": {
        "&:before": {
          color: dangerColor[0],
          content: "'\\f111'",
        },
      },
    },
    "&.status-on_hold": {
      "&:before": {
        color: grayColor[6],
        content: "'\\f28b'",
      },
    },
    "&.status-cancelled": {
      "&:before": {
        color: dangerColor[3],
        content: "'\\f057'",
      },
    },
    "&.status-finished": {
      "&:before": {
        color: infoColor[4],
        content: "'\\f058'",
      },
    },
  },
  initiativeSorted: {
    "&:after": {
      fontSize: "1.5vmin",
      padding: "0 0 0 3px",
      content: "'\\25cf'",
    },
  },
  initiativeDate: {
    textAlign: "center",
    width: "8%",
    "&.critical": {
      "&.selected": {
        color: blendColors(dangerColor[0], whiteColor, 0.25),
      },
      "&:not(.selected)": {
        color: dangerColor[0],
      },
    },
  },
  inputDescription: {
    fontSize: "11px",
  },
  timeline: {
    paddingTop: "0",
    position: "relative",
    "& ul": {
      marginTop: "5px !important",
    },
  },
  followUpWrapper: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "0 15px",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: grayColor[5],
      borderRadius: "4px",
    },
  },
  actionWrapperOuter: {
    paddingLeft: "10px !important",
    paddingRight: "10px !important",
  },
  actionWrapper: {
    cursor: "pointer",
    opacity: "0.8",
    transition: "opacity 0.1s",
    "&:hover,&:active": {
      opacity: "1",
      "& div:first-child": {
        boxShadow: "0 0px 30px -10px rgba(" + hexToRgb(blackColor) + ", 0.3)",
      },
      "& div:first-child > svg": {
        transform: "scale(1.2)",
      },
    },
    "&:active": {
      "& div:first-child": {
        boxShadow:
          "0 0px 30px -10px rgba(" + hexToRgb(blackColor) + ", 0.7) !important",
      },
    },
  },
  actionDisabled: {
    opacity: "0.5 !important",
    pointerEvents: "none",
  },
  actionIcon: {
    color: grayColor[6],
    margin: "6px auto 0",
    width: "42px",
    height: "42px",
    border: "1px solid " + grayColor[11],
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "58px",
    transition: "box-shadow 0.2s",
    "& svg": {
      marginLeft: "1px",
      transition: "transform 0.2s",
      width: "26px",
      height: "26px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "26px",
      fontSize: "26px",
    },
  },
  actionText: {
    color: grayColor[0],
    marginTop: "6px",
    marginBottom: "2px",
    textAlign: "center",
    fontSize: "10px",
  },
  fontCenter: {
    textAlign: "center !important",
  },
  cardButton: {
    padding: "0 !important",
    margin: "10px 0 0 10px !important",
    height: "22px !important",
    width: "30px !important",
    minWidth: "0 !important",
    position: "relative",
    transition: "all 0.2s",
  },
  cardButtonIcon: {
    margin: "0 !important",
    position: "absolute !important",
    top: "50% !important",
    left: "50% !important",
    transform: "translate(-50%, -50%)",
    width: "18px !important",
    height: "18px !important",
  },
  inputAdornment: {
    marginRight: "8px",
    position: "relative",
  },
  inputAdornmentIconSuccess: {
    color: successColor[0] + "!important",
  },
  inputAdornmentIconError: {
    color: dangerColor[0] + "!important",
  },
  initiativeInput: {
    "&.top": {
      marginTop: "10px",
    },
    "&:not(.top)": {
      marginTop: "-6px",
    },
    [theme.breakpoints.down("xs")]: {
      "&:not(.first)": {
        marginTop: "-4px !important",
      },
    },
  },
  newInitiativeNote: {
    width: "100%",
    textAlign: "center",
    fontStyle: "italic",
    paddingTop: "5px",
    paddingBottom: "10px",
    color: grayColor[0],
  },
  initiativesSpinner: {
    height: "100%",
    paddingTop: "4px",
    "& > svg": {
      width: "40px !important",
    },
  },
  screenSpinner: {
    position: "absolute",
    top: "36px",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  attention: {
    backgroundColor: warningColor[0] + " !important",
    animation: "$attention 3s ease infinite",
  },
  "@keyframes attention": {
    "10%": {
      transform: "scale(1.1)",
    },
    "13.33%,20%": {
      transform: "rotate(-10deg) scale(1.1)",
    },
    "16.67%": {
      transform: "rotate(10deg) scale(1.1)",
    },
    "23.33%": {
      transform: "rotate(0deg) scale(1.1)",
    },
    "33%": {
      transform: "scale(1)",
    },
  },
  fupUserName: {
    color: grayColor[0],
  },
  fupDaysAgo: {
    textTransform: "lowercase",
  },
  invisible: {
    opacity: "0",
    pointerEvents: "none",
  },
  filterModalHeaderScope: {
    color: grayColor[0],
  },
  filterModalHeaderFilterName: {
    fontWeight: "bold",
  },
  filterModalOrDividerContainer: {
    height: "100%",
    paddingTop: "8px",
  },
  orDivider: {
    color: grayColor[0],
    fontSize: "16px",
    textAlign: "center",
    margin: "-4px 0 0",
  },
  firstArrow: {
    marginTop: "20px",
  },
  arrow: {
    textAlign: "center",
    fontSize: "22px",
    color: grayColor[5],
    opacity: "0.6",
    transition: "all 0.2s",
  },
  arrowSelected: {
    color: clientColor,
    transform: "scale(2)",
    opacity: "1",
  },
  filterModalActionContainer: {
    paddingRight: "5px !important",
  },
  filterModalAction: {
    color: grayColor[0],
    fontSize: "12px",
    cursor: "pointer",
    "&:hover": {
      color: grayColor[6],
    },
  },
  filterModalCounter: {
    textAlign: "right",
    color: grayColor[0],
    fontSize: "12px",
  },
  topCardShortSpacing: {
    margin: "0px",
  },
  topCardName: {
    color: grayColor[0],
    "& span:first-child": {
      lineHeight: "2.5",
    },
  },
  topCardIcon: {
    color: grayColor[0],
    fontSize: "16px",
    cursor: "pointer",
    marginLeft: "8px",
    transition: "opacity 150ms",
    "&:hover": {
      color: grayColor[0],
      opacity: "0.5",
    },
    "&:before": {
      fontFamily: "'Font Awesome 5 Free'",
      content: "'\\f0b0'",
      padding: "0 0 0 2px",
      fontWeight: "900",
    },
  },
  topCardBody: {
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
    paddingLeft: "0px !important",
  },
  topCardButtonContainer: {
    padding: "5px !important",
  },
  topCardButton: {
    margin: "0 !important",
  },
  refreshIcon: {
    color: grayColor[1],
    marginBottom: "5px",
    marginLeft: "-20px",
    fontSize: "18px",
    cursor: "pointer",
    transition: "transform 150ms",
    "&:hover": {
      transform: "rotate(180deg)",
    },
  },
  stepsContainer: {
    height: "97%",
    backgroundColor: grayColor[8],
    borderRadius: "4px",
    padding: "15px",
    margin: "0",
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
    overflow: "hidden",
  },
  cardHeaderHover: {
    transition: "all 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
    height: "100%",
    "& > div": {
      height: "100%",
      transition: "all 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
    },
  },
  drawerContent: {
    padding: "15px",
    height: "88vh",
    overflowY: "hidden",
    "& .makeStyles-timelineSimple-6417": {
      marginTop: "5px",
    },
  },
  drawer: {
    "& .MuiDrawer-root": {
      zIndex: 1200,
    },
    "& .MuiDrawer-paper": {
      zIndex: 1200,
      overflowY: "hidden",
    },
    "& .MuiBackdrop-root": {
      zIndex: 1199,
    },
  },
  chartCard: {
    height: "100%",
    "& .ct-chart": {
      padding: "5px",
    },
    marginBottom: "0",
  },
  drawerHeader: {
    borderBottom: "none",
    padding: "12px 12px 0 12px",
  },
  drawerTitle: {
    fontSize: "1.1rem",
    fontWeight: "300",
    marginBottom: "4px",
    color: grayColor[6],
  },
  drawerSubtitle: {
    fontSize: "0.875rem",
    color: grayColor[0],
    fontStyle: "italic",
    lineHeight: "1.3",
    marginBottom: "8px",
  },
});

export default initiativesStyle;
