import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import {
  MenuItem,
  Typography,
  InputLabel,
  Select,
  FormControl,
  Grid,
  Button as MuiButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { ThreeDots } from "react-loader-spinner";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "../../components/CustomInput/CustomInput";
import { client } from "../../services/axiosClient";
import AuthContext from "../../contexts/AuthProvider";
import { integrationColor } from "assets/jss/material-dashboard-pro-react";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { useHistory } from "react-router-dom";

// Constants for file size limits

const MAX_FILE_SIZE_MB = 5;
const MAX_TOTAL_FILE_SIZE_MB = 5;
const ContactPage = () => {
  // State Management

  const { userDetails } = useContext(AuthContext);
  const { t } = useTranslation();
  const [adminList, setAdminList] = useState([]);
  const [reason, setReason] = useState("");
  const [description, setDescription] = useState("");
  const [rows] = useState(4);
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(true);
  const maxChars = 2000;
  const history = useHistory();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);

  // Refs for file upload and description field

  const uploadInputRef = useRef(null);
  const descriptionRef = useRef(null);
  const recaptchaRef = useRef(null);

  // Theme Color based on user details

  const themeColor = userDetails
    ? userDetails.implementation_color
    : integrationColor[2];

  // Custom styles using makeStyles (all styles are concentrated in this file)

  const useStyles = makeStyles((theme) => ({
    ...customSelectStyle(theme, themeColor),
    ...customInputStyle(themeColor),
    container: {
      padding: "20px",
      maxWidth: "2000px",
      minHeight: "50vh",
      margin: "0 auto",
      backgroundColor: "#f9f9f9",
      borderRadius: "8px",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        padding: "10px",
      },
    },
    headerWrapper: {
      display: "flex",
      alignItems: "center",
      position: "relative",
      top: "-20px",
      left: "-10px",
    },
    fileName: {
      marginLeft: "10px",
      verticalAlign: "middle",
    },
    selectLabel: {
      "&.Mui-focused, &.MuiFormLabel-filled": {
        transform: "translate(0, 0px) scale(0.75)",
        color: theme.palette.grey[500],
      },
      "&::placeholder": {
        color: theme.palette.grey[500],
        opacity: 1,
      },
    },
    textFieldCustom: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: theme.palette.grey[400],
        },
        "&:hover fieldset": {
          borderColor: themeColor,
        },
        "&.Mui-focused fieldset": {
          borderColor: themeColor,
        },
        "& textarea": {
          resize: "vertical",
          overflow: "auto",
          padding: "0px",
          boxSizing: "border-box",
          margin: "0",
        },
      },
      "& .MuiOutlinedInput-input": {
        paddingBottom: "0px",
      },
      "& .MuiInputLabel-outlined": {
        color: theme.palette.grey[600],
        "&.Mui-focused": {
          color: theme.palette.grey[500],
        },
      },
      "& .MuiOutlinedInput-input::placeholder": {
        color: theme.palette.grey[600],
        opacity: 1,
      },
      "& textarea::after": {
        position: "absolute",
        bottom: "0px",
        right: "0px",
      },
    },
    restrictedField: {
      backgroundColor: "#f0f0f0",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: theme.palette.grey[700],
        },
        "&:hover fieldset": {
          borderColor: theme.palette.grey[700],
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.palette.grey[700],
        },
      },
    },
    disabledButton: {
      backgroundColor: "#d3d3d3 !important",
      color: "#a1a1a1 !important",
    },
    progressContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      textAlign: "center",
    },
    successContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      textAlign: "center",
      animation: `$fadeIn 1s`,
    },
    successIcon: {
      fontSize: "4rem",
      color: userDetails ? themeColor : integrationColor[0],
      marginBottom: "16px",
    },
    spinnerContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "75vh",
    },
    "@keyframes fadeIn": {
      "0%": { opacity: 0 },
      "100%": { opacity: 1 },
    },
  }));
  const classes = useStyles();

  // Check if the selected reason is restricted - if included here, when the user selects this reason, it will block the submit button and ask user to talk to admin

  const isRestrictedReason = reason === "AAD";

  // Fetch admin list when userDetails is available

  useEffect(() => {
    if (userDetails && adminList.length === 0) {
      const fetchAdminList = async () => {
        try {
          const response = await client.post("api/post_login_info/");
          setAdminList(response.data.implementation_admins || []);
        } catch (error) {
          console.error("Error fetching admin list:", error);
        }
      };
      fetchAdminList();
    }
  }, [userDetails, adminList.length]);

  // Define contact reasons - they will be different for users logged in or not

  const contactReasons = userDetails
    ? [
        { value: "ERP", label: t("contact_page.contact_reasons.error_report") },
        {
          value: "AAD",
          label: t("contact_page.contact_reasons.allocation_adjustments"),
        },
        {
          value: "FSG",
          label: t("contact_page.contact_reasons.feature_suggestions"),
        },
        { value: "OTH", label: t("contact_page.contact_reasons.others") },
      ]
    : [
        { value: "LNM", label: t("contact_page.contact_reasons.learn_more") },
        { value: "LIS", label: t("contact_page.contact_reasons.login_issues") },
      ];
  // Handle file selection and validate file size limits

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Calculate the total size of selected files

    const totalSize = files.reduce((sum, file) => sum + file.size, file.size);

    // Validate individual file size

    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      setErrorMessage(t("contact_page.file_size_exceeded"));
      return;
    }
    // Validate total file size
    if (totalSize > MAX_TOTAL_FILE_SIZE_MB * 1024 * 1024) {
      setErrorMessage(t("contact_page.total_file_size_exceeded"));
      return;
    }

    // Add selected file to state

    setFiles((prevFiles) => [...prevFiles, file]);

    // Clear the file input after adding the file

    if (uploadInputRef.current) {
      uploadInputRef.current.value = "";
    }
  };

  // Remove a selected file from the list

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Handle form submission

  const handleSubmit = async (
    e,
    isCaptchaVerifiedParam = captchaVerified,
    captchaToken = null,
  ) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    const name = document.getElementById("name")?.value || "";
    const email = document.getElementById("email")?.value || "";
    if (!name || !email || !reason || !description) {
      setErrorMessage(t("contact_page.error_missing_fields"));
      return;
    }
    if (!userDetails && !isCaptchaVerifiedParam) {
      setShowCaptcha(true);
      setErrorMessage(t("contact_page.captcha_verification_required"));
      return;
    }
    setErrorMessage("");
    setDataLoaded(false);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("reason", reason);
    formData.append("description", description);
    files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    // Including Recaptcha token in case user is non logged

    if (!userDetails && captchaToken) {
      formData.append("recaptchaToken", captchaToken);
      console.log("Token do ReCAPTCHA incluído no FormData:", captchaToken);
    }
    try {
      const response = await client.post("/api/contact_request/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Resposta do servidor:", response.data);
      if (response.data.success) {
        setDataLoaded(true);
        setIsSubmitted(true);

        // Recharge after 7 seconds

        setTimeout(() => {
          window.location.reload();
        }, 7000);
      } else {
        setDataLoaded(true);
        setErrorMessage(
          response.data.error || t("contact_page.error_submission_failed"),
        );
      }
    } catch (error) {
      console.error("Error submitting contact request:", error);
      setDataLoaded(true);
      setErrorMessage(t("contact_page.error_submission_failed"));
    }
  };

  // Handle CAPTCHA verification

  const onCaptchaChange = async (value) => {
    if (value) {
      setCaptchaVerified(true);
      setErrorMessage("");
      console.log("Token do ReCAPTCHA recebido:", value);
      if (!userDetails) {
        await handleSubmit(null, true, value);
      }
    } else {
      setCaptchaVerified(false);
      setErrorMessage(t("contact_page.captcha_verification_failed"));
    }
  };

  // Show loading spinner if data is being loaded

  if (!dataLoaded) {
    return (
      <div className={classes.spinnerContainer}>
        <ThreeDots
          color={userDetails ? themeColor : integrationColor[0]}
          ariaLabel="loading"
          width="80"
          height="80"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  // Show success message if the form was successfully submitted

  if (isSubmitted) {
    return (
      <div className={classes.successContainer}>
        <CheckCircleIcon className={classes.successIcon} />
        <Typography variant="h4">{t("contact_page.success_title")}</Typography>
        <Typography variant="body1">
          {t("contact_page.success_message")}
        </Typography>
      </div>
    );
  }

  // Render the form

  return (
    <form onSubmit={handleSubmit}>
      {/* Back Button for non logged users */}

      {!userDetails && (
        <Button
          color="integration"
          onClick={() => history.goBack()}
          style={{ marginBottom: "40px" }}
        >
          <KeyboardArrowLeft />
          {t("contact_page.back")}
        </Button>
      )}

      {/* Main container grid */}

      <Grid container spacing={3} style={{ alignItems: "stretch" }}>
        {/* Left section: form fields */}

        <Grid
          item
          xs={12}
          md={userDetails ? 8 : 12}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Card className={classes.container} style={{ flex: 1 }}>
            {/* Header */}

            <div className={classes.headerWrapper}>
              <CardHeader
                color={userDetails ? "client" : "integration"}
                stats
                icon
              >
                <CardIcon color={userDetails ? "client" : "integration"}>
                  <Icon>support_agent</Icon>
                </CardIcon>
              </CardHeader>
              <Typography variant="h6">{t("contact_page.title")}</Typography>
            </div>

            {/* Form container */}

            <div className={classes.formContainer}>
              <CustomInput
                labelText={t("contact_page.user_name")}
                id="name"
                formControlProps={{
                  fullWidth: true,
                  style: { marginBottom: "-2px" },
                }}
                inputProps={{
                  defaultValue: userDetails?.name || "",
                  disabled: !!userDetails,
                }}
                integration
              />
              <CustomInput
                labelText={t("contact_page.user_email")}
                id="email"
                formControlProps={{
                  fullWidth: true,
                  style: { marginBottom: "10px" },
                }}
                inputProps={{
                  defaultValue: userDetails?.email || "",
                  disabled: !!userDetails,
                }}
                integration
              />

              {/* Dropdown for contact reasons */}

              <FormControl
                fullWidth
                margin="normal"
                className={`${classes.selectFormControl} ${classes.selectFormControlOverride}`}
              >
                <InputLabel className={classes.selectLabel}>
                  {t("contact_page.contact_reason")}
                </InputLabel>
                <Select
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  displayEmpty
                  MenuProps={{
                    className: classes.selectMenu,
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                    getContentAnchorEl: null,
                  }}
                  classes={{
                    select: `${classes.select} ${classes.selectFormControlOverride}`,
                  }}
                >
                  {contactReasons.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {isRestrictedReason && userDetails && (
                <Typography
                  variant="body2"
                  color="error"
                  style={{ marginTop: "10px", fontStyle: "italic" }}
                >
                  {t("contact_page.restricted_reason_message")}
                </Typography>
              )}

              {/* Description field */}

              <TextField
                ref={descriptionRef}
                label={t("contact_page.case_description")}
                multiline
                rows={rows}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
                helperText={`${description.length}/${maxChars} ${t(
                  "contact_page.characters",
                )}`}
                inputProps={{
                  maxLength: maxChars,
                  style: { resize: "vertical" },
                }}
                className={`${classes.textFieldCustom} ${
                  isRestrictedReason ? classes.restrictedField : ""
                }`}
                disabled={isRestrictedReason}
              />

              {/* File upload */}

              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginBottom: "8px" }}
              >
                {t("contact_page.file_upload_instruction")}
              </Typography>
              <List>
                {files.map((file, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={file.name} />
                    <IconButton onClick={() => removeFile(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
              <div>
                <input
                  ref={uploadInputRef}
                  accept="*"
                  style={{ display: "none" }}
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                  disabled={isRestrictedReason}
                />
                <label htmlFor="file-upload">
                  <MuiButton
                    variant="contained"
                    component="span"
                    className={`${classes.fileButton} ${
                      isRestrictedReason ? classes.disabledButton : ""
                    }`}
                    disabled={isRestrictedReason}
                  >
                    {t("contact_page.choose_file")}
                  </MuiButton>
                </label>
              </div>

              {/* Error and submit button */}

              <div style={{ marginTop: "16px" }}>
                {errorMessage && (
                  <Typography
                    variant="body2"
                    color="error"
                    style={{ marginBottom: "8px" }}
                  >
                    {errorMessage}
                  </Typography>
                )}

                {/* Render Submit Button */}

                {userDetails ? (
                  <Button
                    variant="contained"
                    color="client"
                    type="submit"
                    className={`${classes.submitButton} ${
                      isRestrictedReason ? classes.disabledButton : ""
                    }`}
                    disabled={isRestrictedReason}
                    onClick={handleSubmit}
                  >
                    {t("contact_page.submit")}
                  </Button>
                ) : showCaptcha ? (
                  <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Ld4w5gqAAAAAPEg_ZcFhNylqKUDKXAxFDY-3mla"
                      onChange={onCaptchaChange}
                    />
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    color="integration"
                    type="button"
                    className={`${classes.submitButton} ${
                      isRestrictedReason ? classes.disabledButton : ""
                    }`}
                    disabled={isRestrictedReason}
                    onClick={() => setShowCaptcha(true)}
                  >
                    {t("contact_page.submit")}
                  </Button>
                )}
              </div>
            </div>
          </Card>
        </Grid>

        {/* Right section: admin list - only for logged in users */}

        {userDetails && (
          <Grid
            item
            xs={12}
            md={4}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Card className={classes.container} style={{ flex: 1 }}>
              <Typography variant="h6">
                {t("contact_page.admins_title")}
              </Typography>
              <ul
                className={classes.adminList}
                style={{ listStyleType: "none", paddingLeft: 0 }}
              >
                {adminList.map((admin, index) => (
                  <li
                    key={admin.email || index}
                    className={classes.adminItem}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Icon
                      style={{
                        color: userDetails.implementation_color,
                        marginRight: 8,
                        fontSize: "2.5rem",
                      }}
                    >
                      admin_panel_settings
                    </Icon>
                    <div>
                      <Typography variant="body1">{admin[0]}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {admin[1]}
                      </Typography>
                    </div>
                  </li>
                ))}
              </ul>
            </Card>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
ContactPage.propTypes = {
  userType: PropTypes.string.isRequired,
};
export default ContactPage;
