import React, { forwardRef, useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AuthContext from "../../contexts/AuthProvider";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import textFieldStyles from "assets/jss/material-dashboard-pro-react/components/customTextFieldStyle.js";
const CustomInput = forwardRef((props, ref) => {
  let { userDetails } = useContext(AuthContext);
  const client_styles = () => ({
    ...styles(userDetails ? userDetails.implementation_color : "#fff"),
  });
  const useStyles = makeStyles(client_styles);
  const classes = useStyles();
  const text_field_client_styles = () => ({
    ...textFieldStyles(userDetails),
  });
  const useTextFieldStyles = makeStyles(text_field_client_styles);
  const textFieldClasses = useTextFieldStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
    rtlActive,
    onChange,
    integration,
    client,
    value,
    onEnterKey,
    onFocus,
    onBlur,
    multiline,
  } = props;
  const handleKeyDown = (e) => {
    if (onEnterKey) {
      if (e.key === "Enter") {
        e.preventDefault();
        onEnterKey();
      }
    }
  };
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
    [classes.labelRTL]: rtlActive,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underlineIntegration]: integration && !error,
    [classes.underlineClient]: client && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const inputRootClasses = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  let formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl,
    );
  } else {
    formControlClasses = classes.formControl;
  }
  let helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });
  let newInputProps = {
    maxLength:
      inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
    minLength:
      inputProps && inputProps.minLength ? inputProps.minLength : undefined,
    step: inputProps && inputProps.step ? inputProps.step : undefined,
    autoComplete:
      inputProps && inputProps.autoComplete
        ? inputProps.autoComplete
        : undefined,
  };
  let inputElement;
  if (multiline) {
    inputElement = (
      <TextField
        className={textFieldClasses.root}
        id={id}
        label={labelText}
        {...inputProps}
        inputProps={newInputProps}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
        onKeyDown={handleKeyDown}
        multiline
        minRows={6}
        maxRows={6}
        // variant="outlined"
      />
    );
  } else {
    inputElement = (
      <>
        {labelText !== undefined ? (
          <InputLabel
            className={classes.labelRoot + " " + labelClasses}
            htmlFor={id}
            {...labelProps}
          >
            {labelText}
          </InputLabel>
        ) : null}
        <Input
          classes={{
            input: inputClasses,
            root: inputRootClasses,
            disabled: classes.disabled,
            underline: underlineClasses,
          }}
          id={id}
          {...inputProps}
          inputProps={newInputProps}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={ref}
          onKeyDown={handleKeyDown}
        />
      </>
    );
  }
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {inputElement}
      {helperText !== undefined ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
});
CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  rtlActive: PropTypes.bool,
  onChange: PropTypes.func,
  integration: PropTypes.bool,
  client: PropTypes.bool,
  value: PropTypes.string,
  onEnterKey: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  multiline: PropTypes.bool,
};
CustomInput.displayName = "CustomInput";
export default CustomInput;
