import Buttons from "views/Components/Buttons.js";
import Overview from "./views/Overview/Overview.js";
import ModelingInputs from "./views/Modeling/ModelingInputs.js";
import ExchangeRates from "./views/Modeling/ExchangeRates";
import Documentation from "./views/Modeling/Documentation.js";
import PLExplorer from "./views/Results/PLExplorer";
import GraphExplorer from "./views/Results/GraphExplorer";
import Login from "./views/Login/Login.js";
import Initiatives from "./views/ActionPlans/Initiatives";
import UserManagementForms from "./views/UserManagement/UserManagementForms.js";
import TermsOfUse from "./views/Extras/TermsOfUse";
import NoPermission from "./views/NoPermission/NoPermission";
import ContactPage from "./views/Extras/ContactPage";

import OverviewIcon from "@material-ui/icons/FilterHdr";
import ModelingIcon from "@material-ui/icons/Dashboard";
import ResultsIcon from "@material-ui/icons/Equalizer";
import ActionPlansIcon from "@material-ui/icons/TouchApp";
import AdminIcon from "@material-ui/icons/Settings";
import CancelIcon from "@material-ui/icons/Cancel";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const extrasRoutes = [
  {
    collapse: true,
    name: "sidebar_cts.extras",
    icon: MoreHorizIcon,
    state: "ExtrasCollapse",
    layout: "/extras",
    views: [
      {
        path: "/contact",
        name: "sidebar_cts.extras_menu.contact",
        mini: "sidebar_cts.extras_menu_mini.contact",
        component: ContactPage,
        layout: "/extras",
      },
      {
        path: "/terms-of-use",
        name: "sidebar_cts.extras_menu.terms_of_use",
        mini: "sidebar_cts.extras_menu_mini.terms_of_use",
        component: TermsOfUse,
        layout: "/extras",
      },
      {
        external_link: "https://integrationconsulting.com/en/privacy-policy/",
        name: "sidebar_cts.extras_menu.privacy_policy",
        mini: "sidebar_cts.extras_menu_mini.privacy_policy",
        layout: "/extras",
      },
    ],
  },
];

function deepClone(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(deepClone);
  }
  const clonedObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clonedObj[key] = deepClone(obj[key]);
    }
  }
  return clonedObj;
}

function changeLayoutToMain(obj) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (key === "layout") {
        obj[key] = "/main";
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        changeLayoutToMain(obj[key]);
      }
    }
  }
}

const extrasRoutesInMain = deepClone(extrasRoutes);
changeLayoutToMain(extrasRoutesInMain);

const dashRoutes = [
  {
    path: "/overview",
    name: "sidebar_cts.overview",
    icon: OverviewIcon,
    component: Overview,
    layout: "/main",
    allowedRoles: ["DEV", "IMG", "CAD", "CST", "CFA", "CVW", "CVP"],
  },
  {
    collapse: true,
    name: "sidebar_cts.modeling",
    icon: ModelingIcon,
    state: "modelingCollapse",
    layout: "/main",
    allowedRoles: ["DEV", "IMG", "CAD", "CST", "CFA"],
    views: [
      {
        path: "/inputs",
        name: "sidebar_cts.modeling_menu.inputs",
        mini: "sidebar_cts.modeling_menu_mini.inputs",
        component: ModelingInputs,
        layout: "/main",
      },
      {
        path: "/exchange_rates",
        name: "sidebar_cts.modeling_menu.exchange_rates",
        mini: "sidebar_cts.modeling_menu_mini.exchange_rates",
        component: ExchangeRates,
        layout: "/main",
      },
      {
        path: "/documentation",
        name: "sidebar_cts.modeling_menu.documentation",
        mini: "sidebar_cts.modeling_menu_mini.documentation",
        component: Documentation,
        layout: "/main",
      },
    ],
  },
  {
    collapse: true,
    name: "sidebar_cts.results",
    icon: ResultsIcon,
    state: "resultsCollapse",
    layout: "/main",
    allowedRoles: ["DEV", "IMG", "CAD", "CST", "CFA", "CVW", "CVP"],
    views: [
      {
        path: "/pl-explorer",
        name: "sidebar_cts.results_menu.pl_explorer",
        mini: "sidebar_cts.results_menu_mini.pl_explorer",
        component: PLExplorer,
        layout: "/main",
      },
      {
        path: "/graph-explorer",
        name: "sidebar_cts.results_menu.graph_explorer",
        mini: "sidebar_cts.results_menu_mini.graph_explorer",
        component: GraphExplorer,
        layout: "/main",
      },
      {
        path: "/lists-view",
        name: "sidebar_cts.results_menu.list_view",
        mini: "sidebar_cts.results_menu_mini.list_view",
        component: Buttons,
        layout: "/main",
        disabled: true,
      },
    ],
  },
  {
    collapse: true,
    name: "sidebar_cts.action_plans",
    icon: ActionPlansIcon,
    state: "actionPlansCollapse",
    layout: "/main",
    allowedRoles: ["DEV", "IMG", "CAD", "CST", "CFA", "CVW"],
    views: [
      {
        path: "/initiatives",
        name: "sidebar_cts.action_plans_menu.initiatives",
        mini: "sidebar_cts.action_plans_menu_mini.initiatives",
        component: Initiatives,
        layout: "/main",
      },
    ],
  },
  {
    collapse: true,
    name: "sidebar_cts.admin",
    icon: AdminIcon,
    state: "AdminCollapse",
    allowedRoles: ["CAD", "IMG", "DEV"],
    layout: "/main",
    views: [
      {
        path: "/user-management",
        name: "sidebar_cts.admin_menu.user_management",
        mini: "sidebar_cts.admin_menu_mini.user_management",
        component: UserManagementForms,
        layout: "/main",
        disabled: false,
      },
    ],
  },
  {
    path: "/no-permission",
    name: "sidebar_cts.no_permission",
    icon: CancelIcon,
    component: NoPermission,
    layout: "/main",
    showOnlyWhenRedirected: true,
  },
  {
    path: "/login",
    name: "Profit InSights",
    component: Login,
    layout: "/auth",
  },
  ...extrasRoutes,
  ...extrasRoutesInMain,
];
export default dashRoutes;
