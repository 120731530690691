import {
  blackColor,
  grayColor,
  hexToRgb,
  whiteColor,
} from "../../material-dashboard-pro-react";

const graphExplorerStyle = () => ({
  actionWrapperOuter: {
    paddingLeft: "10px !important",
    paddingRight: "10px !important",
  },
  actionWrapper: {
    cursor: "pointer",
    opacity: "0.8",
    transition: "opacity 0.1s",
    "&:hover,&:active": {
      opacity: "1",
      "& div:first-child": {
        boxShadow: "0 0px 30px -10px rgba(" + hexToRgb(blackColor) + ", 0.3)",
      },
      "& div:first-child > svg": {
        transform: "scale(1.2)",
      },
    },
    "&:active": {
      "& div:first-child": {
        boxShadow:
          "0 0px 30px -10px rgba(" + hexToRgb(blackColor) + ", 0.7) !important",
      },
    },
  },
  actionDisabled: {
    opacity: "0.5 !important",
    pointerEvents: "none",
  },
  actionIcon: {
    color: grayColor[6],
    margin: "6px auto 0",
    width: "42px",
    height: "42px",
    border: "1px solid " + grayColor[11],
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "58px",
    transition: "box-shadow 0.2s",
    "& svg": {
      marginLeft: "1px",
      transition: "transform 0.2s",
      width: "26px",
      height: "26px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "26px",
      fontSize: "26px",
    },
  },
  actionText: {
    color: grayColor[0],
    marginTop: "6px",
    marginBottom: "2px",
    textAlign: "center",
    fontSize: "10px",
  },
  dataCard: {
    marginTop: "8px",
    minHeight: "72vh",
  },
  followMe: {
    display: "inline",
    "& > span": {
      marginRight: "5px",
    },
  },
  followMeText: {
    color: whiteColor,
    backgroundColor: grayColor[6],
    borderRadius: "3px",
    padding: "2px 4px",
    transition: "all 500ms ease-in-out",
    marginLeft: "0",
    "&.new": {
      marginLeft: "20px",
      opacity: "0",
    },
  },
  followMeDivider: {
    color: grayColor[6],
    padding: "2px 0",
  },
});

export default graphExplorerStyle;
